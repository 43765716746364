import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ClientContext } from "../context/ClientContext";

const ClientLogout = () => {
	const navigate = useNavigate();
	const [clientSession, setClientSession] = useContext(ClientContext);

	const logout = async () => {
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": clientSession,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/clients/logout`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			setClientSession(null);
		}
	};

	useEffect(() => {
		logout();
	}, []);

	useEffect(() => {
		if (!clientSession) {
			navigate(`/login${window.location.search}`);
		}
	}, [clientSession]);

	return "";
};

export default ClientLogout;
