import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import ToastDisplay from "../components/ToastDisplay";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Logo from "../assets/hero.png";

const ResetPassword = () => {
	const navigate = useNavigate();
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [loading, setLoading] = useState(false);

	const savePassword = async (e) => {
		e.preventDefault();
		setLoading(true);

		if (password !== confirmPassword) {
			toast(<ToastDisplay status="error" content="Passwords do not match." />, {
				position: "top-center",
			});
		} else if (password.length < 8) {
			toast(
				<ToastDisplay
					status="error"
					content="Password must be at least 8 characters."
				/>,
				{
					position: "top-center",
				}
			);
		} else {
			const requestOptions = {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					password: password,
					token: new URLSearchParams(window.location.search).get("token"),
				}),
			};

			const response = await fetch(
				`${process.env.REACT_APP_API_URI}/clients/password/reset`,
				requestOptions
			);
			const data = await response.json();

			if (!response.ok) {
				toast(<ToastDisplay status="error" content={data.msg} />, {
					position: "top-center",
				});
			} else {
				toast(<ToastDisplay status="success" content={data.msg} />, {
					position: "top-center",
				});
				navigate("/login");
			}
		}
		setLoading(false);
	};

	return (
		<div className="full-page bg-white flex-column">
			<Toaster
				position="bottom-right"
				toastOptions={{ className: "snackbar-container" }}
			/>
			<Container className="flex-column">
				<div className="login-form-container mb-50 bg-white col-xl-4 col-lg-6 col-md-7 col-12">
					<Form onSubmit={savePassword} className="login-form full flex-column">
						<img src={Logo} alt="login-logo" className="login-logo mb-30" />
						<div className="align-left full mb-30">
							<h1 className="text-medium-gotham text-xl mb-0">
								Reset Password
							</h1>
							<span className="login-title text-md">
								Please enter your new password.
							</span>
						</div>
						<Form.Control
							required
							type="password"
							className="login-input rounded-4 mb-20"
							placeholder="Password"
							autoCapitalize="off"
							autoComplete="off"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
						<Form.Control
							required
							type="password"
							className="login-input rounded-4 mb-30"
							placeholder="Confirm password"
							value={confirmPassword}
							onChange={(e) => setConfirmPassword(e.target.value)}
						/>
						<Button
							type="submit"
							className="btn-default login-btn btn-md text-sm text-medium-inter full mb-50"
							disabled={loading}
						>
							{loading ? (
								<Spinner className="btn-loader text-white" />
							) : (
								"Reset Password"
							)}
						</Button>
					</Form>
				</div>
			</Container>
		</div>
	);
};

export default ResetPassword;
