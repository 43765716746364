import EmptyBee from "./EmptyBee";

const Empty = ({ className, label }) => {
	return (
		<div className={`empty-state flex-column p-50 ${className || ""}`}>
			<EmptyBee />
			<span className="text-sm empty-text opacity-5">{label}</span>
		</div>
	);
};

export default Empty;
