import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Skeleton from "react-loading-skeleton";

const ContentLoaderSm = () => {
	return (
		<>
			<div className="content-item-sm bg-white gx-0 full rounded-8 p-20 mb-20 border">
				<div className="mb-15">
					<div>
						<span className="content-item-title block text-medium-gotham">
							<Skeleton
								width={"30%"}
								height={16}
								baseColor="#dadee7"
								highlightColor="#e9ecf2"
							/>
						</span>
					</div>
					<Skeleton
						width={"60%"}
						height={14}
						baseColor="#dadee7"
						highlightColor="#e9ecf2"
					/>
				</div>
				<div className="content-item-sub border-top">
					<Row className="flex-row-left">
						<Col>
							<div className="content-status">
								<Skeleton
									width={"90px"}
									height={14}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							</div>
						</Col>
						<Col className="flex-row-right">
							<Skeleton
								width={"110px"}
								height={14}
								baseColor="#dadee7"
								highlightColor="#e9ecf2"
							/>
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
};

export default ContentLoaderSm;
