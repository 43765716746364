import React, { createContext, useEffect, useState } from "react";
import Cookies from "js-cookie";

export const SessionContext = createContext();

export const SessionProvider = (props) => {
	const [session, setSession] = useState(Cookies.get("rmmsSession") || null);

	const fetchUser = async () => {
		const requestOptions = {
			headers: {
				method: "GET",
				"X-Auth-Token": session,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/me`,
			requestOptions
		);

		if (!response.ok) {
			setSession(null);
			return;
		}
		Cookies.set("rmmsSession", session);
	};

	useEffect(() => {
		if (session) {
			fetchUser();
		} else {
			Cookies.remove("rmmsSession");
		}
	}, [session]);

	return (
		<SessionContext.Provider value={[session, setSession]}>
			{props.children}
		</SessionContext.Provider>
	);
};
