import { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { SessionContext } from "../context/SessionContext";
import { ProfileContext } from "../context/ProfileContext";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Skeleton from "react-loading-skeleton";
import Dropdown from "react-bootstrap/Dropdown";
import InputGroup from "react-bootstrap/InputGroup";
import Nav from "react-bootstrap/Nav";
import toast from "react-hot-toast";
import ToastDisplay from "../components/ToastDisplay";
import ItemDetails from "../components/ItemDetails";
import InfoLogs from "../components/InfoLogs";

const Item = () => {
	let { id } = useParams();
	const navigate = useNavigate();
	const [session] = useContext(SessionContext);
	const [profile] = useContext(ProfileContext);
	const [loading, setLoading] = useState(true);
	const [generating, setGenerating] = useState(false);
	const [item, setItem] = useState(null);
	const [editModal, setEditModal] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [actionLoading, setActionLoading] = useState(false);
	const [tab, setTab] = useState("details");
	const [infoTab, setInfoTab] = useState("details");

	const [name, setName] = useState("");
	const [barcode, setBarcode] = useState("");
	const [sapAssetNo, setSapAssetNo] = useState("");
	const [description, setDescription] = useState("");
	const [agency, setAgency] = useState("");
	const [location, setLocation] = useState("");
	const [area, setArea] = useState("");

	const [errors, setErrors] = useState([]);

	const requiredFields = [{ key: "name", value: name }];

	const getData = async () => {
		setLoading(true);
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/inventory/i/${id}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			setItem(data.item);
			setName(data.item.name);
			setBarcode(data.item.barcode);
			setSapAssetNo(data.item.sap_asset_no);
			setDescription(data.item.description);
			setAgency(data.item.agency);
			setLocation(data.item.location);
			setArea(data.item.area);
		}
		setLoading(false);
	};

	const generateCode = async () => {
		setGenerating(true);
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/inventory/code`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			setBarcode(data.code);
		}
		setGenerating(false);
	};

	const editItem = async () => {
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
			body: JSON.stringify({
				name: name,
				barcode: barcode,
				sap_asset_no: sapAssetNo,
				description: description,
				agency: agency,
				location: location,
				area: area,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/inventory/i/${id}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			toast(<ToastDisplay status="error" content={data.msg} />);
		} else {
			toast(<ToastDisplay status="success" content={data.msg} />);
			getData();
			closeEditModal();
		}
		setActionLoading(false);
	};

	const deleteItem = async () => {
		setDeleteLoading(true);
		const requestOptions = {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/inventory/i/${id}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			toast(<ToastDisplay status="success" content={data.msg} />);
			navigate("/property/inventory/items");
			setDeleteModal(false);
		}
		setDeleteLoading(false);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setActionLoading(true);
		var newErrors = [];

		requiredFields.map((field) => {
			if (!field.value || field.value === "" || field.value.length === 0) {
				newErrors.push(field.key);
			}
		});

		if (newErrors.length !== 0) {
			toast(
				<ToastDisplay
					status="error"
					content="Please fill out all required fields."
				/>
			);
			setActionLoading(false);
			setErrors(newErrors);
			return;
		}
		editItem();
	};

	const closeEditModal = () => {
		setEditModal(false);
		setName(item.name);
		setBarcode(item.barcode);
		setSapAssetNo(item.sap_asset_no);
		setDescription(item.description);
		setAgency(item.agency);
		setLocation(item.location);
		setArea(item.area);
		setErrors([]);
	};

	useEffect(() => {
		if (profile) {
			if (!profile.role.items_view) {
				navigate("/");
			} else {
				if (id) {
					getData();
				}
			}
		}
	}, [id, profile]);

	return (
		<>
			<Container className="gx-5">
				<div className="info-page-header mb-10">
					<a
						href="#"
						className="back-btn flex-row-left fit mb-20"
						onClick={(e) => {
							e.preventDefault();
							navigate(-1);
						}}
					>
						<i className="ri-arrow-left-line opacity-7" />
						<span className="text-sm text-regular-inter upper opacity-7">
							Inventory Items
						</span>
					</a>
					<Row className="flex-row-left">
						<Col className="flex-row-left">
							<div className="contact-icon flex-column rounded-8">
								<span className="text-bold-gotham text-md">
									{item?.name[0]}
								</span>
							</div>
							<h1 className="text-lg mb-0 text-medium-gotham">
								{loading ? (
									<Skeleton
										width={250}
										height={25}
										baseColor="#dadee7"
										highlightColor="#e9ecf2"
									/>
								) : (
									item.name || "--"
								)}
							</h1>
						</Col>
						<Col className="flex-row-right">
							{profile?.role?.items_edit || profile?.role?.items_delete ? (
								<Dropdown>
									<Dropdown.Toggle
										variant="light"
										className="info-control-btn text-lg rounded-4 flex-column no-arrow"
										disabled={loading}
									>
										<i className="ri-more-2-fill me-0" />
									</Dropdown.Toggle>
									<Dropdown.Menu className="text-sm dropdown-default">
										{profile?.role?.items_edit && (
											<Dropdown.Item
												href="#"
												onClick={(e) => {
													e.preventDefault();
													setEditModal(true);
												}}
											>
												Edit
											</Dropdown.Item>
										)}
										<Dropdown.Item
											href="#"
											onClick={(e) => {
												e.preventDefault();
												setEditModal(true);
											}}
										>
											Print Barcode
										</Dropdown.Item>
										{profile?.role?.items_delete && (
											<Dropdown.Item
												href="#"
												className="text-danger"
												onClick={() => setDeleteModal(true)}
											>
												Delete
											</Dropdown.Item>
										)}
									</Dropdown.Menu>
								</Dropdown>
							) : (
								""
							)}
						</Col>
					</Row>
				</div>
				<Nav variant="tabs" defaultActiveKey={tab} className="mb-20">
					<Nav.Item>
						<Nav.Link
							eventKey="details"
							className="info-tab-item"
							onClick={() => setTab("details")}
						>
							Details
						</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link
							eventKey="logs"
							className="info-tab-item"
							onClick={() => setTab("logs")}
						>
							Logs
						</Nav.Link>
					</Nav.Item>
				</Nav>
				{tab === "details" && <ItemDetails loading={loading} item={item} />}
				{tab === "logs" && <InfoLogs loading={loading} data={item} />}
			</Container>
			<Modal
				show={editModal}
				className="info-modal"
				size="lg"
				centered
				onHide={closeEditModal}
			>
				<Form onSubmit={handleSubmit}>
					<Modal.Header>
						<Modal.Title className="text-lg">Edit Item</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Name</Form.Label>
								<Form.Control
									autoFocus
									className={`text-sm form-input ${
										errors.includes("name") && "border-danger"
									}`}
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
							</Col>
						</Row>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Barcode</Form.Label>
								<InputGroup>
									<Form.Control
										className={`text-sm form-input ${
											errors.includes("barcode") && "border-danger"
										}`}
										value={barcode}
										onChange={(e) => setBarcode(e.target.value)}
									/>
									<Button
										className="btn-default-outlined text-sm flex-column code-btn"
										onClick={generateCode}
										disabled={generating}
									>
										{generating ? (
											<Spinner size="sm" />
										) : (
											<i className="ri-refresh-line" />
										)}
									</Button>
								</InputGroup>
							</Col>
							<Col>
								<Form.Label className="text-sm">SAP Asset No.</Form.Label>
								<Form.Control
									className={`text-sm form-input ${
										errors.includes("sapAssetNo") && "border-danger"
									}`}
									value={sapAssetNo}
									onChange={(e) => setSapAssetNo(e.target.value)}
								/>
							</Col>
						</Row>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Description</Form.Label>
								<Form.Control
									as="textarea"
									className={`text-sm form-input ${
										errors.includes("description") && "border-danger"
									}`}
									value={description}
									onChange={(e) => setDescription(e.target.value)}
								/>
							</Col>
						</Row>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Agency</Form.Label>
								<Form.Control
									className={`text-sm form-input ${
										errors.includes("agency") && "border-danger"
									}`}
									value={agency}
									onChange={(e) => setAgency(e.target.value)}
								/>
							</Col>
						</Row>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Location</Form.Label>
								<Form.Control
									className={`text-sm form-input ${
										errors.includes("location") && "border-danger"
									}`}
									value={location}
									onChange={(e) => setLocation(e.target.value)}
								/>
							</Col>
							<Col>
								<Form.Label className="text-sm">Area</Form.Label>
								<Form.Control
									className={`text-sm form-input ${
										errors.includes("area") && "border-danger"
									}`}
									value={area}
									onChange={(e) => setArea(e.target.value)}
								/>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="outline-secondary"
							className="btn-sm action-btn text-sm me-2"
							onClick={closeEditModal}
						>
							Close
						</Button>
						<Button
							type="submit"
							variant="primary"
							className="btn-sm action-btn text-sm btn-default"
							disabled={actionLoading}
						>
							{actionLoading ? (
								<Spinner className="btn-loader text-white" />
							) : (
								"Save Changes"
							)}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal show={deleteModal} onHide={() => setDeleteModal(false)}>
				<Modal.Body>
					<h2 className="text-lg text-medium-inter mb-15">Delete Item</h2>
					<p className="text-sm opacity-7">
						Are you sure you want to delete this item? This action cannot be
						undone.
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="light"
						className="modal-btn btn-sm dismiss-btn text-medium-inter text-sm"
						onClick={() => setDeleteModal(false)}
					>
						Cancel
					</Button>
					<Button
						variant="danger"
						className="action-btn btn-sm text-medium-inter text-sm flex-column"
						disabled={deleteLoading}
						onClick={deleteItem}
					>
						{deleteLoading ? (
							<Spinner className="btn-loader text-white" />
						) : (
							"Delete Contact"
						)}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default Item;
