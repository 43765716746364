import Skeleton from "react-loading-skeleton";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const RoleDetails = ({ loading, role }) => {
	return (
		<div>
			<div className="bg-white rounded-8 p-20 mb-30">
				<div className="mb-20">
					<h2 className="text-xl text-medium-gotham mb-0">Role Information</h2>
				</div>
				<Row className="mb-50">
					<Col>
						<span className="info-label text-sm text-medium-gotham block">
							{loading ? (
								<Skeleton
									width={120}
									height={15}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								"Name"
							)}
						</span>
						<span className="info-val">
							{loading ? (
								<Skeleton
									width={"30%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								role?.name || "--"
							)}
						</span>
					</Col>
				</Row>
				<div className="mb-20">
					<h2 className="text-xl text-medium-gotham mb-0">Permissions</h2>
				</div>
				<div className="border rounded-8 p-20 mb-20">
					<div className="mb-20">
						<span className="text-medium-gotham">Work Orders</span>
					</div>
					<Row>
						<Col>
							<span className="info-label text-sm text-medium-gotham block">
								View
							</span>
							<span className="info-val">
								{loading ? (
									<Skeleton
										width={"50%"}
										height={25}
										baseColor="#dadee7"
										highlightColor="#e9ecf2"
									/>
								) : role?.work_orders_view ? (
									"Yes"
								) : (
									"No"
								)}
							</span>
						</Col>
						<Col>
							<span className="info-label text-sm text-medium-gotham block">
								Add
							</span>
							<span className="info-val">
								{loading ? (
									<Skeleton
										width={"50%"}
										height={25}
										baseColor="#dadee7"
										highlightColor="#e9ecf2"
									/>
								) : role?.work_orders_add ? (
									"Yes"
								) : (
									"No"
								)}
							</span>
						</Col>
						<Col>
							<span className="info-label text-sm text-medium-gotham block">
								Edit
							</span>
							<span className="info-val">
								{loading ? (
									<Skeleton
										width={"50%"}
										height={25}
										baseColor="#dadee7"
										highlightColor="#e9ecf2"
									/>
								) : role?.work_orders_edit ? (
									"Yes"
								) : (
									"No"
								)}
							</span>
						</Col>
						<Col>
							<span className="info-label text-sm text-medium-gotham block">
								Delete
							</span>
							<span className="info-val">
								{loading ? (
									<Skeleton
										width={"50%"}
										height={25}
										baseColor="#dadee7"
										highlightColor="#e9ecf2"
									/>
								) : role?.work_orders_delete ? (
									"Yes"
								) : (
									"No"
								)}
							</span>
						</Col>
					</Row>
				</div>
				<div className="border rounded-8 p-20 mb-20">
					<div className="mb-20">
						<span className="text-medium-gotham">Clients</span>
					</div>
					<Row>
						<Col>
							<span className="info-label text-sm text-medium-gotham block">
								View
							</span>
							<span className="info-val">
								{loading ? (
									<Skeleton
										width={"50%"}
										height={25}
										baseColor="#dadee7"
										highlightColor="#e9ecf2"
									/>
								) : role?.clients_view ? (
									"Yes"
								) : (
									"No"
								)}
							</span>
						</Col>
						<Col>
							<span className="info-label text-sm text-medium-gotham block">
								Add
							</span>
							<span className="info-val">
								{loading ? (
									<Skeleton
										width={"50%"}
										height={25}
										baseColor="#dadee7"
										highlightColor="#e9ecf2"
									/>
								) : role?.clients_add ? (
									"Yes"
								) : (
									"No"
								)}
							</span>
						</Col>
						<Col>
							<span className="info-label text-sm text-medium-gotham block">
								Edit
							</span>
							<span className="info-val">
								{loading ? (
									<Skeleton
										width={"50%"}
										height={25}
										baseColor="#dadee7"
										highlightColor="#e9ecf2"
									/>
								) : role?.clients_edit ? (
									"Yes"
								) : (
									"No"
								)}
							</span>
						</Col>
						<Col>
							<span className="info-label text-sm text-medium-gotham block">
								Delete
							</span>
							<span className="info-val">
								{loading ? (
									<Skeleton
										width={"50%"}
										height={25}
										baseColor="#dadee7"
										highlightColor="#e9ecf2"
									/>
								) : role?.clients_delete ? (
									"Yes"
								) : (
									"No"
								)}
							</span>
						</Col>
					</Row>
				</div>
				<div className="border rounded-8 p-20 mb-20">
					<div className="mb-20">
						<span className="text-medium-gotham">Contractors</span>
					</div>
					<Row>
						<Col>
							<span className="info-label text-sm text-medium-gotham block">
								View
							</span>
							<span className="info-val">
								{loading ? (
									<Skeleton
										width={"50%"}
										height={25}
										baseColor="#dadee7"
										highlightColor="#e9ecf2"
									/>
								) : role?.contractors_view ? (
									"Yes"
								) : (
									"No"
								)}
							</span>
						</Col>
						<Col>
							<span className="info-label text-sm text-medium-gotham block">
								Add
							</span>
							<span className="info-val">
								{loading ? (
									<Skeleton
										width={"50%"}
										height={25}
										baseColor="#dadee7"
										highlightColor="#e9ecf2"
									/>
								) : role?.contractors_add ? (
									"Yes"
								) : (
									"No"
								)}
							</span>
						</Col>
						<Col>
							<span className="info-label text-sm text-medium-gotham block">
								Edit
							</span>
							<span className="info-val">
								{loading ? (
									<Skeleton
										width={"50%"}
										height={25}
										baseColor="#dadee7"
										highlightColor="#e9ecf2"
									/>
								) : role?.contractors_edit ? (
									"Yes"
								) : (
									"No"
								)}
							</span>
						</Col>
						<Col>
							<span className="info-label text-sm text-medium-gotham block">
								Delete
							</span>
							<span className="info-val">
								{loading ? (
									<Skeleton
										width={"50%"}
										height={25}
										baseColor="#dadee7"
										highlightColor="#e9ecf2"
									/>
								) : role?.contractors_delete ? (
									"Yes"
								) : (
									"No"
								)}
							</span>
						</Col>
					</Row>
				</div>
				<div className="border rounded-8 p-20 mb-20">
					<div className="mb-20">
						<span className="text-medium-gotham">Users</span>
					</div>
					<Row>
						<Col>
							<span className="info-label text-sm text-medium-gotham block">
								View
							</span>
							<span className="info-val">
								{loading ? (
									<Skeleton
										width={"50%"}
										height={25}
										baseColor="#dadee7"
										highlightColor="#e9ecf2"
									/>
								) : role?.users_view ? (
									"Yes"
								) : (
									"No"
								)}
							</span>
						</Col>
						<Col>
							<span className="info-label text-sm text-medium-gotham block">
								Add
							</span>
							<span className="info-val">
								{loading ? (
									<Skeleton
										width={"50%"}
										height={25}
										baseColor="#dadee7"
										highlightColor="#e9ecf2"
									/>
								) : role?.users_add ? (
									"Yes"
								) : (
									"No"
								)}
							</span>
						</Col>
						<Col>
							<span className="info-label text-sm text-medium-gotham block">
								Edit
							</span>
							<span className="info-val">
								{loading ? (
									<Skeleton
										width={"50%"}
										height={25}
										baseColor="#dadee7"
										highlightColor="#e9ecf2"
									/>
								) : role?.users_edit ? (
									"Yes"
								) : (
									"No"
								)}
							</span>
						</Col>
						<Col>
							<span className="info-label text-sm text-medium-gotham block">
								Delete
							</span>
							<span className="info-val">
								{loading ? (
									<Skeleton
										width={"50%"}
										height={25}
										baseColor="#dadee7"
										highlightColor="#e9ecf2"
									/>
								) : role?.users_delete ? (
									"Yes"
								) : (
									"No"
								)}
							</span>
						</Col>
					</Row>
				</div>
				<div className="border rounded-8 p-20 mb-20">
					<div className="mb-20">
						<span className="text-medium-gotham">Roles</span>
					</div>
					<Row>
						<Col>
							<span className="info-label text-sm text-medium-gotham block">
								View
							</span>
							<span className="info-val">
								{loading ? (
									<Skeleton
										width={"50%"}
										height={25}
										baseColor="#dadee7"
										highlightColor="#e9ecf2"
									/>
								) : role?.roles_view ? (
									"Yes"
								) : (
									"No"
								)}
							</span>
						</Col>
						<Col>
							<span className="info-label text-sm text-medium-gotham block">
								Add
							</span>
							<span className="info-val">
								{loading ? (
									<Skeleton
										width={"50%"}
										height={25}
										baseColor="#dadee7"
										highlightColor="#e9ecf2"
									/>
								) : role?.roles_add ? (
									"Yes"
								) : (
									"No"
								)}
							</span>
						</Col>
						<Col>
							<span className="info-label text-sm text-medium-gotham block">
								Edit
							</span>
							<span className="info-val">
								{loading ? (
									<Skeleton
										width={"50%"}
										height={25}
										baseColor="#dadee7"
										highlightColor="#e9ecf2"
									/>
								) : role?.roles_edit ? (
									"Yes"
								) : (
									"No"
								)}
							</span>
						</Col>
						<Col>
							<span className="info-label text-sm text-medium-gotham block">
								Delete
							</span>
							<span className="info-val">
								{loading ? (
									<Skeleton
										width={"50%"}
										height={25}
										baseColor="#dadee7"
										highlightColor="#e9ecf2"
									/>
								) : role?.roles_delete ? (
									"Yes"
								) : (
									"No"
								)}
							</span>
						</Col>
					</Row>
				</div>
			</div>
		</div>
	);
};

export default RoleDetails;
