import React, { useState, useEffect, useContext } from "react";
import { ProfileContext } from "../context/ProfileContext";
import { SessionContext } from "../context/SessionContext";
import { Outlet, NavLink, useNavigate } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import ToastDisplay from "../components/ToastDisplay";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Logo from "../assets/hero.png";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

const Layout = () => {
	const navigate = useNavigate();
	const [profile, setProfile] = useContext(ProfileContext);
	const [session] = useContext(SessionContext);
	const [showNav, setShowNav] = useState(false);
	const [passwordModal, setPasswordModal] = useState(false);
	const [actionLoading, setActionLoading] = useState(false);
	const [password, setPassword] = useState("");

	const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
		<a
			href=""
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
			className="header-user-dropdown text-sm flex-row-left"
		>
			{children}
		</a>
	));

	const closePasswordModal = () => {
		setPasswordModal(false);
		setPassword("");
	};

	const resetPassword = async (e) => {
		e.preventDefault();
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
			body: JSON.stringify({
				password: password,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/u/${profile?.id}/password`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			toast(<ToastDisplay status="error" content={data.msg} />);
		} else {
			closePasswordModal();
			toast(<ToastDisplay status="success" content={data.msg} />);
			setProfile(data.profile);
		}
		setActionLoading(false);
	};

	useEffect(() => {
		if (profile?.temp_pw) {
			setPasswordModal(true);
		}
	}, [profile]);

	return (
		<>
			<Toaster
				position="bottom-right"
				toastOptions={{ className: "snackbar-container" }}
			/>
			<div className="main-nav bg-white">
				<div className="main-nav-header flex-column full">
					<img src={Logo} alt="logo" className="nav-logo" />
				</div>
				<div className="main-nav-body">
					{profile?.role?.work_orders_view && (
						<NavLink
							to="/property/work-orders"
							className="main-nav-item opacity-5 flex-row-left full"
						>
							<i className="nav-icon ri-tools-line"></i>
							<span className="text-medium-gotham text-sm">Work Orders</span>
						</NavLink>
					)}
					{profile?.role?.clients_view && (
						<NavLink
							to="/property/clients"
							className="main-nav-item opacity-5 flex-row-left full"
						>
							<i className="nav-icon ri-contacts-book-3-line"></i>
							<span className="text-medium-gotham text-sm">Clients</span>
						</NavLink>
					)}
					{profile?.role?.contractors_view && (
						<NavLink
							to="/property/contractors"
							className="main-nav-item opacity-5 flex-row-left full"
						>
							<i className="nav-icon ri-team-line"></i>
							<span className="text-medium-gotham text-sm">Contractors</span>
						</NavLink>
					)}
					{profile?.role?.roles_view && (
						<NavLink
							to="/property/roles"
							className="main-nav-item opacity-5 flex-row-left full"
						>
							<i className="nav-icon ri-lock-line"></i>
							<span className="text-medium-gotham text-sm">
								Roles & Permissions
							</span>
						</NavLink>
					)}
					{profile?.role?.users_view && (
						<NavLink
							to="/property/users"
							className="main-nav-item opacity-5 flex-row-left full"
						>
							<i className="nav-icon ri-user-line"></i>
							<span className="text-medium-gotham text-sm">Property Users</span>
						</NavLink>
					)}
					<div className="support-link-container flex-column">
						<div className="support-link p-20 flex-column bg-gray">
							<span className="text-medium-gotham align-center opacity-7">
								Need help?
							</span>
							<span className="text-medium-gotham align-center opacity-7 mb-15">
								Just let us know.
							</span>
							<Button className="support-link-btn btn-default btn-sm btn-rounded text-sm text-medium-gotham full">
								Contact Support
							</Button>
						</div>
					</div>
				</div>
			</div>
			<div className="content">
				<div className="page-header bg-white full flex-row-left">
					<Container className="gx-5">
						<Row className="flex-row-left">
							<Col>
								<div className="header-client">
									<span className="text-medium-gotham text-lg">
										Repairs & Maintenance Management System
									</span>
								</div>
							</Col>
							<Col className="flex-row-right">
								<Dropdown align="end">
									<Dropdown.Toggle as={CustomToggle}>
										<div className="header-user bg-default flex-column">
											<span className="text-white text-medium-inter">
												{profile?.name[0]}
											</span>
										</div>
									</Dropdown.Toggle>
									<Dropdown.Menu className="account-dropdown dropdown-default">
										<Dropdown.Item href="/property/logout" className="text-sm">
											Logout
										</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</Col>
						</Row>
					</Container>
				</div>
				<Outlet />
			</div>
			<Modal
				show={passwordModal}
				onHide={() => closePasswordModal()}
				centered
				backdrop="static"
				keyboard={false}
			>
				<Form onSubmit={resetPassword}>
					<Modal.Header>
						<Modal.Title className="text-lg">Change Password</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Row className="mb-30">
							<Col>
								<Form.Label className="text-sm">New Password</Form.Label>
								<Form.Control
									required
									type="password"
									className="form-input"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
								/>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							type="submit"
							className="btn-default btn-sm action-btn text-sm me-2 flex-row-center"
							disabled={actionLoading}
						>
							{actionLoading ? "Saving.." : "Save"}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
};

export default Layout;
