import React, { createContext, useState } from "react";
import ReportItem from "../components/ReportItem";

export const ReportContext = createContext();

export const ReportProvider = (props) => {
	const [report, setReport] = useState(null);

	return (
		<ReportContext.Provider value={[report, setReport]}>
			{props.children}
			{report && (
				<div className="report-loader">
					<div className="report-loader-body bg-white">
						<ReportItem report={report} />
					</div>
				</div>
			)}
		</ReportContext.Provider>
	);
};
