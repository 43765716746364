import React, { createContext, useEffect, useState } from "react";
import Cookies from "js-cookie";

export const ClientContext = createContext();

export const ClientProvider = (props) => {
	const [clientSession, setClientSession] = useState(
		Cookies.get("rmmsClient") || null
	);

	const fetchClient = async () => {
		const requestOptions = {
			headers: {
				method: "GET",
				"X-Auth-Token": clientSession,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/clients/me`,
			requestOptions
		);

		if (!response.ok) {
			setClientSession(null);
			return;
		}
		Cookies.set("rmmsClient", clientSession);
	};

	useEffect(() => {
		if (clientSession) {
			fetchClient();
		} else {
			Cookies.remove("rmmsClient");
		}
	}, [clientSession]);

	return (
		<ClientContext.Provider value={[clientSession, setClientSession]}>
			{props.children}
		</ClientContext.Provider>
	);
};
