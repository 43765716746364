import { useState, useEffect } from "react";
import AWS from "aws-sdk";

const FileUpload = ({
	index,
	file,
	filePaths,
	setFilePaths,
	files,
	setFiles,
}) => {
	const [path, setPath] = useState("");
	const [key, setKey] = useState("");
	const [progress, setProgress] = useState(0);
	const [uploading, setUploading] = useState(false);
	const S3_BUCKET = "aep";
	const REGION = "sgp1";

	AWS.config.update({
		accessKeyId: process.env.REACT_APP_STORAGE_KEY_ID,
		secretAccessKey: process.env.REACT_APP_STORAGE_ACCESS_KEY,
	});

	const myBucket = new AWS.S3({
		endpoint: "sgp1.digitaloceanspaces.com",
		params: { Bucket: S3_BUCKET },
	});

	const handleUpload = () => {
		setUploading(true);
		let filename = file.uuid;
		setKey(`rmms/${filename}`);

		const params = {
			Body: file.file,
			ACL: "public-read",
			Bucket: S3_BUCKET,
			Key: `rmms/${filename}`,
		};

		myBucket
			.putObject(params)
			.on("httpUploadProgress", (evt) => {
				setProgress(Math.round((evt.loaded / evt.total) * 100));
			})
			.send((err) => {
				setUploading(false);
				setProgress(0);
				if (err) {
					console.log(err);
				} else {
					setPath(URL.createObjectURL(file.file));
					const s3Url = `https://${S3_BUCKET}.${REGION}.cdn.digitaloceanspaces.com/${params.Key}`;
				}
			});
	};

	const handleDelete = () => {
		setFilePaths(filePaths.filter((filePath) => filePath !== file.uuid));

		const newFiles = [...files];
		newFiles.splice(index, 1);
		setFiles(newFiles);
	};

	useEffect(() => {
		if (file) {
			handleUpload();
		}
	}, [file]);

	return (
		<div
			className="file-upload bg-cover rounded-4 border"
			style={{
				background: path ? `url(${path})` : "transparent",
			}}
		>
			{uploading && (
				<>
					<div className="upload-progress-overlay" />
					<div
						className="upload-progress-bar"
						style={{
							width: `${progress < 20 ? 20 : progress}%`,
						}}
					>
						<div className="upload-progress-pulse bg-white" />
					</div>
				</>
			)}
			{!uploading && (
				<div className="file-delete-overlay flex-column">
					<i
						className="ri-delete-bin-line text-white m-0 text-lg"
						onClick={handleDelete}
					/>
				</div>
			)}
		</div>
	);
};

export default FileUpload;
