import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { ClientContext } from "../context/ClientContext";
import { toast, Toaster } from "react-hot-toast";
import ToastDisplay from "../components/ToastDisplay";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import Logo from "../assets/hero.png";

const ClientLogin = () => {
	document.title = "Log in | RMMS - Repairs & Maintenance Management System";
	const navigate = useNavigate();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const [clientSession, setClientSession] = useContext(ClientContext);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [passwordEmail, setPasswordEmail] = useState("");
	const [loading, setLoading] = useState(false);
	const [actionLoading, setActionLoading] = useState(false);
	const [passwordModal, setPasswordModal] = useState(false);

	const authUser = async (e) => {
		e.preventDefault();
		setLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				email: email,
				password: password,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/clients/login`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			toast(<ToastDisplay status="error" content={data.msg} />, {
				position: "top-center",
			});
			setPassword("");
		} else {
			setClientSession(data.client_session);
		}
		setLoading(false);
	};

	const createPasswordToken = async (e) => {
		e.preventDefault();
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				email: passwordEmail,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/clients/password/forgot`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			toast(<ToastDisplay status="error" content={data.msg} />, {
				position: "top-center",
			});
		} else {
			toast(<ToastDisplay status="success" content={data.msg} />, {
				position: "top-center",
			});
		}
		setActionLoading(false);
		setPasswordModal(false);
		setPasswordEmail("");
	};

	useEffect(() => {
		if (clientSession) {
			if (searchParams.has("r")) {
				navigate(searchParams.get("r"));
			} else {
				navigate("/");
			}
		}
	}, [, clientSession]);

	return (
		<>
			<div className="full-page bg-white flex-column">
				<Toaster
					position="bottom-right"
					toastOptions={{ className: "snackbar-container" }}
				/>
				<Container className="flex-column">
					<div className="login-form-container mb-50 bg-white col-xl-4 col-lg-6 col-md-7 col-12">
						<Form onSubmit={authUser} className="login-form full flex-column">
							<img src={Logo} alt="login-logo" className="login-logo mb-30" />
							<div className="align-left full mb-30">
								<h1 className="text-medium-gotham text-xl mb-0">Sign In</h1>
								<span className="login-title text-md">
									Repairs & Maintenance Management System
								</span>
							</div>
							<Form.Control
								type="email"
								className="login-input rounded-4 mb-20"
								placeholder="Username/Email"
								autoCapitalize="off"
								autoComplete="off"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
							<Form.Control
								type="password"
								className="login-input rounded-4 mb-30"
								placeholder="Password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
							<Button
								type="submit"
								className="btn-default login-btn btn-md text-sm text-medium-inter full mb-15"
								disabled={loading}
							>
								{loading ? "Signing you in.." : "Sign in"}
							</Button>
							<Link to="/signup" className="full">
								<Button className="btn-default-outlined login-btn btn-md text-sm text-medium-inter full mb-30">
									Create account
								</Button>
							</Link>
							<a
								href="#"
								className="text-sm"
								onClick={(e) => {
									e.preventDefault();
									setPasswordModal(true);
								}}
							>
								Forgot password?
							</a>
						</Form>
					</div>
				</Container>
			</div>
			<Modal
				show={passwordModal}
				centered
				onHide={() => {
					setPasswordModal(false);
					setPasswordEmail("");
				}}
				className="filter-modal"
			>
				<Form onSubmit={createPasswordToken}>
					<Modal.Header>
						<Modal.Title className="text-lg">Reset Password</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Email</Form.Label>
								<Form.Control
									type="email"
									className="form-input"
									value={passwordEmail}
									onChange={(e) => setPasswordEmail(e.target.value)}
								/>
							</Col>
						</Row>
						<p className="label-md opacity-7 mb-0">
							We will send a password reset link to your email.
						</p>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="outline-secondary"
							className="btn-sm action-btn text-sm me-2"
							onClick={() => {
								setPasswordModal(false);
								setPasswordEmail("");
							}}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							variant="primary"
							className="btn-sm action-btn text-sm btn-default"
							disabled={actionLoading}
						>
							{actionLoading ? (
								<Spinner className="btn-loader text-white" />
							) : (
								"Send Link"
							)}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
};

export default ClientLogin;
