import React, { useEffect, useContext, useState } from "react";
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import { SessionContext } from "../context/SessionContext";
import { ProfileContext } from "../context/ProfileContext";
import { WithContext as ReactTags } from "react-tag-input";
import Select from "react-select";
import toast from "react-hot-toast";
import ToastDisplay from "../components/ToastDisplay";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Pagination from "../components/Pagination";
import Spinner from "react-bootstrap/esm/Spinner";
import ContentLoader from "../components/ContentLoader";
import CountryOptions from "../components/CountryOptions";
import Empty from "../components/Empty";

const Contractors = () => {
	document.title = "Contractors | Repairs & Maintenance Management System";
	const navigate = useNavigate();
	const [session] = useContext(SessionContext);
	const [profile] = useContext(ProfileContext);
	const countries = CountryOptions();
	const [contractors, setContractors] = useState([]);
	const [meta, setMeta] = useState(null);
	const [loading, setLoading] = useState(false);
	const [actionLoading, setActionLoading] = useState(false);
	const [contractorModal, setContractorModal] = useState(false);
	const [errors, setErrors] = useState([]);

	const [company, setCompany] = useState("");
	const [shortName, setShortName] = useState("");
	const [phone, setPhone] = useState("");
	const [emails, setEmails] = useState([]);
	const [contactPerson, setContactPerson] = useState("");
	const [address, setAddress] = useState("");
	const [city, setCity] = useState("");
	const [province, setProvince] = useState("");
	const [country, setCountry] = useState({});
	const [postalCode, setPostalCode] = useState("");
	const [bank, setBank] = useState("");
	const [bankBranch, setBankBranch] = useState("");
	const [bankKey, setBankKey] = useState("");
	const [bankAccountName, setBankAccountName] = useState("");
	const [bankAccountNo, setBankAccountNo] = useState("");
	const [remarks, setRemarks] = useState("");

	const [searchParams, setSearchParams] = useSearchParams({});
	const [searchQ, setSearchQ] = useState(searchParams.get("q") || "");
	const [searchCity, setSearchCity] = useState(searchParams.get("city") || "");
	const [searchProvince, setSearchProvince] = useState(
		searchParams.get("province") || ""
	);
	const [searchCountry, setSearchCountry] = useState(
		searchParams.get("country") || ""
	);

	const searchFields = [{ key: "q", value: searchQ }];

	const requiredFields = [{ key: "company", value: company }];

	const getData = async () => {
		setLoading(true);
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/contractors/${window.location.search}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			setContractors(data.data);
			setMeta(data.meta);
		}
		setLoading(false);
	};

	const saveContractor = async () => {
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
			body: JSON.stringify({
				company: company,
				short_name: shortName,
				contact_person: contactPerson,
				phone: phone,
				emails: emails,
				address: address,
				city: city,
				province: province,
				country: country?.value || "",
				postal_code: postalCode,
				bank: bank,
				bank_branch: bankBranch,
				bank_key: bankKey,
				bank_account_name: bankAccountName,
				bank_account_number: bankAccountNo,
				remarks: remarks,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/contractors/`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			toast(<ToastDisplay status="error" content={data.msg} />);
		} else {
			toast(<ToastDisplay status="success" content={data.msg} />);
			closeContractorModal();
			navigate(`/property/contractors/${data.contractor_id}`);
		}
		setActionLoading(false);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setActionLoading(true);
		var newErrors = [];

		requiredFields.map((field) => {
			if (!field.value || field.value === "" || field.value.length === 0) {
				newErrors.push(field.key);
			}
		});

		if (newErrors.length !== 0) {
			toast(
				<ToastDisplay
					status="error"
					content="Please fill out all required fields."
				/>
			);
			setActionLoading(false);
			setErrors(newErrors);
			return;
		}
		saveContractor();
	};

	const search = (e) => {
		e.preventDefault();
		var newSearch = { ...searchParams };
		searchFields.map((field) => {
			if (field.value !== "") {
				newSearch[field.key] = field.value;
			} else {
				delete newSearch[field.key];
			}
		});
		setSearchParams(newSearch);
	};

	const closeContractorModal = () => {
		setContractorModal(false);
		setCompany("");
		setShortName("");
		setPhone("");
		setContactPerson("");
		setAddress("");
		setCity("");
		setProvince("");
		setCountry({});
		setPostalCode("");
		setBank("");
		setBankBranch("");
		setBankKey("");
		setBankAccountName("");
		setBankAccountNo("");
		setRemarks("");
		setErrors([]);
	};

	const KeyCodes = {
		comma: 188,
		enter: 13,
		space: 32,
	};

	const emailDelimiters = [KeyCodes.comma, KeyCodes.enter, KeyCodes.space];

	const handleDeleteEmail = (i) => {
		setEmails(emails.filter((tag, index) => index !== i));
	};

	const handleNewEmail = (tag) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (emailRegex.test(tag.text)) {
			setEmails([...emails, tag]);
		} else {
			toast(<ToastDisplay status="error" content="Invalid email format." />);
		}
	};

	const handleEmailBlur = (tag) => {
		if (tag) {
			handleNewEmail({ id: tag, text: tag });
		}
	};

	useEffect(() => {
		if (profile) {
			if (!profile.role.contractors_view) {
				navigate("/");
			} else {
				getData();
			}
		}
	}, [searchParams, profile]);

	return (
		<>
			<Container className="gx-5">
				<Row className="content-header flex-row-left mb-30">
					<Col>
						<h1 className="text-xl m-0">Contractors</h1>
					</Col>
				</Row>
				<Row className="flex-row-left mb-20">
					<Col className="col-5">
						<Form onSubmit={search}>
							<div className="search-container bg-white flex-row-left">
								<i className="ri-search-line search-icon" />
								<Form.Control
									className="text-sm"
									placeholder="Search contractors"
									value={searchQ}
									onChange={(e) => setSearchQ(e.target.value)}
								/>
							</div>
						</Form>
					</Col>
					<Col className="col-7 flex-row-right">
						<Pagination
							meta={meta}
							searchParams={searchParams}
							setSearchParams={setSearchParams}
							searchFields={searchFields}
							className="pull-right flex-row-left"
						/>
						<Button
							variant="light"
							className="refresh-btn flex-column"
							onClick={getData}
						>
							<i className="ri-restart-line opacity-7" />
						</Button>
						{profile?.role?.contractors_add && (
							<Button
								className="control-btn btn-sm btn-default text-medium-gotham text-sm"
								onClick={() => setContractorModal(true)}
							>
								Add Contractor
							</Button>
						)}
					</Col>
				</Row>
				<Row className="content-table-header bg-white gx-0">
					<Col className="p-10">
						<span className="text-sm text-medium-gotham block">Company</span>
					</Col>
					<Col className="p-10">
						<span className="text-sm text-medium-gotham block">Short Name</span>
					</Col>
					<Col className="p-10">
						<span className="text-sm text-medium-gotham block">
							Contact Person
						</span>
					</Col>
					<Col className="p-10">
						<span className="text-sm text-medium-gotham block">Email</span>
					</Col>
				</Row>
				{loading ? (
					<ContentLoader />
				) : contractors?.length === 0 ? (
					<Empty label="No contractors" className="bg-white" />
				) : (
					contractors?.map((contractor) => (
						<Link
							key={contractor.id}
							to={`/property/contractors/${contractor.id}`}
						>
							<Row className="content-table-item bg-white gx-0">
								<Col className="p-10 ellipsis">
									<span className="text-sm">{contractor.company}</span>
								</Col>
								<Col className="p-10 ellipsis">
									<span className="text-sm">
										{contractor.short_name || "--"}
									</span>
								</Col>
								<Col className="p-10 ellipsis">
									<span className="email-preview text-sm ellipsis">
										{contractor.contact_person || "--"}
									</span>
								</Col>
								<Col className="p-10 flex-row-left">
									<span className="email-preview text-sm ellipsis">
										{contractor.emails[0]?.email || "--"}
									</span>
									{contractor.emails.length > 1 && (
										<div className="email-badge rounded-8 flex-column">
											<span className="label-md text-medium-gotham opacity-7">
												+{contractor.emails.length - 1}
											</span>
										</div>
									)}
								</Col>
							</Row>
						</Link>
					))
				)}
				<Row className="mt-4 mb-20">
					<Col className="flex-row-right">
						<Pagination
							meta={meta}
							searchParams={searchParams}
							setSearchParams={setSearchParams}
							searchFields={searchFields}
							className="pull-right flex-row-left pagination-bottom"
						/>
					</Col>
				</Row>
			</Container>
			<Modal
				show={contractorModal}
				className="info-modal"
				centered
				size="lg"
				onHide={closeContractorModal}
			>
				<Form onSubmit={handleSubmit}>
					<Modal.Header>
						<Modal.Title className="text-lg">New Contractor</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="mb-10">
							<h2 className="text-lg text-medium-gotham mb-0">
								Company Information
							</h2>
						</div>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Company</Form.Label>
								<Form.Control
									autoFocus
									className={`text-sm form-input ${
										errors.includes("company") && "border-danger"
									}`}
									value={company}
									onChange={(e) => setCompany(e.target.value)}
								/>
							</Col>
							<Col>
								<Form.Label className="text-sm">Short Name</Form.Label>
								<Form.Control
									className={`text-sm form-input ${
										errors.includes("shortName") && "border-danger"
									}`}
									value={shortName}
									onChange={(e) => setShortName(e.target.value)}
								/>
							</Col>
						</Row>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Emails</Form.Label>
								<ReactTags
									tags={emails}
									delimiters={emailDelimiters}
									handleInputBlur={handleEmailBlur}
									handleDelete={handleDeleteEmail}
									handleAddition={handleNewEmail}
									inputFieldPosition="bottom"
									allowDragDrop={false}
									placeholder=""
									autofocus={false}
									classNames={{
										tagInput: "full",
										tagInputField: `full form-control form-input text-sm ${
											errors.includes("emails") && "border-danger"
										}`,
										tag: "tag-item text-sm bg-green text-white rounded-4",
										remove: "tag-remove-btn text-white flex-column",
									}}
								/>
							</Col>
						</Row>
						<Row className="mb-50">
							<Col>
								<Form.Label className="text-sm">Phone</Form.Label>
								<Form.Control
									className={`text-sm form-input ${
										errors.includes("phone") && "border-danger"
									}`}
									value={phone}
									onChange={(e) => setPhone(e.target.value)}
								/>
							</Col>
							<Col>
								<Form.Label className="text-sm">Contact Person</Form.Label>
								<Form.Control
									className={`text-sm form-input ${
										errors.includes("contactPerson") && "border-danger"
									}`}
									value={contactPerson}
									onChange={(e) => setContactPerson(e.target.value)}
								/>
							</Col>
						</Row>
						<div className="mb-10">
							<h2 className="text-lg text-medium-gotham mb-0">Address</h2>
						</div>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Address</Form.Label>
								<Form.Control
									as="textarea"
									className={`text-sm form-input ${
										errors.includes("address") && "border-danger"
									}`}
									value={address}
									onChange={(e) => setAddress(e.target.value)}
								/>
							</Col>
						</Row>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Town/City</Form.Label>
								<Form.Control
									className={`text-sm form-input ${
										errors.includes("city") && "border-danger"
									}`}
									value={city}
									onChange={(e) => setCity(e.target.value)}
								/>
							</Col>
							<Col>
								<Form.Label className="text-sm">Province</Form.Label>
								<Form.Control
									className={`text-sm form-input ${
										errors.includes("province") && "border-danger"
									}`}
									value={province}
									onChange={(e) => setProvince(e.target.value)}
								/>
							</Col>
						</Row>
						<Row className="mb-50">
							<Col>
								<Form.Label className="text-sm">Country</Form.Label>
								<Select
									isClearable
									defaultValue={country}
									onChange={setCountry}
									options={countries}
									menuShouldScrollIntoView={false}
									maxMenuHeight={200}
									placeholder=""
									styles={{
										control: (baseStyles, state) => ({
											...baseStyles,
											borderColor: state.isFocused
												? "#c7883a !important"
												: "#dee2e6 !important",
											boxShadow: "none",
											outline: "none",
											borderRadius: 4,
											fontSize: "0.875rem",
										}),
										option: (baseStyles, state) => ({
											...baseStyles,
											fontSize: ".875rem",
											color: "#050911",
											backgroundColor: state.isFocused
												? "#f6d4aa !important"
												: "#fff",
										}),
									}}
								/>
							</Col>
							<Col>
								<Form.Label className="text-sm">Postal Code</Form.Label>
								<Form.Control
									className={`text-sm form-input ${
										errors.includes("postalCode") && "border-danger"
									}`}
									value={postalCode}
									onChange={(e) => setPostalCode(e.target.value)}
								/>
							</Col>
						</Row>
						<div className="mb-10">
							<h2 className="text-lg text-medium-gotham mb-0">
								Bank Account Details
							</h2>
						</div>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Bank</Form.Label>
								<Form.Control
									className={`text-sm form-input ${
										errors.includes("bank") && "border-danger"
									}`}
									value={bank}
									onChange={(e) => setBank(e.target.value)}
								/>
							</Col>
						</Row>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Branch</Form.Label>
								<Form.Control
									className={`text-sm form-input ${
										errors.includes("bankBranch") && "border-danger"
									}`}
									value={bankBranch}
									onChange={(e) => setBankBranch(e.target.value)}
								/>
							</Col>
						</Row>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Bank Key/BSB</Form.Label>
								<Form.Control
									className={`text-sm form-input ${
										errors.includes("bankKey") && "border-danger"
									}`}
									value={bankKey}
									onChange={(e) => setBankKey(e.target.value)}
								/>
							</Col>
						</Row>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Account Name</Form.Label>
								<Form.Control
									className={`text-sm form-input ${
										errors.includes("bankAccountName") && "border-danger"
									}`}
									value={bankAccountName}
									onChange={(e) => setBankAccountName(e.target.value)}
								/>
							</Col>
						</Row>
						<Row className="mb-50">
							<Col>
								<Form.Label className="text-sm">Account Number</Form.Label>
								<Form.Control
									className={`text-sm form-input ${
										errors.includes("bankAccountNo") && "border-danger"
									}`}
									value={bankAccountNo}
									onChange={(e) => setBankAccountNo(e.target.value)}
								/>
							</Col>
						</Row>
						<div className="mb-10">
							<h2 className="text-lg text-medium-gotham mb-0">Miscellaneous</h2>
						</div>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Remarks</Form.Label>
								<Form.Control
									as="textarea"
									className={`text-sm form-input ${
										errors.includes("remarks") && "border-danger"
									}`}
									value={remarks}
									onChange={(e) => setRemarks(e.target.value)}
								/>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="outline-secondary"
							className="btn-sm action-btn text-sm me-2"
							onClick={closeContractorModal}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							variant="primary"
							className="btn-sm action-btn flex-column text-sm btn-default"
							disabled={actionLoading}
						>
							{actionLoading ? (
								<Spinner className="btn-loader text-white" />
							) : (
								"Save"
							)}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
};

export default Contractors;
