import React, { useEffect, useContext, useState } from "react";
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import { SessionContext } from "../context/SessionContext";
import { ProfileContext } from "../context/ProfileContext";
import { WithContext as ReactTags } from "react-tag-input";
import { getFullName } from "../components/Utils";
import Select from "react-select";
import toast from "react-hot-toast";
import ToastDisplay from "../components/ToastDisplay";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Nav from "react-bootstrap/Nav";
import Pagination from "../components/Pagination";
import Spinner from "react-bootstrap/esm/Spinner";
import ContentLoader from "../components/ContentLoader";
import VillageOptions from "../components/VillageOptions";
import AgencyOptions from "../components/AgencyOptions";
import Empty from "../components/Empty";

const Clients = () => {
	document.title = "Clients | Repairs & Maintenance Management System";
	const navigate = useNavigate();
	const [session] = useContext(SessionContext);
	const [profile] = useContext(ProfileContext);
	const villages = VillageOptions();
	const agencies = AgencyOptions();
	const [clients, setClients] = useState([]);
	const [meta, setMeta] = useState(null);
	const [loading, setLoading] = useState(false);
	const [actionLoading, setActionLoading] = useState(false);
	const [clientModal, setClientModal] = useState(false);
	const [filterModal, setFilterModal] = useState(false);
	const [showFilters, setShowFilters] = useState(false);
	const [errors, setErrors] = useState([]);
	const [tab, setTab] = useState("details");

	const [prefix, setPrefix] = useState("");
	const [firstName, setFirstName] = useState("");
	const [middleName, setMiddleName] = useState("");
	const [lastName, setLastName] = useState("");
	const [suffix, setSuffix] = useState("");
	const [gender, setGender] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const [address, setAddress] = useState("");
	const [village, setVillage] = useState("");

	const [homePhone, setHomePhone] = useState("");
	const [msisdn, setMsisdn] = useState("");
	const [agency, setAgency] = useState("");
	const [position, setPosition] = useState("");
	const [workPhone, setWorkPhone] = useState("");
	const [localExt, setLocalExt] = useState("");
	const [fax, setFax] = useState("");
	const [remarks, setRemarks] = useState("");

	const [partnerPrefix, setPartnerPrefix] = useState("");
	const [partnerFirstName, setPartnerFirstName] = useState("");
	const [partnerMiddleName, setPartnerMiddleName] = useState("");
	const [partnerLastName, setPartnerLastName] = useState("");
	const [partnerSuffix, setPartnerSuffix] = useState("");
	const [partnerEmails, setPartnerEmails] = useState([]);
	const [partnerMsisdn, setPartnerMsisdn] = useState("");

	const [searchParams, setSearchParams] = useSearchParams({});
	const [searchQ, setSearchQ] = useState(searchParams.get("q") || "");
	const [searchAgency, setSearchAgency] = useState(
		searchParams.get("agency") || ""
	);

	const searchFields = [
		{ key: "q", value: searchQ },
		{ key: "agency", value: searchAgency },
	];

	const filterFields = [
		{
			key: "agency",
			value: searchAgency,
			defaultVal: "",
			edit: setSearchAgency,
		},
	];

	const requiredFields = [
		{ key: "firstName", value: firstName },
		{ key: "lastName", value: lastName },
		{ key: "email", value: email },
		{ key: "password", value: password },
	];

	const getData = async () => {
		setLoading(true);
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/clients/${window.location.search}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			setClients(data.data);
			setMeta(data.meta);
		}
		setLoading(false);
	};

	const saveClient = async () => {
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
			body: JSON.stringify({
				prefix: prefix,
				first_name: firstName,
				middle_name: middleName,
				last_name: lastName,
				suffix: suffix,
				gender: gender,
				email: email,
				password: password,
				home_phone: homePhone,
				msisdn: msisdn,
				address: address,
				village: village?.value || "",
				agency: agency?.value || "",
				position: position,
				work_phone: workPhone,
				local_ext: localExt,
				fax: fax,
				remarks: remarks,
				partner: {
					prefix: partnerPrefix,
					first_name: partnerFirstName,
					middle_name: partnerMiddleName,
					last_name: partnerLastName,
					suffix: partnerSuffix,
					emails: partnerEmails,
					msisdn: partnerMsisdn,
				},
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/clients/`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			toast(<ToastDisplay status="error" content={data.msg} />);
		} else {
			toast(<ToastDisplay status="success" content={data.msg} />);
			closeClientModal();
			navigate(`/property/clients/${data.client_id}`);
		}
		setActionLoading(false);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setActionLoading(true);
		var newErrors = [];

		requiredFields.map((field) => {
			if (!field.value || field.value === "" || field.value.length === 0) {
				newErrors.push(field.key);
			}
		});

		if (newErrors.length !== 0) {
			toast(
				<ToastDisplay
					status="error"
					content="Please fill out all required fields."
				/>
			);
			setActionLoading(false);
			setErrors(newErrors);
			return;
		}
		saveClient();
	};

	const search = (e) => {
		e.preventDefault();
		var newSearch = { ...searchParams };
		searchFields.map((field) => {
			if (field.value !== "") {
				newSearch[field.key] = field.value;
			} else {
				delete newSearch[field.key];
			}
		});
		setSearchParams(newSearch);
		setFilterModal(false);
	};

	const removeFilter = (e, func, key) => {
		e.preventDefault();
		func("");
		var newSearch = { ...searchParams };
		searchFields.map((field) => {
			if (field.value !== "" && field.key !== key) {
				newSearch[field.key] = field.value;
			} else {
				delete newSearch[field.key];
			}
		});
		setSearchParams(newSearch);
	};

	const clearFilters = (e) => {
		e.preventDefault();
		setSearchParams({});
		filterFields.map((field) => {
			field.edit(field.defaultVal);
		});
	};

	const closeClientModal = () => {
		setClientModal(false);
		setPrefix("");
		setFirstName("");
		setMiddleName("");
		setLastName("");
		setSuffix("");
		setGender("");
		setEmail("");
		setPassword("");
		setHomePhone("");
		setMsisdn("");
		setAddress("");
		setVillage({});
		setAgency("");
		setPosition("");
		setWorkPhone("");
		setLocalExt("");
		setFax("");
		setRemarks("");

		setPartnerPrefix("");
		setPartnerFirstName("");
		setPartnerMiddleName("");
		setPartnerLastName("");
		setPartnerSuffix("");

		setPartnerEmails([]);
		setPartnerMsisdn("");

		setTab("details");
		setErrors([]);
	};

	const KeyCodes = {
		comma: 188,
		enter: 13,
		space: 32,
	};

	const emailDelimiters = [KeyCodes.comma, KeyCodes.enter, KeyCodes.space];

	const handleDeletePartnerEmail = (i) => {
		setPartnerEmails(partnerEmails.filter((tag, index) => index !== i));
	};

	const handleNewPartnerEmail = (tag) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (emailRegex.test(tag.text)) {
			setPartnerEmails([...partnerEmails, tag]);
		} else {
			toast(<ToastDisplay status="error" content="Invalid email format." />);
		}
	};

	const handlePartnerEmailBlur = (tag) => {
		if (tag) {
			handleNewPartnerEmail({ id: tag, text: tag });
		}
	};

	const isEmpty = (value) => {
		return value === "" || value === null || value === undefined;
	};

	useEffect(() => {
		if (profile) {
			if (!profile.role.clients_view) {
				navigate("/");
			} else {
				getData();
				if (filterFields.every((field) => isEmpty(field.value))) {
					setShowFilters(false);
				} else {
					setShowFilters(true);
				}
			}
		}
	}, [searchParams, profile]);

	return (
		<>
			<Container className="gx-5">
				<Row className="content-header flex-row-left mb-30">
					<Col>
						<h1 className="text-xl m-0">Clients</h1>
					</Col>
				</Row>
				<Row className="flex-row-left mb-20">
					<Col className="col-5">
						<Form onSubmit={search}>
							<div className="search-container bg-white flex-row-left">
								<i className="ri-search-line search-icon" />
								<Form.Control
									className="text-sm"
									placeholder="Search clients"
									value={searchQ}
									onChange={(e) => setSearchQ(e.target.value)}
								/>
								<Button
									variant="light"
									className="filter-btn flex-column"
									onClick={() => setFilterModal(true)}
								>
									<i className="ri-equalizer-3-fill m-0" />
								</Button>
							</div>
						</Form>
					</Col>
					<Col className="col-7 flex-row-right">
						<Pagination
							meta={meta}
							searchParams={searchParams}
							setSearchParams={setSearchParams}
							searchFields={searchFields}
							className="pull-right flex-row-left"
						/>
						<Button
							variant="light"
							className="refresh-btn flex-column"
							onClick={getData}
						>
							<i className="ri-restart-line opacity-7" />
						</Button>
						{profile?.role?.clients_add && (
							<Button
								className="control-btn btn-sm btn-default text-medium-gotham text-sm"
								onClick={() => setClientModal(true)}
							>
								Add Client
							</Button>
						)}
					</Col>
				</Row>
				{showFilters ? (
					<div className="filters-container">
						<span className="filters-label text-medium-mont">Filters:</span>
						{filterFields.map((field) => {
							const { key, value } = field;
							if (value) {
								return (
									<div
										key={key}
										className="filter-item bg-default flex-row-left rounded-4"
									>
										<span className="text-white label-md mb-0">
											{key.charAt(0).toUpperCase() + key.slice(1)}: {value}
										</span>
										<a
											href="#"
											className="remove-filter"
											onClick={(e) => removeFilter(e, field.edit, key)}
										>
											<i className="ri-close-line text-white" />
										</a>
									</div>
								);
							} else {
								return "";
							}
						})}
						<div className="filter-item rounded-4 fit">
							<a
								href="#"
								className="text-sm text-medium-mont text-default"
								onClick={clearFilters}
							>
								Clear Filters
							</a>
						</div>
					</div>
				) : (
					""
				)}
				<Row className="content-table-header bg-white gx-0">
					<Col className="col-3 p-10">
						<span className="text-sm text-medium-gotham block">Name</span>
					</Col>
					<Col className="col-3 p-10">
						<span className="text-sm text-medium-gotham block">Agency</span>
					</Col>
					<Col className="col-3 p-10">
						<span className="text-sm text-medium-gotham block">Email</span>
					</Col>
					<Col className="col-3 p-10">
						<span className="text-sm text-medium-gotham block">Status</span>
					</Col>
				</Row>
				{loading ? (
					<ContentLoader />
				) : clients?.length === 0 ? (
					<Empty label="No clients" className="bg-white" />
				) : (
					clients?.map((client) => (
						<Link key={client.id} to={`/property/clients/${client.id}`}>
							<Row className="content-table-item bg-white gx-0">
								<Col className="col-3 p-10 ellipsis">
									<span className="text-sm">{getFullName(client)}</span>
								</Col>
								<Col className="col-3 p-10 ellipsis">
									<span className="text-sm">{client.agency || "--"}</span>
								</Col>
								<Col className="col-3 p-10 ellipsis">
									<span className="text-sm">{client.email || "--"}</span>
								</Col>
								<Col className="col-3 p-10 ellipsis">
									<div
										className={`client-status fit flex-column ${client.status}`}
									>
										<span className="label-md text-medium-gotham">
											{client.status || "--"}
										</span>
									</div>
								</Col>
							</Row>
						</Link>
					))
				)}
				<Row className="mt-4 mb-20">
					<Col className="flex-row-right">
						<Pagination
							meta={meta}
							searchParams={searchParams}
							setSearchParams={setSearchParams}
							searchFields={searchFields}
							className="pull-right flex-row-left pagination-bottom"
						/>
					</Col>
				</Row>
			</Container>
			<Modal
				show={filterModal}
				centered
				onHide={() => setFilterModal(false)}
				className="filter-modal"
			>
				<Form onSubmit={search}>
					<Modal.Header>
						<Modal.Title className="text-lg">Filters</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Agency</Form.Label>
								<Select
									isClearable
									defaultValue={searchAgency}
									onChange={(input) => setSearchAgency(input.value)}
									options={agencies}
									menuShouldScrollIntoView={false}
									maxMenuHeight={200}
									placeholder=""
									styles={{
										control: (baseStyles, state) => ({
											...baseStyles,
											borderColor: state.isFocused
												? "#c7883a !important"
												: "#dee2e6 !important",
											boxShadow: "none",
											outline: "none",
											borderRadius: 4,
											fontSize: "0.875rem",
										}),
										option: (baseStyles, state) => ({
											...baseStyles,
											fontSize: ".875rem",
											color: "#050911",
											backgroundColor: state.isFocused
												? "#f6d4aa !important"
												: "#fff",
										}),
									}}
								/>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="outline-secondary"
							className="btn-sm action-btn text-sm me-2"
							onClick={() => setFilterModal(false)}
						>
							Close
						</Button>
						<Button
							type="submit"
							variant="primary"
							className="btn-sm action-btn text-sm btn-default"
						>
							Apply
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal
				show={clientModal}
				className="info-modal"
				centered
				size="lg"
				onHide={closeClientModal}
			>
				<Form onSubmit={handleSubmit}>
					<Modal.Header>
						<Modal.Title className="text-lg">New Client</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Nav variant="tabs" defaultActiveKey={tab} className="mb-30">
							<Nav.Item>
								<Nav.Link
									eventKey="details"
									className="info-tab-item light text-sm"
									onClick={() => setTab("details")}
								>
									Details
								</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link
									eventKey="partner"
									className="info-tab-item light text-sm"
									onClick={() => setTab("partner")}
								>
									Partner
								</Nav.Link>
							</Nav.Item>
						</Nav>
						{tab === "details" && (
							<>
								<div className="mb-10">
									<h2 className="text-lg text-medium-gotham mb-0">
										Client Information
									</h2>
								</div>
								<Row className="mb-20">
									<Col>
										<Form.Label className="text-sm">Prefix</Form.Label>
										<Form.Control
											autoFocus
											className={`text-sm form-input ${
												errors.includes("prefix") && "border-danger"
											}`}
											value={prefix}
											onChange={(e) => setPrefix(e.target.value)}
										/>
									</Col>
									<Col />
								</Row>
								<Row className="mb-20">
									<Col>
										<Form.Label className="text-sm">First Name*</Form.Label>
										<Form.Control
											className={`text-sm form-input ${
												errors.includes("firstName") && "border-danger"
											}`}
											value={firstName}
											onChange={(e) => setFirstName(e.target.value)}
										/>
									</Col>
									<Col>
										<Form.Label className="text-sm">Middle Name</Form.Label>
										<Form.Control
											className={`text-sm form-input ${
												errors.includes("middleName") && "border-danger"
											}`}
											value={middleName}
											onChange={(e) => setMiddleName(e.target.value)}
										/>
									</Col>
								</Row>
								<Row className="mb-20">
									<Col>
										<Form.Label className="text-sm">Last Name*</Form.Label>
										<Form.Control
											className={`text-sm form-input ${
												errors.includes("lastName") && "border-danger"
											}`}
											value={lastName}
											onChange={(e) => setLastName(e.target.value)}
										/>
									</Col>
									<Col>
										<Form.Label className="text-sm">Suffix</Form.Label>
										<Form.Control
											className={`text-sm form-input ${
												errors.includes("suffix") && "border-danger"
											}`}
											value={suffix}
											onChange={(e) => setSuffix(e.target.value)}
										/>
									</Col>
								</Row>
								<Row className="mb-20">
									<Col>
										<Form.Label className="text-sm">Gender</Form.Label>
										<Form.Select
											className={`text-sm form-input ${
												errors.includes("gender") && "border-danger"
											}`}
											value={gender}
											onChange={(e) => setGender(e.target.value)}
										>
											<option value=""></option>
											<option value="Male">Male</option>
											<option value="Female">Female</option>
											<option value="Other">Other</option>
										</Form.Select>
									</Col>
								</Row>
								<Row className="mb-50">
									<Col>
										<Form.Label className="text-sm">Agency</Form.Label>
										<Select
											isClearable
											defaultValue={agency}
											onChange={setAgency}
											options={agencies}
											menuShouldScrollIntoView={false}
											maxMenuHeight={200}
											placeholder=""
											styles={{
												control: (baseStyles, state) => ({
													...baseStyles,
													borderColor: state.isFocused
														? "#c7883a !important"
														: "#dee2e6 !important",
													boxShadow: "none",
													outline: "none",
													borderRadius: 4,
													fontSize: "0.875rem",
												}),
												option: (baseStyles, state) => ({
													...baseStyles,
													fontSize: ".875rem",
													color: "#050911",
													backgroundColor: state.isFocused
														? "#f6d4aa !important"
														: "#fff",
												}),
											}}
										/>
									</Col>
									<Col>
										<Form.Label className="text-sm">Position</Form.Label>
										<Form.Control
											className={`text-sm form-input ${
												errors.includes("position") && "border-danger"
											}`}
											value={position}
											onChange={(e) => setPosition(e.target.value)}
										/>
									</Col>
								</Row>
								<div className="mb-10">
									<h2 className="text-lg text-medium-gotham mb-0">
										Account Information
									</h2>
								</div>
								<Row className="mb-20">
									<Col>
										<Form.Label className="text-sm">Email*</Form.Label>
										<Form.Control
											type="email"
											className={`text-sm form-input ${
												errors.includes("email") && "border-danger"
											}`}
											value={email}
											onChange={(e) => setEmail(e.target.value)}
										/>
									</Col>
								</Row>
								<Row className="mb-50">
									<Col>
										<Form.Label className="text-sm">
											Temporary Password
										</Form.Label>
										<Form.Control
											type="password"
											className={`text-sm form-input ${
												errors.includes("password") && "border-danger"
											}`}
											value={password}
											onChange={(e) => setPassword(e.target.value)}
										/>
									</Col>
								</Row>
								<div className="mb-10">
									<h2 className="text-lg text-medium-gotham mb-0">
										Contact Information
									</h2>
								</div>
								<Row className="mb-20">
									<Col>
										<Form.Label className="text-sm">Address</Form.Label>
										<Form.Control
											className={`text-sm form-input ${
												errors.includes("address") && "border-danger"
											}`}
											value={address}
											onChange={(e) => setAddress(e.target.value)}
										/>
									</Col>
								</Row>
								<Row className="mb-20">
									<Col>
										<Form.Label className="text-sm">
											Village/Apartment
										</Form.Label>
										<Select
											isClearable
											defaultValue={village}
											onChange={setVillage}
											options={villages}
											menuShouldScrollIntoView={false}
											maxMenuHeight={200}
											placeholder=""
											styles={{
												control: (baseStyles, state) => ({
													...baseStyles,
													borderColor: state.isFocused
														? "#c7883a !important"
														: "#dee2e6 !important",
													boxShadow: "none",
													outline: "none",
													borderRadius: 4,
													fontSize: "0.875rem",
												}),
												option: (baseStyles, state) => ({
													...baseStyles,
													fontSize: ".875rem",
													color: "#050911",
													backgroundColor: state.isFocused
														? "#f6d4aa !important"
														: "#fff",
												}),
											}}
										/>
									</Col>
								</Row>
								<Row className="mb-20">
									<Col>
										<Form.Label className="text-sm">Home Phone #</Form.Label>
										<Form.Control
											className={`text-sm form-input ${
												errors.includes("homePhone") && "border-danger"
											}`}
											value={homePhone}
											onChange={(e) => setHomePhone(e.target.value)}
										/>
									</Col>
									<Col>
										<Form.Label className="text-sm">Mobile #</Form.Label>
										<Form.Control
											className={`text-sm form-input ${
												errors.includes("msisdn") && "border-danger"
											}`}
											value={msisdn}
											onChange={(e) => setMsisdn(e.target.value)}
										/>
									</Col>
								</Row>
								<Row className="mb-50">
									<Col>
										<Form.Label className="text-sm">Work Phone</Form.Label>
										<Form.Control
											className={`text-sm form-input ${
												errors.includes("workPhone") && "border-danger"
											}`}
											value={workPhone}
											onChange={(e) => setWorkPhone(e.target.value)}
										/>
									</Col>
									<Col>
										<Form.Label className="text-sm">Local Ext.</Form.Label>
										<Form.Control
											className={`text-sm form-input ${
												errors.includes("localExt") && "border-danger"
											}`}
											value={localExt}
											onChange={(e) => setLocalExt(e.target.value)}
										/>
									</Col>
									<Col>
										<Form.Label className="text-sm">Fax</Form.Label>
										<Form.Control
											className={`text-sm form-input ${
												errors.includes("fax") && "border-danger"
											}`}
											value={fax}
											onChange={(e) => setFax(e.target.value)}
										/>
									</Col>
								</Row>
								<div className="mb-10">
									<h2 className="text-lg text-medium-gotham mb-0">
										Miscellaneous
									</h2>
								</div>
								<Row className="mb-20">
									<Col>
										<Form.Label className="text-sm">Remarks</Form.Label>
										<Form.Control
											as="textarea"
											className={`text-sm form-input ${
												errors.includes("remarks") && "border-danger"
											}`}
											value={remarks}
											onChange={(e) => setRemarks(e.target.value)}
										/>
									</Col>
								</Row>
							</>
						)}
						{tab === "partner" && (
							<>
								<div className="mb-10">
									<h2 className="text-lg text-medium-gotham mb-0">
										Partner Information
									</h2>
								</div>
								<Row className="mb-20">
									<Col>
										<Form.Label className="text-sm">Prefix</Form.Label>
										<Form.Control
											autoFocus
											className={`text-sm form-input ${
												errors.includes("partnerPrefix") && "border-danger"
											}`}
											value={partnerPrefix}
											onChange={(e) => setPartnerPrefix(e.target.value)}
										/>
									</Col>
									<Col />
								</Row>
								<Row className="mb-20">
									<Col>
										<Form.Label className="text-sm">First Name</Form.Label>
										<Form.Control
											className={`text-sm form-input ${
												errors.includes("partnerFirstName") && "border-danger"
											}`}
											value={partnerFirstName}
											onChange={(e) => setPartnerFirstName(e.target.value)}
										/>
									</Col>
									<Col>
										<Form.Label className="text-sm">Middle Name</Form.Label>
										<Form.Control
											className={`text-sm form-input ${
												errors.includes("partnerMiddleName") && "border-danger"
											}`}
											value={partnerMiddleName}
											onChange={(e) => setPartnerMiddleName(e.target.value)}
										/>
									</Col>
								</Row>
								<Row className="mb-50">
									<Col>
										<Form.Label className="text-sm">Last Name</Form.Label>
										<Form.Control
											className={`text-sm form-input ${
												errors.includes("partnerLastName") && "border-danger"
											}`}
											value={partnerLastName}
											onChange={(e) => setPartnerLastName(e.target.value)}
										/>
									</Col>
									<Col>
										<Form.Label className="text-sm">Suffix</Form.Label>
										<Form.Control
											className={`text-sm form-input ${
												errors.includes("partnerSuffix") && "border-danger"
											}`}
											value={partnerSuffix}
											onChange={(e) => setPartnerSuffix(e.target.value)}
										/>
									</Col>
								</Row>
								<div className="mb-10">
									<h2 className="text-lg text-medium-gotham mb-0">
										Partner Contact Information
									</h2>
								</div>
								<Row className="mb-20">
									<Col>
										<Form.Label className="text-sm">Emails</Form.Label>
										<ReactTags
											tags={partnerEmails}
											delimiters={emailDelimiters}
											handleInputBlur={handlePartnerEmailBlur}
											handleDelete={handleDeletePartnerEmail}
											handleAddition={handleNewPartnerEmail}
											inputFieldPosition="bottom"
											allowDragDrop={false}
											placeholder=""
											autofocus={false}
											classNames={{
												tagInput: "full",
												tagInputField: `full form-control form-input text-sm ${
													errors.includes("partnerEmails") && "border-danger"
												}`,
												tag: "tag-item text-sm bg-green text-white rounded-4",
												remove: "tag-remove-btn text-white flex-column",
											}}
										/>
									</Col>
								</Row>
								<Row className="mb-20">
									<Col>
										<Form.Label className="text-sm">Mobile #</Form.Label>
										<Form.Control
											className={`text-sm form-input ${
												errors.includes("partnerMsisdn") && "border-danger"
											}`}
											value={partnerMsisdn}
											onChange={(e) => setPartnerMsisdn(e.target.value)}
										/>
									</Col>
								</Row>
							</>
						)}
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="outline-secondary"
							className="btn-sm action-btn text-sm me-2"
							onClick={closeClientModal}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							variant="primary"
							className="btn-sm action-btn flex-column text-sm btn-default"
							disabled={actionLoading}
						>
							{actionLoading ? (
								<Spinner className="btn-loader text-white" />
							) : (
								"Save"
							)}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
};

export default Clients;
