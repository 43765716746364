import React from "react";

const NoPage = () => {
	return (
		<div className="full-page flex-column full">
			<h1 className="text-xl text-bold-mont mb-10">404</h1>
			<span className="text-lg text-medium-mont mb-10">Page Not Found</span>
			<p className="text-sm mb-30">
				Sorry, we couldn't find the page you're looking for.
			</p>
			<a href="/">Back to Home</a>
		</div>
	);
};

export default NoPage;
