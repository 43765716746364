const ToastDisplay = ({ status, content }) => {
	return (
		<div className={`snackbar flex-row-left rounded-8 ${status}`}>
			{status === "success" ? (
				<i className="snackbar-icon ri-check-line text-white" />
			) : (
				<i className="snackbar-icon ri-error-warning-line text-white" />
			)}
			<span className="snackbar-content text-sm text-white">{content}</span>
		</div>
	);
};

export default ToastDisplay;
