import React, { useEffect, useContext, useState, useMemo, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ClientContext } from "../context/ClientContext";
import { getAddress } from "../components/Utils";
import { v4 as uuidv4 } from "uuid";
import format from "date-fns/format";
import Skeleton from "react-loading-skeleton";
import toast from "react-hot-toast";
import ToastDisplay from "../components/ToastDisplay";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import Spinner from "react-bootstrap/Spinner";
import FileUpload from "../components/FileUpload";
import WoAttachment from "../components/WoAttachment";
import ClientWorkOrderUpdate from "../components/ClientWorkOrderUpdate";
import WorkOrderComment from "../components/WorkOrderComment";

const ClientWorkOrder = () => {
	document.title = "Work Orders | Repairs & Maintenance Management System";
	let { id } = useParams();
	const fileRef = useRef(null);
	const navigate = useNavigate();
	const [clientSession] = useContext(ClientContext);
	const [workOrder, setWorkOrder] = useState(null);
	const [loading, setLoading] = useState(true);
	const [actionLoading, setActionLoading] = useState(false);
	const [commentLoading, setCommentLoading] = useState(false);
	const [comment, setComment] = useState("");
	const [editModal, setEditModal] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [errors, setErrors] = useState([]);

	const [shortDesc, setShortDesc] = useState("");
	const [desc, setDesc] = useState("");
	const [type, setType] = useState("Corrective");
	const [category, setCategory] = useState("");
	const [attachments, setAttachments] = useState([]);
	const [removeAttachments, setRemoveAttachments] = useState([]);

	const [files, setFiles] = useState([]);
	const [filePaths, setFilePaths] = useState([]);

	const requiredFields = [
		{ key: "shortDesc", value: shortDesc },
		{ key: "desc", value: desc },
		{ key: "type", value: type },
		{ key: "category", value: category },
	];

	const getData = async () => {
		setLoading(true);
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": clientSession,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/clients/work-orders/w/${id}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			setWorkOrder(data.work_order);

			setAttachments(
				data.work_order.attachments.map((attachment) => ({
					...attachment,
				}))
			);

			setShortDesc(data.work_order.short_description);
			setDesc(data.work_order.description);
			setCategory(data.work_order.category);
		}
		setLoading(false);
	};

	const editWorkOrder = async () => {
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": clientSession,
			},
			body: JSON.stringify({
				short_description: shortDesc,
				description: desc,
				type: type,
				category: category,
				attachments: filePaths,
				remove_attachments: removeAttachments,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/clients/work-orders/w/${id}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			toast(<ToastDisplay status="error" content={data.msg} />);
		} else {
			toast(<ToastDisplay status="success" content={data.msg} />);
			getData();
			closeEditModal();
		}
		setActionLoading(false);
	};

	const deleteWorkOrder = async () => {
		setDeleteLoading(true);
		const requestOptions = {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": clientSession,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/clients/work-orders/w/${id}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			toast(<ToastDisplay status="success" content={data.msg} />);
			navigate("/work-orders");
			setDeleteModal(false);
		}
		setDeleteLoading(false);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setActionLoading(true);
		var newErrors = [];

		requiredFields.map((field) => {
			if (!field.value || field.value === "" || field.value.length === 0) {
				newErrors.push(field.key);
			}
		});

		if (newErrors.length !== 0) {
			toast(
				<ToastDisplay
					status="error"
					content="Please fill out all required fields."
				/>
			);
			setActionLoading(false);
			setErrors(newErrors);
			return;
		}
		editWorkOrder();
	};

	const handleFiles = (e) => {
		const newFiles = Array.from(e.target.files);
		const forUpload = [...files];
		const paths = [...filePaths];

		newFiles.map((file) => {
			const newUid = uuidv4();
			forUpload.push({
				uuid: `${newUid}.${file.name.split(".").pop()}`,
				file: file,
			});
			paths.push(`${newUid}.${file.name.split(".").pop()}`);
		});

		setFiles(forUpload);
		setFilePaths(paths);
	};

	const closeEditModal = () => {
		setEditModal(false);

		setAttachments(
			workOrder.attachments.map((attachment) => ({
				...attachment,
			}))
		);

		setShortDesc("");
		setDesc("");
		setCategory("");

		setErrors([]);
	};

	const postComment = async (e) => {
		e.preventDefault();
		setCommentLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": clientSession,
			},
			body: JSON.stringify({
				comment: comment,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/clients/work-orders/w/${workOrder.id}/comments`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			toast(<ToastDisplay status="error" content="Something went wrong." />);
		} else {
			setComment("");
			setWorkOrder(data.work_order);
		}
		setCommentLoading(false);
	};

	useEffect(() => {
		if (id) {
			getData();
		}
	}, [id]);

	return (
		<>
			<div className="content-header mb-20">
				<Container className="gx-md-5 gx-4">
					<Row className="back-btn-row mb-20 flex-row-left">
						<Col>
							<a
								href="#"
								className="back-btn flex-row-left fit"
								onClick={(e) => {
									e.preventDefault();
									navigate(-1);
								}}
							>
								<i className="ri-arrow-left-line" />
								<span className="text-sm text-regular-inter upper">
									Work Orders
								</span>
							</a>
						</Col>
						<Col className="flex-row-right display-sm">
							<Dropdown>
								<Dropdown.Toggle
									variant="light"
									className="info-control-btn-sm text-lg rounded-4 flex-row-right no-arrow"
									disabled={loading}
								>
									<i className="ri-more-2-fill me-0" />
								</Dropdown.Toggle>
								<Dropdown.Menu className="text-sm dropdown-default">
									<Dropdown.Item
										href="#"
										onClick={(e) => {
											e.preventDefault();
											setEditModal(true);
										}}
										disabled={
											workOrder?.status === "Completed" ||
											workOrder?.status === "Canceled"
										}
									>
										Edit
									</Dropdown.Item>
									<Dropdown.Item
										href="#"
										onClick={() => setDeleteModal(true)}
										disabled={
											workOrder?.status === "Completed" ||
											workOrder?.status === "Canceled"
										}
									>
										Delete
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</Col>
					</Row>
					<Row className="flex-row-left">
						<Col className="client-header-content flex-row-left">
							<h1 className="client-header-title text-lg text-medium-gotham mb-0 me-3">
								Work Order #{workOrder?.wo_number}
							</h1>
							{!loading && (
								<div
									className={`wo-status fit flex-column ${workOrder?.status}`}
								>
									<span className="label-md">{workOrder?.status || "--"}</span>
								</div>
							)}
						</Col>
						<Col className="flex-row-right display-lg">
							<Dropdown>
								<Dropdown.Toggle
									variant="light"
									className="info-control-btn text-lg rounded-4 flex-column no-arrow"
									disabled={loading}
								>
									<i className="ri-more-2-fill me-0" />
								</Dropdown.Toggle>
								<Dropdown.Menu className="text-sm dropdown-default">
									<Dropdown.Item
										href="#"
										onClick={(e) => {
											e.preventDefault();
											setEditModal(true);
										}}
										disabled={workOrder?.status !== "New"}
									>
										Edit
									</Dropdown.Item>
									<Dropdown.Item
										href="#"
										className="text-danger"
										// onClick={() => setDeleteModal(true)}
										disabled={workOrder?.status !== "New"}
									>
										Delete
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</Col>
					</Row>
				</Container>
			</div>
			<Container className="info-group-container gx-md-5 gx-0">
				<div className="info-group bg-white rounded-8 mb-30">
					<div>
						<div className="mb-20">
							<h2 className="text-lg text-medium-gotham mb-0">
								Work Order Information
							</h2>
						</div>
						<Row>
							<Col className="col-md-6 col-12 mb-30">
								<span className="info-label text-sm text-medium-gotham block">
									{loading ? (
										<Skeleton
											width={120}
											height={15}
											baseColor="#dadee7"
											highlightColor="#e9ecf2"
										/>
									) : (
										"Short Description"
									)}
								</span>
								<span className="info-val">
									{loading ? (
										<Skeleton
											width={"80%"}
											height={25}
											baseColor="#dadee7"
											highlightColor="#e9ecf2"
										/>
									) : (
										workOrder?.short_description || "--"
									)}
								</span>
							</Col>
							<Col className="col-md-6 col-12 mb-30">
								<span className="info-label text-sm text-medium-gotham block">
									{loading ? (
										<Skeleton
											width={120}
											height={15}
											baseColor="#dadee7"
											highlightColor="#e9ecf2"
										/>
									) : (
										"WO Date"
									)}
								</span>
								<span className="info-val">
									{loading ? (
										<Skeleton
											width={"80%"}
											height={25}
											baseColor="#dadee7"
											highlightColor="#e9ecf2"
										/>
									) : workOrder?.wo_date ? (
										format(new Date(workOrder.wo_date), "MMM dd, yyyy")
									) : (
										"--"
									)}
								</span>
							</Col>
						</Row>
						<Row>
							<Col className="col-md-6 col-12 mb-30">
								<span className="info-label text-sm text-medium-gotham block">
									{loading ? (
										<Skeleton
											width={120}
											height={15}
											baseColor="#dadee7"
											highlightColor="#e9ecf2"
										/>
									) : (
										"Type"
									)}
								</span>
								<span className="info-val">
									{loading ? (
										<Skeleton
											width={"80%"}
											height={25}
											baseColor="#dadee7"
											highlightColor="#e9ecf2"
										/>
									) : (
										workOrder?.type_ || "--"
									)}
								</span>
							</Col>
							<Col className="col-md-6 col-12 mb-30">
								<span className="info-label text-sm text-medium-gotham block">
									{loading ? (
										<Skeleton
											width={120}
											height={15}
											baseColor="#dadee7"
											highlightColor="#e9ecf2"
										/>
									) : (
										"Category"
									)}
								</span>
								<span className="info-val">
									{loading ? (
										<Skeleton
											width={"80%"}
											height={25}
											baseColor="#dadee7"
											highlightColor="#e9ecf2"
										/>
									) : (
										workOrder?.category || "--"
									)}
								</span>
							</Col>
						</Row>
						<Row className="mb-30">
							<Col>
								<span className="info-label text-sm text-medium-gotham block">
									{loading ? (
										<Skeleton
											width={120}
											height={15}
											baseColor="#dadee7"
											highlightColor="#e9ecf2"
										/>
									) : (
										"Location"
									)}
								</span>
								<span className="info-val">
									{loading ? (
										<Skeleton
											width={"80%"}
											height={25}
											baseColor="#dadee7"
											highlightColor="#e9ecf2"
										/>
									) : workOrder?.client?.address ? (
										getAddress(workOrder.client)
									) : (
										"--"
									)}
								</span>
							</Col>
						</Row>
						<Row className="mb-30">
							<Col>
								<span className="info-label text-sm text-medium-gotham block">
									{loading ? (
										<Skeleton
											width={120}
											height={15}
											baseColor="#dadee7"
											highlightColor="#e9ecf2"
										/>
									) : (
										"Description"
									)}
								</span>
								<span className="info-val">
									{loading ? (
										<Skeleton
											width={"80%"}
											height={25}
											baseColor="#dadee7"
											highlightColor="#e9ecf2"
										/>
									) : (
										workOrder?.description || "--"
									)}
								</span>
							</Col>
						</Row>
						{workOrder?.attachments?.length !== 0 && (
							<>
								<span className="info-label text-sm text-medium-gotham block mb-10">
									{loading ? (
										<Skeleton
											width={120}
											height={15}
											baseColor="#dadee7"
											highlightColor="#e9ecf2"
										/>
									) : (
										"Attachments"
									)}
								</span>
								<Row className="wo-attachments-row">
									{loading ? (
										<Skeleton
											width={100}
											height={100}
											baseColor="#dadee7"
											highlightColor="#e9ecf2"
										/>
									) : (
										<div className="order-files-container">
											{workOrder?.attachments?.map((attachment) => (
												<WoAttachment
													key={attachment.id}
													attachment={attachment}
												/>
											))}
										</div>
									)}
								</Row>
							</>
						)}
						<div className="mb-10">
							<h2 className="text-lg text-medium-gotham mb-0">Updates</h2>
						</div>
						<Row className="client-updates-row mb-50">
							{loading ? (
								<Skeleton
									width="100%"
									height={80}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								<div className="order-items-container">
									{!workOrder?.updates || workOrder?.updates?.length === 0 ? (
										<div className="rounded-8 border bg-white flex-column p-50">
											<h2 className="text-sm mb-10 opacity-7">
												No updates yet
											</h2>
										</div>
									) : (
										""
									)}
									{workOrder?.updates?.map((workOrderUpdate) => (
										<ClientWorkOrderUpdate
											key={workOrderUpdate.id}
											update={workOrderUpdate}
										/>
									))}
								</div>
							)}
						</Row>
						{!loading && (
							<>
								<div className="mb-10">
									<h2 className="text-lg text-medium-gotham mb-0">Comments</h2>
								</div>
								{!workOrder?.comments || workOrder?.comments?.length === 0 ? (
									<div className="empty-comments mb-20">
										<span className="text-sm opacity-5">No comments yet.</span>
									</div>
								) : (
									workOrder?.comments?.map((comment) => (
										<WorkOrderComment key={comment.id} comment={comment} />
									))
								)}
								<Form onSubmit={postComment}>
									<div className="comment-input-container border rounded-8 mb-50">
										<Form.Control
											as="textarea"
											className="comment-input text-sm mb-10"
											placeholder="Write your comment here."
											value={comment}
											onChange={(e) => setComment(e.target.value)}
										/>
										<div className="comment-control border-top">
											<Row className="flex-row-left">
												<Col className="flex-row-right">
													<Button
														type="submit"
														className="btn-default-light btn-sm comment-btn flex-column"
														disabled={!comment || commentLoading}
													>
														{commentLoading ? (
															<Spinner className="btn-loader text-default" />
														) : (
															"Post Comment"
														)}
													</Button>
												</Col>
											</Row>
										</div>
									</div>
								</Form>
							</>
						)}
					</div>
				</div>
			</Container>
			<Modal show={editModal} centered size="lg" onHide={closeEditModal}>
				<Form onSubmit={handleSubmit}>
					<Modal.Header>
						<Modal.Title className="text-lg">Edit Work Order</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Short Description*</Form.Label>
								<Form.Control
									autoFocus
									className={`text-sm form-input ${
										errors.includes("shortDesc") && "border-danger"
									}`}
									value={shortDesc}
									onChange={(e) => setShortDesc(e.target.value)}
								/>
							</Col>
						</Row>
						{/* <Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Type*</Form.Label>
								<Form.Select
									className={`text-sm form-input ${
										errors.includes("type") && "border-danger"
									}`}
									value={type}
									onChange={(e) => setType(e.target.value)}
								>
									<option value=""></option>
									<option value="Preventive Maintenance">
										Preventive Maintenance
									</option>
									<option value="Corrective Maintenance">
										Corrective Maintenance
									</option>
								</Form.Select>
							</Col>
						</Row> */}
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Category*</Form.Label>
								<Form.Select
									className={`text-sm form-input ${
										errors.includes("category") && "border-danger"
									}`}
									value={category}
									onChange={(e) => setCategory(e.target.value)}
								>
									<option value=""></option>
									<option value="Electrical">Electrical</option>
									<option value="Plumbing">Plumbing</option>
								</Form.Select>
							</Col>
						</Row>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Description*</Form.Label>
								<Form.Control
									as="textarea"
									className={`text-sm form-input ${
										errors.includes("desc") && "border-danger"
									}`}
									value={desc}
									onChange={(e) => setDesc(e.target.value)}
								/>
							</Col>
						</Row>
						<Row className="mb-20">
							<Form.Label className="text-sm">Attachments</Form.Label>
							<div className="order-files-container">
								{attachments?.map((attachment) => (
									<WoAttachment
										key={attachment.id}
										attachment={attachment}
										setAttachments={setAttachments}
										setRemoveAttachments={setRemoveAttachments}
									/>
								))}
								{files?.map((file, index) => (
									<FileUpload
										key={index}
										index={index}
										file={file}
										files={files}
										setFiles={setFiles}
										filePaths={filePaths}
										setFilePaths={setFilePaths}
									/>
								))}
								<Button
									variant="light"
									className="file-btn flex-column border"
									onClick={() => fileRef.current.click()}
								>
									<i className="ri-upload-line opacity-7" />
								</Button>
								<input
									ref={fileRef}
									type="file"
									multiple
									className="hidden"
									accept="image/*"
									capture="environment"
									onChange={handleFiles}
								/>
							</div>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="outline-secondary"
							className="btn-sm action-btn text-sm me-2"
							onClick={closeEditModal}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							variant="primary"
							className="btn-sm action-btn flex-column text-sm btn-default"
							disabled={actionLoading}
						>
							{actionLoading ? (
								<Spinner className="btn-loader text-white" />
							) : (
								"Save Changes"
							)}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal show={deleteModal} onHide={() => setDeleteModal(false)}>
				<Modal.Body>
					<h2 className="text-lg text-medium-inter mb-15">Delete Work Order</h2>
					<p className="text-sm opacity-7">
						Are you sure you want to delete this work order? This action cannot
						be undone.
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="light"
						className="modal-btn btn-sm dismiss-btn text-medium-inter text-sm"
						onClick={() => setDeleteModal(false)}
					>
						Cancel
					</Button>
					<Button
						variant="danger"
						className="action-btn btn-sm text-medium-inter text-sm flex-column"
						disabled={deleteLoading}
						onClick={deleteWorkOrder}
					>
						{deleteLoading ? (
							<Spinner className="btn-loader text-white" />
						) : (
							"Delete"
						)}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default ClientWorkOrder;
