import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/esm/Spinner";
import Logo from "../assets/hero.png";

const Loader = () => {
	return (
		<div className="master-loader bg-white full-page full flex-column">
			<Container className="flex-column">
				<img src={Logo} alt="logo" className="loader-logo mb-30" />
				<Spinner className="text-green" />
			</Container>
		</div>
	);
};

export default Loader;
