import Skeleton from "react-loading-skeleton";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const UserDetails = ({ loading, user }) => {
	return (
		<div>
			<div className="bg-white rounded-8 p-20 mb-30">
				<div className="mb-20">
					<h2 className="text-xl text-medium-gotham mb-0">User Information</h2>
				</div>
				<Row className="mb-30">
					<Col>
						<span className="info-label text-sm text-medium-gotham block">
							{loading ? (
								<Skeleton
									width={120}
									height={15}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								"Name"
							)}
						</span>
						<span className="info-val">
							{loading ? (
								<Skeleton
									width={"80%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								user?.name || "--"
							)}
						</span>
					</Col>
					<Col>
						<span className="info-label text-sm text-medium-gotham block">
							{loading ? (
								<Skeleton
									width={120}
									height={15}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								"Email"
							)}
						</span>
						<span className="info-val">
							{loading ? (
								<Skeleton
									width={"80%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								user?.email || "--"
							)}
						</span>
					</Col>
				</Row>
				<Row className="mb-30">
					<Col>
						<span className="info-label text-sm text-medium-gotham block">
							{loading ? (
								<Skeleton
									width={120}
									height={15}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								"Role"
							)}
						</span>
						<span className="info-val">
							{loading ? (
								<Skeleton
									width={"80%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								user?.role?.name || "--"
							)}
						</span>
					</Col>
				</Row>
			</div>
		</div>
	);
};

export default UserDetails;
