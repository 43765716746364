import { Link } from "react-router-dom";
import { getFullName, getAddress } from "./Utils";
import Skeleton from "react-loading-skeleton";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const WorkOrderClient = ({ workOrder, loading }) => {
	return (
		<div className="bg-white rounded-8 p-20 mb-30">
			<div className="mb-20">
				<h2 className="text-xl text-medium-gotham mb-0">Client Information</h2>
			</div>
			<Row className="mb-30">
				<Col>
					<span className="info-label text-sm text-medium-gotham block">
						{loading ? (
							<Skeleton
								width={120}
								height={15}
								baseColor="#dadee7"
								highlightColor="#e9ecf2"
							/>
						) : (
							"Client Name"
						)}
					</span>
					<Link to={`/property/clients/${workOrder.client.id}`}>
						<span className="info-val underline">
							{loading ? (
								<Skeleton
									width={"80%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								getFullName(workOrder.client) || "--"
							)}
						</span>
					</Link>
				</Col>
				<Col>
					<span className="info-label text-sm text-medium-gotham block">
						{loading ? (
							<Skeleton
								width={120}
								height={15}
								baseColor="#dadee7"
								highlightColor="#e9ecf2"
							/>
						) : (
							"Email"
						)}
					</span>
					<span className="info-val">
						{loading ? (
							<Skeleton
								width={"80%"}
								height={25}
								baseColor="#dadee7"
								highlightColor="#e9ecf2"
							/>
						) : (
							workOrder?.client.email || "--"
						)}
					</span>
				</Col>
				<Col>
					<span className="info-label text-sm text-medium-gotham block">
						{loading ? (
							<Skeleton
								width={120}
								height={15}
								baseColor="#dadee7"
								highlightColor="#e9ecf2"
							/>
						) : (
							"Agency"
						)}
					</span>
					<span className="info-val">
						{loading ? (
							<Skeleton
								width={"80%"}
								height={25}
								baseColor="#dadee7"
								highlightColor="#e9ecf2"
							/>
						) : (
							workOrder?.client.agency || "--"
						)}
					</span>
				</Col>
			</Row>
			<Row className="mb-30">
				<Col>
					<span className="info-label text-sm text-medium-gotham block">
						{loading ? (
							<Skeleton
								width={120}
								height={15}
								baseColor="#dadee7"
								highlightColor="#e9ecf2"
							/>
						) : (
							"Contact No."
						)}
					</span>
					{loading ? (
						<Skeleton
							width={"80%"}
							height={25}
							baseColor="#dadee7"
							highlightColor="#e9ecf2"
						/>
					) : (
						<>
							{workOrder?.client.home_phone && (
								<span className="text-sm block">
									Home: {workOrder?.client.home_phone}
								</span>
							)}
							{workOrder?.client.work_phone && (
								<span className="text-sm block">
									Work: {workOrder?.client.work_phone}
								</span>
							)}
							{workOrder?.client.local_ext && (
								<span className="text-sm block">
									Ext: {workOrder?.client.local_ext}
								</span>
							)}
							{workOrder?.client.fax && (
								<span className="text-sm block">
									Fax: {workOrder?.client.fax}
								</span>
							)}
							{workOrder?.client.msisdn && (
								<span className="text-sm block">
									Mobile: {workOrder?.client.msisdn}
								</span>
							)}
						</>
					)}
					{!workOrder?.client.home_phone &&
					!workOrder?.client.work_phone &&
					!workOrder?.client.msisdn ? (
						<span className="text-sm block">--</span>
					) : (
						""
					)}
				</Col>
				<Col>
					<span className="info-label text-sm text-medium-gotham block">
						{loading ? (
							<Skeleton
								width={120}
								height={15}
								baseColor="#dadee7"
								highlightColor="#e9ecf2"
							/>
						) : (
							"Location"
						)}
					</span>
					<span className="info-val">
						{loading ? (
							<Skeleton
								width={"80%"}
								height={25}
								baseColor="#dadee7"
								highlightColor="#e9ecf2"
							/>
						) : (
							getAddress(workOrder?.client) || "--"
						)}
					</span>
				</Col>
				<Col />
			</Row>
		</div>
	);
};

export default WorkOrderClient;
