import { useState, useContext, useMemo, useRef } from "react";
import { SessionContext } from "../context/SessionContext";
import { ProfileContext } from "../context/ProfileContext";
import { getAddress, getFullName } from "../components/Utils";
import { v4 as uuidv4 } from "uuid";
import AsyncSelect from "react-select/async";
import Skeleton from "react-loading-skeleton";
import format from "date-fns/format";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import WorkOrderUpdate from "./WorkOrderUpdate";
import WorkOrderComment from "./WorkOrderComment";
import WoAttachment from "./WoAttachment";
import Spinner from "react-bootstrap/Spinner";
import toast from "react-hot-toast";
import ToastDisplay from "../components/ToastDisplay";
import FileUpload from "./FileUpload";

const WorkOrderDetails = ({ loading, workOrder, setWorkOrder }) => {
	const fileRef = useRef(null);
	const [session] = useContext(SessionContext);
	const [profile] = useContext(ProfileContext);

	const [actionLoading, setActionLoading] = useState(false);
	const [commentLoading, setCommentLoading] = useState(false);
	const [receiveModal, setReceiveModal] = useState(false);
	const [reassignModal, setReassignModal] = useState(false);
	const [completeModal, setCompleteModal] = useState(false);
	const [updateModal, setUpdateModal] = useState(false);
	const [comment, setComment] = useState("");

	const [priority, setPriority] = useState("");
	const [due, setDue] = useState("");
	const [location, setLocation] = useState("");

	const [contractor, setContractor] = useState(null);
	const [contractorLoading, setContractorLoading] = useState(false);
	const [contractorInput, setContractorInput] = useState("");
	const [selectedContractor, setSelectedContractor] = useState(null);

	const [assignedTo, setAssignedTo] = useState(null);
	const [assignedToLoading, setAssignedToLoading] = useState(false);
	const [assignedToInput, setAssignedToInput] = useState("");
	const [selectedAssignedTo, setSelectedAssignedTo] = useState(null);

	const [updateTitle, setUpdateTitle] = useState("");
	const [updateContent, setUpdateContent] = useState("");
	const [updateFiles, setUpdateFiles] = useState([]);
	const [updateFilePaths, setUpdateFilePaths] = useState([]);
	const [notify, setNotify] = useState(false);

	const [serviceReport, setServiceReport] = useState("");
	const [invoice, setInvoice] = useState("");
	const [sap, setSap] = useState("");

	const [errors, setErrors] = useState([]);

	const requiredFields = [{ key: "priority", value: priority }];
	const requiredUpdateFields = [
		{ key: "title", value: updateTitle },
		{ key: "content", value: updateContent },
	];
	const requiredCompleteFields = [
		{ key: "serviceReport", value: serviceReport },
		{ key: "invoice", value: invoice },
	];

	const closeReceiveModal = () => {
		setReceiveModal(false);
		setReassignModal(false);
		setPriority("");
		setDue("");
		setLocation("");
		setContractor(null);
		setContractorLoading(false);
		setContractorInput("");
		setSelectedContractor(null);
		setAssignedTo(null);
		setAssignedToLoading(false);
		setAssignedToInput("");
		setSelectedAssignedTo(null);
		setNotify(false);

		setErrors([]);
	};

	const closeUpdateModal = () => {
		setUpdateModal(false);
		setUpdateTitle("");
		setUpdateContent("");
		setUpdateFiles([]);
		setUpdateFilePaths([]);

		setErrors([]);
	};

	const closeCompleteModal = () => {
		setCompleteModal(false);
		setServiceReport("");
		setInvoice("");
		setSap("");

		setErrors([]);
	};

	const loadContractorOptions = async (inputValue) => {
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/contractors/?q=${inputValue}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			return data.data?.map((contractor) => ({
				value: contractor.id,
				label: contractor.company,
			}));
		}
	};

	const loadAssignedToOptions = async (inputValue) => {
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/?q=${inputValue}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			return data.data?.map((user) => ({
				value: user.id,
				label: user.name,
			}));
		}
	};

	const getContractor = async () => {
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/contractors/c/${selectedContractor.value}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			setContractor(data.contractor);
		}
	};

	const receiveWorkOrder = async () => {
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
			body: JSON.stringify({
				priority: priority,
				due: due,
				location: location,
				contractor: contractor?.id || null,
				assigned_to: assignedTo,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/work-orders/w/${workOrder.id}/receive`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			toast(<ToastDisplay status="error" content={data.msg} />);
		} else {
			toast(<ToastDisplay status="success" content={data.msg} />);
			closeReceiveModal();
			setWorkOrder(data.work_order);
		}
		setActionLoading(false);
	};

	const reassignWorkOrder = async () => {
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
			body: JSON.stringify({
				priority: priority,
				due: due,
				location: location,
				contractor: contractor?.id || null,
				assigned_to: assignedTo,
				notify: notify,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/work-orders/w/${workOrder.id}/reassign`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			toast(<ToastDisplay status="error" content={data.msg} />);
		} else {
			toast(<ToastDisplay status="success" content={data.msg} />);
			closeReceiveModal();
			setWorkOrder(data.work_order);
		}
		setActionLoading(false);
	};

	const saveUpdate = async () => {
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
			body: JSON.stringify({
				title: updateTitle,
				content: updateContent,
				attachments: updateFilePaths,
				notify: notify,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/work-orders/w/${workOrder.id}/updates`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			toast(<ToastDisplay status="error" content={data.msg} />);
		} else {
			toast(<ToastDisplay status="success" content={data.msg} />);
			closeUpdateModal();
			setWorkOrder(data.work_order);
		}
		setActionLoading(false);
	};

	const completeWorkOrder = async () => {
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
			body: JSON.stringify({
				service_report: serviceReport,
				invoice: invoice,
				sap: sap,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/work-orders/w/${workOrder.id}/complete`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			toast(<ToastDisplay status="error" content={data.msg} />);
		} else {
			toast(<ToastDisplay status="success" content={data.msg} />);
			closeCompleteModal();
			setWorkOrder(data.work_order);
		}
		setActionLoading(false);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setActionLoading(true);
		var newErrors = [];

		requiredFields.map((field) => {
			if (!field.value || field.value === "" || field.value.length === 0) {
				newErrors.push(field.key);
			}
		});

		if (newErrors.length !== 0) {
			toast(
				<ToastDisplay
					status="error"
					content="Please fill out all required fields."
				/>
			);
			setActionLoading(false);
			setErrors(newErrors);
			return;
		}
		receiveWorkOrder();
	};

	const handleReassignment = (e) => {
		e.preventDefault();
		setActionLoading(true);
		var newErrors = [];

		requiredFields.map((field) => {
			if (!field.value || field.value === "" || field.value.length === 0) {
				newErrors.push(field.key);
			}
		});

		if (newErrors.length !== 0) {
			toast(
				<ToastDisplay
					status="error"
					content="Please fill out all required fields."
				/>
			);
			setActionLoading(false);
			setErrors(newErrors);
			return;
		}
		reassignWorkOrder();
	};

	const handleUpdateSubmit = (e) => {
		e.preventDefault();
		setActionLoading(true);
		var newErrors = [];

		requiredUpdateFields.map((field) => {
			if (!field.value || field.value === "" || field.value.length === 0) {
				newErrors.push(field.key);
			}
		});

		if (newErrors.length !== 0) {
			toast(
				<ToastDisplay
					status="error"
					content="Please fill out all required fields."
				/>
			);
			setActionLoading(false);
			setErrors(newErrors);
			return;
		}
		saveUpdate();
	};

	const handleCompleteSubmit = (e) => {
		e.preventDefault();
		setActionLoading(true);
		var newErrors = [];

		requiredCompleteFields.map((field) => {
			if (!field.value || field.value === "" || field.value.length === 0) {
				newErrors.push(field.key);
			}
		});

		if (newErrors.length !== 0) {
			toast(
				<ToastDisplay
					status="error"
					content="Please fill out all required fields."
				/>
			);
			setActionLoading(false);
			setErrors(newErrors);
			return;
		}
		completeWorkOrder();
	};

	const handleFiles = (e) => {
		const newFiles = Array.from(e.target.files);
		const forUpload = [...updateFiles];
		const paths = [...updateFilePaths];

		newFiles.map((file) => {
			const newUid = uuidv4();
			forUpload.push({
				uuid: `${newUid}.${file.name.split(".").pop()}`,
				file: file,
			});
			paths.push(`${newUid}.${file.name.split(".").pop()}`);
		});

		setUpdateFiles(forUpload);
		setUpdateFilePaths(paths);
	};

	const postComment = async (e) => {
		e.preventDefault();
		setCommentLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
			body: JSON.stringify({
				comment: comment,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/work-orders/w/${workOrder.id}/comments`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			toast(<ToastDisplay status="error" content="Something went wrong." />);
		} else {
			setComment("");
			setWorkOrder(data.work_order);
		}
		setCommentLoading(false);
	};

	useMemo(() => {
		if (selectedContractor) {
			getContractor();
		}
	}, [selectedContractor]);

	useMemo(() => {
		if (selectedAssignedTo) {
			setAssignedTo(selectedAssignedTo.value);
		}
	}, [selectedAssignedTo]);

	return (
		<div>
			<div className="bg-white rounded-8 p-20 mb-30">
				<div className="mb-20">
					<h2 className="text-xl text-medium-gotham mb-0">
						Work Order Information
					</h2>
				</div>
				<Row className="mb-30">
					<Col>
						<span className="info-label text-sm text-medium-gotham block">
							{loading ? (
								<Skeleton
									width={120}
									height={15}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								"Short Description"
							)}
						</span>
						<span className="info-val">
							{loading ? (
								<Skeleton
									width={"80%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								workOrder?.short_description || "--"
							)}
						</span>
					</Col>
					<Col>
						<span className="info-label text-sm text-medium-gotham block">
							{loading ? (
								<Skeleton
									width={120}
									height={15}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								"Client"
							)}
						</span>
						<span className="info-val">
							{loading ? (
								<Skeleton
									width={"80%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								getFullName(workOrder.client) || "--"
							)}
						</span>
					</Col>
					<Col>
						<span className="info-label text-sm text-medium-gotham block">
							{loading ? (
								<Skeleton
									width={120}
									height={15}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								"WO Date"
							)}
						</span>
						<span className="info-val">
							{loading ? (
								<Skeleton
									width={"80%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : workOrder.wo_date ? (
								format(new Date(workOrder.wo_date), "MMM dd, yyyy")
							) : (
								"--"
							)}
						</span>
					</Col>
				</Row>
				<Row className="mb-30">
					<Col>
						<span className="info-label text-sm text-medium-gotham block">
							{loading ? (
								<Skeleton
									width={120}
									height={15}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								"Type"
							)}
						</span>
						<span className="info-val">
							{loading ? (
								<Skeleton
									width={"80%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								workOrder?.type_ || "--"
							)}
						</span>
					</Col>
					<Col>
						<span className="info-label text-sm text-medium-gotham block">
							{loading ? (
								<Skeleton
									width={120}
									height={15}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								"Category"
							)}
						</span>
						<span className="info-val">
							{loading ? (
								<Skeleton
									width={"80%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								workOrder?.category || "--"
							)}
						</span>
					</Col>
					<Col>
						<span className="info-label text-sm text-medium-gotham block">
							{loading ? (
								<Skeleton
									width={120}
									height={15}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								"Location"
							)}
						</span>
						<span className="info-val">
							{loading ? (
								<Skeleton
									width={"80%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : workOrder?.client?.address ? (
								getAddress(workOrder.client)
							) : (
								"--"
							)}
						</span>
					</Col>
				</Row>
				<Row className="mb-50">
					<Col>
						<span className="info-label text-sm text-medium-gotham block">
							{loading ? (
								<Skeleton
									width={120}
									height={15}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								"Description"
							)}
						</span>
						<span className="info-val">
							{loading ? (
								<Skeleton
									width={"80%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								workOrder?.description || "--"
							)}
						</span>
					</Col>
				</Row>
				{workOrder?.attachments?.length !== 0 && (
					<>
						<div className="mb-10">
							<h2 className="text-lg text-medium-gotham mb-0">Attachments</h2>
						</div>
						<Row className="wo-attachments-row">
							{loading ? (
								<Skeleton
									width={100}
									height={100}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								<div className="order-files-container">
									{workOrder?.attachments?.map((attachment) => (
										<WoAttachment key={attachment.id} attachment={attachment} />
									))}
								</div>
							)}
						</Row>
					</>
				)}
				<div className="mb-10">
					<h2 className="text-lg text-medium-gotham mb-0">Updates</h2>
				</div>
				<Row className="mb-30">
					{loading ? (
						<Skeleton
							width="100%"
							height={80}
							baseColor="#dadee7"
							highlightColor="#e9ecf2"
						/>
					) : (
						<div className="order-items-container">
							{!workOrder?.updates || workOrder?.updates?.length === 0 ? (
								<div className="rounded-8 border bg-white flex-column p-50">
									<h2 className="text-md mb-10">No updates yet</h2>
									<span className="text-sm opacity-5">
										Please acknowledge the work order before adding further
										updates.
									</span>
								</div>
							) : (
								""
							)}
							{workOrder?.updates?.map((workOrderUpdate) => (
								<WorkOrderUpdate
									key={workOrderUpdate.id}
									update={workOrderUpdate}
								/>
							))}
							{workOrder?.updates?.length !== 0 &&
							profile?.role?.work_orders_edit &&
							workOrder?.status === "Ongoing" ? (
								<div className="flex-row-right">
									<Button
										className="btn-default-light btn-sm"
										onClick={() => setUpdateModal(true)}
									>
										Add Update
									</Button>
								</div>
							) : (
								""
							)}
						</div>
					)}
				</Row>
				{!loading && (
					<>
						<div className="mb-10">
							<h2 className="text-lg text-medium-gotham mb-0">Comments</h2>
						</div>
						{!workOrder?.comments || workOrder?.comments?.length === 0 ? (
							<div className="empty-comments mb-30">
								<span className="text-sm opacity-5">No comments yet.</span>
							</div>
						) : (
							workOrder?.comments?.map((comment) => (
								<WorkOrderComment key={comment.id} comment={comment} />
							))
						)}
						<Form onSubmit={postComment}>
							<div className="comment-input-container border rounded-8 mb-50">
								<Form.Control
									as="textarea"
									className="comment-input text-sm mb-10"
									placeholder="Write your comment here."
									value={comment}
									onChange={(e) => setComment(e.target.value)}
								/>
								<div className="comment-control border-top">
									<Row className="flex-row-left">
										<Col className="flex-row-right">
											<Button
												type="submit"
												className="btn-default-light btn-sm comment-btn flex-column"
												disabled={!comment || commentLoading}
											>
												{commentLoading ? (
													<Spinner className="btn-loader text-default" />
												) : (
													"Post Comment"
												)}
											</Button>
										</Col>
									</Row>
								</div>
							</div>
						</Form>
					</>
				)}
				{profile?.role?.work_orders_edit && (
					<div className="wo-action-container">
						<Row>
							<Col className="flex-row-right">
								{workOrder?.status === "New" && (
									<Button
										className="wo-action-btn text-sm btn-md rounded-4 text-medium-gotham btn-default"
										onClick={() => setReceiveModal(true)}
									>
										Acknowledge
									</Button>
								)}
								{workOrder?.status === "Ongoing" && (
									<>
										<Button
											variant="outline-secondary"
											className="wo-action-btn text-sm btn-md rounded-4 text-medium-gotham me-3"
											onClick={() => setReceiveModal(true)}
										>
											Cancel
										</Button>
										<Button
											className="wo-action-btn text-sm btn-md rounded-4 text-medium-gotham btn-default-outlined me-3"
											onClick={() => setReassignModal(true)}
										>
											Reassign
										</Button>
										<Button
											className="wo-action-btn text-sm btn-md rounded-4 text-medium-gotham btn-default"
											onClick={() => setCompleteModal(true)}
										>
											Complete
										</Button>
									</>
								)}
							</Col>
						</Row>
					</div>
				)}
			</div>
			<Modal
				show={receiveModal}
				centered
				onHide={closeReceiveModal}
				size="lg"
				className="info-modal"
			>
				<Form onSubmit={handleSubmit}>
					<Modal.Header>
						<Modal.Title className="text-lg">Receive Work Order</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Priority*</Form.Label>
								<Form.Select
									className={`text-sm form-input ${
										errors.includes("priority") && "border-danger"
									}`}
									value={priority}
									onChange={(e) => setPriority(e.target.value)}
								>
									<option value=""></option>
									<option value="High">High</option>
									<option value="Medium">Medium</option>
									<option value="Low">Low</option>
								</Form.Select>
							</Col>
							<Col>
								<Form.Label className="text-sm">Assigned To</Form.Label>
								<AsyncSelect
									isClearable
									isDisabled={assignedToLoading}
									isLoading={assignedToLoading}
									inputValue={assignedToInput}
									onInputChange={setAssignedToInput}
									noOptionsMessage={() =>
										assignedToInput ? "No results" : "Search property users"
									}
									loadOptions={loadAssignedToOptions}
									defaultOptions={[]}
									onChange={setSelectedAssignedTo}
									value={selectedAssignedTo}
									menuShouldScrollIntoView={false}
									maxMenuHeight={200}
									placeholder=""
									styles={{
										control: (baseStyles, state) => ({
											...baseStyles,
											borderColor: state.isFocused
												? "#c7883a !important"
												: "#dee2e6 !important",
											boxShadow: "none",
											outline: "none",
											borderRadius: 4,
											fontSize: "0.875rem",
										}),
										option: (baseStyles, state) => ({
											...baseStyles,
											fontSize: ".875rem",
											color: "#050911",
											backgroundColor: state.isFocused
												? "#f6d4aa !important"
												: "#fff",
										}),
									}}
								/>
							</Col>
						</Row>
						<Row className="mb-50">
							<Col>
								<Form.Label className="text-sm">Due Date</Form.Label>
								<Form.Control
									type="date"
									className={`text-sm form-input ${
										errors.includes("due") && "border-danger"
									}`}
									value={due}
									onChange={(e) => setDue(e.target.value)}
								/>
							</Col>
						</Row>
						<div className="mb-10">
							<h2 className="text-lg text-medium-gotham mb-0">
								Contractor Information
							</h2>
						</div>
						<div className="bg-gray rounded-8 p-20 mb-30">
							<Row className="mb-20">
								<Col>
									<Form.Label className="text-sm">Contractor</Form.Label>
									<AsyncSelect
										isClearable
										isDisabled={contractorLoading}
										isLoading={contractorLoading}
										inputValue={contractorInput}
										onInputChange={setContractorInput}
										noOptionsMessage={() =>
											contractorInput ? "No results" : "Search contractors"
										}
										loadOptions={loadContractorOptions}
										defaultOptions={[]}
										onChange={setSelectedContractor}
										value={selectedContractor}
										menuShouldScrollIntoView={false}
										maxMenuHeight={200}
										placeholder=""
										styles={{
											control: (baseStyles, state) => ({
												...baseStyles,
												borderColor: state.isFocused
													? "#c7883a !important"
													: "#dee2e6 !important",
												boxShadow: "none",
												outline: "none",
												borderRadius: 4,
												fontSize: "0.875rem",
											}),
											option: (baseStyles, state) => ({
												...baseStyles,
												fontSize: ".875rem",
												color: "#050911",
												backgroundColor: state.isFocused
													? "#f6d4aa !important"
													: "#fff",
											}),
										}}
									/>
								</Col>
							</Row>
							<Row className="mb-20">
								<Col>
									<Form.Label className="text-sm">Short Name</Form.Label>
									<span className="text-sm block">
										{contractor?.short_name || "--"}
									</span>
								</Col>
								<Col>
									<Form.Label className="text-sm">Contact Person</Form.Label>
									<span className="text-sm block">
										{contractor?.contact_person || "--"}
									</span>
								</Col>
							</Row>
							<Row className="mb-20">
								<Col>
									<Form.Label className="text-sm">Email</Form.Label>
									<span className="text-sm block">
										{contractor?.emails.length !== 0 &&
											contractor?.emails.map((email) => (
												<span key={email.id} className="block">
													{email.email}
												</span>
											))}
										{!contractor || !contractor?.emails ? "--" : ""}
									</span>
								</Col>
								<Col>
									<Form.Label className="text-sm">Phone</Form.Label>
									<span className="text-sm block">
										{contractor?.phone || "--"}
									</span>
								</Col>
							</Row>
						</div>
						<p className="label-md opacity-5 italic">
							Note: Acknowledging this work order will automatically create an
							update entry.
						</p>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="outline-secondary"
							className="btn-sm action-btn text-sm me-2"
							onClick={closeReceiveModal}
						>
							Close
						</Button>
						<Button
							type="submit"
							variant="primary"
							className="btn-sm action-btn text-sm btn-default flex-column"
							disabled={actionLoading}
						>
							{actionLoading ? (
								<Spinner className="btn-loader text-white" />
							) : (
								"Receive"
							)}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal show={completeModal} centered onHide={closeCompleteModal}>
				<Form onSubmit={handleCompleteSubmit}>
					<Modal.Header>
						<Modal.Title className="text-lg">Complete Work Order</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Service Report #</Form.Label>
								<Form.Control
									autoFocus
									className={`text-sm form-input ${
										errors.includes("serviceReport") && "border-danger"
									}`}
									value={serviceReport}
									onChange={(e) => setServiceReport(e.target.value)}
								/>
							</Col>
						</Row>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Invoice #</Form.Label>
								<Form.Control
									className={`text-sm form-input ${
										errors.includes("invoice") && "border-danger"
									}`}
									value={invoice}
									onChange={(e) => setInvoice(e.target.value)}
								/>
							</Col>
						</Row>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">SAP Document #</Form.Label>
								<Form.Control
									className={`text-sm form-input ${
										errors.includes("sap") && "border-danger"
									}`}
									value={sap}
									onChange={(e) => setSap(e.target.value)}
								/>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="outline-secondary"
							className="btn-sm action-btn text-sm me-2"
							onClick={closeCompleteModal}
						>
							Close
						</Button>
						<Button
							type="submit"
							variant="primary"
							className="btn-sm action-btn text-sm btn-default flex-column"
						>
							{actionLoading ? (
								<Spinner className="btn-loader text-white" />
							) : (
								"Complete"
							)}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal show={updateModal} centered onHide={closeUpdateModal} size="lg">
				<Form onSubmit={handleUpdateSubmit}>
					<Modal.Header>
						<Modal.Title className="text-lg">New Update</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Title*</Form.Label>
								<Form.Control
									autoFocus
									className={`text-sm form-input ${
										errors.includes("updateTitle") && "border-danger"
									}`}
									value={updateTitle}
									onChange={(e) => setUpdateTitle(e.target.value)}
								/>
							</Col>
						</Row>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Content*</Form.Label>
								<Form.Control
									as="textarea"
									className={`text-sm form-input ${
										errors.includes("updateContent") && "border-danger"
									}`}
									value={updateContent}
									onChange={(e) => setUpdateContent(e.target.value)}
								/>
							</Col>
						</Row>
						<Row className="mb-20">
							<Form.Label className="text-sm">Attachments</Form.Label>
							<div className="order-files-container">
								{updateFiles?.map((file, index) => (
									<FileUpload
										key={index}
										index={index}
										file={file}
										files={updateFiles}
										setFiles={setUpdateFiles}
										filePaths={updateFilePaths}
										setFilePaths={setUpdateFilePaths}
									/>
								))}
								<Button
									variant="light"
									className="file-btn flex-column border"
									onClick={() => fileRef.current.click()}
								>
									<i className="ri-upload-line opacity-7" />
								</Button>
								<input
									ref={fileRef}
									type="file"
									multiple
									className="hidden"
									accept="image/*"
									onChange={handleFiles}
								/>
							</div>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Col className="flex-row-left">
							<input
								type="checkbox"
								defaultChecked={notify}
								onChange={(e) => setNotify(e.target.checked)}
								className="cbx hidden"
								id="notify"
							/>
							<label htmlFor="notify" className="lbl"></label>
							<span className="text-sm">Notify Client</span>
						</Col>
						<Col className="flex-row-right">
							<Button
								variant="outline-secondary"
								className="btn-sm action-btn text-sm me-2"
								onClick={closeUpdateModal}
							>
								Close
							</Button>
							<Button
								type="submit"
								variant="primary"
								className="btn-sm action-btn text-sm btn-default flex-column"
								disabled={actionLoading}
							>
								{actionLoading ? (
									<Spinner className="btn-loader text-white" />
								) : (
									"Save"
								)}
							</Button>
						</Col>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal
				show={reassignModal}
				centered
				onHide={closeReceiveModal}
				size="lg"
				className="info-modal"
			>
				<Form onSubmit={handleReassignment}>
					<Modal.Header>
						<Modal.Title className="text-lg">Reassign Work Order</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Priority*</Form.Label>
								<Form.Select
									className={`text-sm form-input ${
										errors.includes("priority") && "border-danger"
									}`}
									value={priority}
									onChange={(e) => setPriority(e.target.value)}
								>
									<option value=""></option>
									<option value="High">High</option>
									<option value="Medium">Medium</option>
									<option value="Low">Low</option>
								</Form.Select>
							</Col>
							<Col>
								<Form.Label className="text-sm">Assigned To</Form.Label>
								<AsyncSelect
									isClearable
									isDisabled={assignedToLoading}
									isLoading={assignedToLoading}
									inputValue={assignedToInput}
									onInputChange={setAssignedToInput}
									noOptionsMessage={() =>
										assignedToInput ? "No results" : "Search property users"
									}
									loadOptions={loadAssignedToOptions}
									defaultOptions={[]}
									onChange={setSelectedAssignedTo}
									value={selectedAssignedTo}
									menuShouldScrollIntoView={false}
									maxMenuHeight={200}
									placeholder=""
									styles={{
										control: (baseStyles, state) => ({
											...baseStyles,
											borderColor: state.isFocused
												? "#c7883a !important"
												: "#dee2e6 !important",
											boxShadow: "none",
											outline: "none",
											borderRadius: 4,
											fontSize: "0.875rem",
										}),
										option: (baseStyles, state) => ({
											...baseStyles,
											fontSize: ".875rem",
											color: "#050911",
											backgroundColor: state.isFocused
												? "#f6d4aa !important"
												: "#fff",
										}),
									}}
								/>
							</Col>
						</Row>
						<Row className="mb-50">
							<Col>
								<Form.Label className="text-sm">Due Date</Form.Label>
								<Form.Control
									type="date"
									className={`text-sm form-input ${
										errors.includes("due") && "border-danger"
									}`}
									value={due}
									onChange={(e) => setDue(e.target.value)}
								/>
							</Col>
						</Row>
						<div className="mb-10">
							<h2 className="text-lg text-medium-gotham mb-0">
								Contractor Information
							</h2>
						</div>
						<div className="bg-gray rounded-8 p-20 mb-30">
							<Row className="mb-20">
								<Col>
									<Form.Label className="text-sm">Contractor</Form.Label>
									<AsyncSelect
										isClearable
										isDisabled={contractorLoading}
										isLoading={contractorLoading}
										inputValue={contractorInput}
										onInputChange={setContractorInput}
										noOptionsMessage={() =>
											contractorInput ? "No results" : "Search contractors"
										}
										loadOptions={loadContractorOptions}
										defaultOptions={[]}
										onChange={setSelectedContractor}
										value={selectedContractor}
										menuShouldScrollIntoView={false}
										maxMenuHeight={200}
										placeholder=""
										styles={{
											control: (baseStyles, state) => ({
												...baseStyles,
												borderColor: state.isFocused
													? "#c7883a !important"
													: "#dee2e6 !important",
												boxShadow: "none",
												outline: "none",
												borderRadius: 4,
												fontSize: "0.875rem",
											}),
											option: (baseStyles, state) => ({
												...baseStyles,
												fontSize: ".875rem",
												color: "#050911",
												backgroundColor: state.isFocused
													? "#f6d4aa !important"
													: "#fff",
											}),
										}}
									/>
								</Col>
							</Row>
							<Row className="mb-20">
								<Col>
									<Form.Label className="text-sm">Short Name</Form.Label>
									<span className="text-sm block">
										{contractor?.short_name || "--"}
									</span>
								</Col>
								<Col>
									<Form.Label className="text-sm">Contact Person</Form.Label>
									<span className="text-sm block">
										{contractor?.contact_person || "--"}
									</span>
								</Col>
							</Row>
							<Row className="mb-20">
								<Col>
									<Form.Label className="text-sm">Email</Form.Label>
									<span className="text-sm block">
										{contractor?.emails.length !== 0 &&
											contractor?.emails.map((email) => (
												<span key={email.id} className="block">
													{email.email}
												</span>
											))}
										{!contractor || !contractor?.emails ? "--" : ""}
									</span>
								</Col>
								<Col>
									<Form.Label className="text-sm">Phone</Form.Label>
									<span className="text-sm block">
										{contractor?.phone || "--"}
									</span>
								</Col>
							</Row>
						</div>
						<p className="label-md opacity-5 italic">
							Note: Reassigning this work order will automatically create an
							update entry.
						</p>
					</Modal.Body>
					<Modal.Footer>
						<Col className="flex-row-left">
							<input
								type="checkbox"
								defaultChecked={notify}
								onChange={(e) => setNotify(e.target.checked)}
								className="cbx hidden"
								id="notify"
							/>
							<label htmlFor="notify" className="lbl"></label>
							<span className="text-sm">Notify Client</span>
						</Col>
						<Col className="flex-row-right">
							<Button
								variant="outline-secondary"
								className="btn-sm action-btn text-sm me-2"
								onClick={closeReceiveModal}
							>
								Close
							</Button>
							<Button
								type="submit"
								variant="primary"
								className="btn-sm action-btn text-sm btn-default flex-column"
								disabled={actionLoading}
							>
								{actionLoading ? (
									<Spinner className="btn-loader text-white" />
								) : (
									"Reassign"
								)}
							</Button>
						</Col>
					</Modal.Footer>
				</Form>
			</Modal>
		</div>
	);
};

export default WorkOrderDetails;
