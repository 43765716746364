import { useContext, useEffect, useState } from "react";
import { SessionContext } from "../context/SessionContext";
import { ReportContext } from "../context/ReportContext";
import toast from "react-hot-toast";
import ToastDisplay from "../components/ToastDisplay";

const ReportItem = ({ report }) => {
	const [session] = useContext(SessionContext);
	const [, setReport] = useContext(ReportContext);
	const [status, setStatus] = useState("Pending");

	const getStatus = async () => {
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/work-orders/reports/r/${report.id}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			setStatus(data.report.report_status);
			if (data.report.report_status === "Pending") {
				setTimeout(function () {
					getStatus();
				}, 1000);
			} else {
				if (data.report.report_status === "Failed") {
					toast(
						<ToastDisplay status="error" content="Something went wrong." />
					);
				} else {
					downloadReport();
				}
			}
		}
	};

	const downloadReport = async () => {
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/work-orders/reports/download/${report.id}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			toast(<ToastDisplay status="error" content="Error downloading file." />);
		} else {
			const link = document.createElement("a");
			link.href = data.download_url;
			link.download = report.filename;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			setReport(null);
		}
	};

	useEffect(() => {
		getStatus();
	}, []);

	return (
		status === "Pending" && (
			<div key={report.id} className="report-loader-item flex-row-left">
				<span className="report-loader-name ellipsis text-medium-inter text-md">
					Generating report...
				</span>

				<span className="report-loader-wheel" />
			</div>
		)
	);
};

export default ReportItem;
