import { useState, useEffect, useContext, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { SessionContext } from "../context/SessionContext";
import { ProfileContext } from "../context/ProfileContext";
import { getFullName } from "../components/Utils";
import format from "date-fns/format";
import { v4 as uuidv4 } from "uuid";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Skeleton from "react-loading-skeleton";
import Dropdown from "react-bootstrap/Dropdown";
import Nav from "react-bootstrap/Nav";
import toast from "react-hot-toast";
import ToastDisplay from "../components/ToastDisplay";
import WorkOrderDetails from "../components/WorkOrderDetails";
import WorkOrderClient from "../components/WorkOrderClient";
import WorkOrderProperty from "../components/WorkOrderProperty";
import InfoLogs from "../components/InfoLogs";
import FileUpload from "../components/FileUpload";
import WoAttachment from "../components/WoAttachment";
import WorkOrderContractor from "../components/WorkOrderContractor";

const WorkOrder = () => {
	let { id } = useParams();
	const fileRef = useRef(null);
	const navigate = useNavigate();
	const [session] = useContext(SessionContext);
	const [profile] = useContext(ProfileContext);
	const [loading, setLoading] = useState(true);
	const [generating, setGenerating] = useState(false);
	const [workOrder, setWorkOrder] = useState(null);
	const [editModal, setEditModal] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [actionLoading, setActionLoading] = useState(false);
	const [tab, setTab] = useState("details");
	const [infoTab, setInfoTab] = useState("details");

	const [shortDesc, setShortDesc] = useState("");
	const [desc, setDesc] = useState("");
	const [type, setType] = useState("Corrective");
	const [category, setCategory] = useState("");
	const [receivedBy, setReceivedBy] = useState("");
	const [assignedTo, setAssignedTo] = useState("");
	const [location, setLocation] = useState("");
	const [serviceReportNo, setServiceReportNo] = useState("");
	const [invoiceNo, setInvoiceNo] = useState("");
	const [sapDocNo, setSapDocNo] = useState("");
	const [woDate, setWoDate] = useState("");
	const [attachments, setAttachments] = useState([]);
	const [selectedItem, setSelectedItem] = useState(null);
	const [files, setFiles] = useState([]);
	const [filePaths, setFilePaths] = useState([]);
	const [client, setClient] = useState(null);
	const [clientLoading, setClientLoading] = useState(false);
	const [clientInput, setClientInput] = useState("");
	const [selectedClient, setSelectedClient] = useState(null);
	const [contractor, setContractor] = useState(null);
	const [contractorLoading, setContractorLoading] = useState(false);
	const [contractorInput, setContractorInput] = useState("");
	const [selectedContractor, setSelectedContractor] = useState(null);

	const [removeAttachments, setRemoveAttachments] = useState([]);

	const [errors, setErrors] = useState([]);

	const requiredFields = [
		{ key: "shortDesc", value: shortDesc },
		{ key: "desc", value: desc },
		{ key: "woDate", value: woDate },
		{ key: "type", value: type },
	];

	const getData = async () => {
		setLoading(true);
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/work-orders/w/${id}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			setWorkOrder(data.work_order);

			setAttachments(
				data.work_order.attachments.map((attachment) => ({
					...attachment,
				}))
			);

			setShortDesc(data.work_order.short_description);
			setDesc(data.work_order.description);
			// setType(data.work_order.type_);
			setCategory(data.work_order.category);
			setReceivedBy(data.work_order.received_by);
			setAssignedTo(data.work_order.assigned_to);
			setLocation(data.work_order.location);
			setServiceReportNo(data.work_order.service_report_no);
			setInvoiceNo(data.work_order.invoice_no);
			setSapDocNo(data.work_order.sap_doc_no);
			setWoDate(format(new Date(data.work_order.wo_date), "yyyy-MM-dd"));
			setClient(data.work_order.client);
			setContractor(data.work_order.contractor);
		}
		setLoading(false);
	};

	const editWorkOrder = async () => {
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
			body: JSON.stringify({
				short_description: shortDesc,
				description: desc,
				type: type,
				category: category,
				received_by: receivedBy,
				assigned_to: assignedTo,
				location: location,
				service_report_no: serviceReportNo,
				invoice_no: invoiceNo,
				sap_doc_no: sapDocNo,
				wo_date: woDate,
				client_id: client.id,
				contractor_id: contractor.id,
				attachments: filePaths,
				remove_attachments: removeAttachments,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/work-orders/w/${id}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			toast(<ToastDisplay status="error" content={data.msg} />);
		} else {
			toast(<ToastDisplay status="success" content={data.msg} />);
			getData();
			closeEditModal();
		}
		setActionLoading(false);
	};

	const deleteWorkOrder = async () => {
		setDeleteLoading(true);
		const requestOptions = {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/work-orders/w/${id}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			toast(<ToastDisplay status="success" content={data.msg} />);
			navigate("/property/work-orders");
			setDeleteModal(false);
		}
		setDeleteLoading(false);
	};

	const handleFiles = (e) => {
		const newFiles = Array.from(e.target.files);
		const forUpload = [...files];
		const paths = [...filePaths];

		newFiles.map((file) => {
			const newUid = uuidv4();
			forUpload.push({
				uuid: `${newUid}.${file.name.split(".").pop()}`,
				file: file,
			});
			paths.push(`${newUid}.${file.name.split(".").pop()}`);
		});

		setFiles(forUpload);
		setFilePaths(paths);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setActionLoading(true);
		var newErrors = [];

		requiredFields.map((field) => {
			if (!field.value || field.value === "" || field.value.length === 0) {
				newErrors.push(field.key);
			}
		});

		if (newErrors.length !== 0) {
			toast(
				<ToastDisplay
					status="error"
					content="Please fill out all required fields."
				/>
			);
			setActionLoading(false);
			setErrors(newErrors);
			return;
		}
		editWorkOrder();
	};

	const closeEditModal = () => {
		setEditModal(false);

		setAttachments(
			workOrder.attachments.map((attachment) => ({
				...attachment,
			}))
		);

		setShortDesc(workOrder.short_description);
		setDesc(workOrder.description);
		// setType(workOrder.type);
		setCategory(workOrder.category);
		setReceivedBy(workOrder.received_by);
		setAssignedTo(workOrder.assigned_to);
		setLocation(workOrder.location);
		setServiceReportNo(workOrder.service_report_no);
		setInvoiceNo(workOrder.invoice_no);
		setSapDocNo(workOrder.sap_doc_no);
		setWoDate(format(new Date(workOrder.wo_date), "yyyy-MM-dd"));
		setClient(workOrder.client);
		setContractor(workOrder.contractor);

		setRemoveAttachments([]);
		setErrors([]);
	};

	useEffect(() => {
		if (profile) {
			if (!profile.role.work_orders_view) {
				navigate("/");
			} else {
				if (id) {
					getData();
				}
			}
		}
	}, [id, profile]);

	return (
		<>
			<Container className="gx-5">
				<div className="info-page-header mb-10">
					<a
						href="#"
						className="back-btn flex-row-left fit mb-20"
						onClick={(e) => {
							e.preventDefault();
							navigate(-1);
						}}
					>
						<i className="ri-arrow-left-line opacity-7" />
						<span className="text-sm text-regular-inter upper opacity-7">
							Work Orders
						</span>
					</a>
					<Row className="flex-row-left">
						<Col className="flex-row-left">
							<h1 className="text-lg mb-0 text-medium-gotham me-3">
								{loading ? (
									<Skeleton
										width={250}
										height={25}
										baseColor="#dadee7"
										highlightColor="#e9ecf2"
									/>
								) : (
									`Work Order #${workOrder.wo_number}` || "--"
								)}
							</h1>
							{!loading && (
								<div
									className={`wo-status fit flex-column ${workOrder.status}`}
								>
									<span className="label-md">{workOrder.status || "--"}</span>
								</div>
							)}
						</Col>
						<Col className="flex-row-right">
							{profile?.role?.work_orders_edit ||
							profile?.role?.work_orders_delete ? (
								<Dropdown>
									<Dropdown.Toggle
										variant="light"
										className="info-control-btn text-lg rounded-4 flex-column no-arrow"
										disabled={loading}
									>
										<i className="ri-more-2-fill me-0" />
									</Dropdown.Toggle>
									<Dropdown.Menu className="text-sm dropdown-default">
										{profile?.role?.work_orders_edit && (
											<Dropdown.Item
												href="#"
												onClick={(e) => {
													e.preventDefault();
													setEditModal(true);
												}}
											>
												Edit
											</Dropdown.Item>
										)}
										{profile?.role?.work_orders_delete && (
											<Dropdown.Item
												href="#"
												className="text-danger"
												onClick={() => setDeleteModal(true)}
											>
												Delete
											</Dropdown.Item>
										)}
									</Dropdown.Menu>
								</Dropdown>
							) : (
								""
							)}
						</Col>
					</Row>
				</div>
				<Nav variant="tabs" defaultActiveKey={tab} className="mb-20">
					<Nav.Item>
						<Nav.Link
							eventKey="details"
							className="info-tab-item"
							onClick={() => setTab("details")}
						>
							Details
						</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link
							eventKey="client"
							className="info-tab-item"
							onClick={() => setTab("client")}
						>
							Client
						</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link
							eventKey="property"
							className="info-tab-item"
							onClick={() => setTab("property")}
						>
							Property
						</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link
							eventKey="contractor"
							className="info-tab-item"
							onClick={() => setTab("contractor")}
						>
							Contractor
						</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link
							eventKey="logs"
							className="info-tab-item"
							onClick={() => setTab("logs")}
						>
							Logs
						</Nav.Link>
					</Nav.Item>
				</Nav>
				{tab === "details" && (
					<WorkOrderDetails
						loading={loading}
						workOrder={workOrder}
						setWorkOrder={setWorkOrder}
					/>
				)}
				{tab === "client" && (
					<WorkOrderClient loading={loading} workOrder={workOrder} />
				)}
				{tab === "property" && (
					<WorkOrderProperty loading={loading} workOrder={workOrder} />
				)}
				{tab === "contractor" && (
					<WorkOrderContractor loading={loading} workOrder={workOrder} />
				)}
				{tab === "logs" && <InfoLogs loading={loading} data={workOrder} />}
			</Container>
			<Modal
				show={editModal}
				className="info-modal"
				size="lg"
				centered
				onHide={closeEditModal}
			>
				<Form onSubmit={handleSubmit}>
					<Modal.Header>
						<Modal.Title className="text-lg">Edit Work Order</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="mb-10">
							<h2 className="text-lg text-medium-gotham mb-0">
								Work Order Information
							</h2>
						</div>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Short Description</Form.Label>
								<Form.Control
									autoFocus
									className={`text-sm form-input ${
										errors.includes("shortDesc") && "border-danger"
									}`}
									value={shortDesc}
									onChange={(e) => setShortDesc(e.target.value)}
								/>
							</Col>
						</Row>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Date</Form.Label>
								<Form.Control
									type="date"
									className={`text-sm form-input ${
										errors.includes("woDate") && "border-danger"
									}`}
									value={woDate}
									onChange={(e) => setWoDate(e.target.value)}
								/>
							</Col>
							{/* <Col>
								<Form.Label className="text-sm">Type</Form.Label>
								<Form.Select
									className={`text-sm form-input ${
										errors.includes("type") && "border-danger"
									}`}
									value={type}
									onChange={(e) => setType(e.target.value)}
								>
									<option value=""></option>
									<option value="Preventive Maintenance">
										Preventive Maintenance
									</option>
									<option value="Corrective Maintenance">
										Corrective Maintenance
									</option>
								</Form.Select>
							</Col> */}
						</Row>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Category</Form.Label>
								<Form.Select
									className={`text-sm form-input ${
										errors.includes("category") && "border-danger"
									}`}
									value={category}
									onChange={(e) => setCategory(e.target.value)}
								>
									<option value=""></option>
									<option value="Electrical">Electrical</option>
									<option value="Plumbing">Plumbing</option>
								</Form.Select>
							</Col>
						</Row>
						<Row className="mb-50">
							<Col>
								<Form.Label className="text-sm">Description</Form.Label>
								<Form.Control
									as="textarea"
									className={`text-sm form-input ${
										errors.includes("desc") && "border-danger"
									}`}
									value={desc}
									onChange={(e) => setDesc(e.target.value)}
								/>
							</Col>
						</Row>
						{/* <div className="mb-10">
							<h2 className="text-lg text-medium-gotham mb-0">
								Client Information
							</h2>
						</div> */}
						{/* <div className="bg-gray rounded-8 p-20 mb-50">
							<Row className="mb-30">
								<Col>
									<Form.Label className="text-sm">Client</Form.Label>
									<AsyncSelect
										isClearable
										isDisabled={clientLoading}
										isLoading={clientLoading}
										inputValue={clientInput}
										onInputChange={setClientInput}
										noOptionsMessage={() =>
											clientInput ? "No results" : "Search clients"
										}
										loadOptions={loadClientOptions}
										defaultOptions={[]}
										onChange={setSelectedClient}
										value={selectedClient}
										menuShouldScrollIntoView={false}
										maxMenuHeight={200}
										// onCreateOption={createClient}
										placeholder=""
										styles={{
											control: (baseStyles, state) => ({
												...baseStyles,
												borderColor: state.isFocused
													? "#c7883a !important"
													: "#dee2e6 !important",
												boxShadow: "none",
												outline: "none",
												borderRadius: 4,
												fontSize: "0.875rem",
											}),
											option: (baseStyles, state) => ({
												...baseStyles,
												fontSize: ".875rem",
												color: "#050911",
												backgroundColor: state.isFocused
													? "#f6d4aa !important"
													: "#fff",
											}),
										}}
									/>
								</Col>
							</Row>
							<Row className="mb-30">
								<Col>
									<Form.Label className="text-sm">Agency</Form.Label>
									<span className="text-sm block">
										{client?.agency || "--"}
									</span>
								</Col>
								<Col>
									<Form.Label className="text-sm">Position</Form.Label>
									<span className="text-sm block">
										{client?.position || "--"}
									</span>
								</Col>
							</Row>
							<Row className="mb-30">
								<Col>
									<Form.Label className="text-sm">Address</Form.Label>
									<span className="text-sm block">
										{client?.address || "--"}
									</span>
								</Col>
								<Col>
									<Form.Label className="text-sm">Email</Form.Label>
									<span className="text-sm block">{client?.email || "--"}</span>
								</Col>
							</Row>
							<Row>
								<Col>
									<Form.Label className="text-sm">Contact No.</Form.Label>
									{client?.home_phone && (
										<span className="text-sm block">
											Home: {client?.home_phone}
										</span>
									)}
									{client?.work_phone && (
										<span className="text-sm block">
											Work: {client?.work_phone}
										</span>
									)}
									{client?.local_ext && (
										<span className="text-sm block">
											Ext: {client?.local_ext}
										</span>
									)}
									{client?.fax && (
										<span className="text-sm block">Fax: {client?.fax}</span>
									)}
									{client?.msisdn && (
										<span className="text-sm block">
											Mobile: {client?.msisdn}
										</span>
									)}

									{!client?.home_phone &&
									!client?.work_phone &&
									!client?.msisdn ? (
										<span className="text-sm block">--</span>
									) : (
										""
									)}
								</Col>
							</Row>
						</div> */}
						<div className="mb-10">
							<h2 className="text-lg text-medium-gotham mb-0">Attachments</h2>
						</div>
						<Row className="mb-30">
							<div className="order-files-container">
								{attachments?.map((attachment) => (
									<WoAttachment
										key={attachment.id}
										attachment={attachment}
										setAttachments={setAttachments}
										setRemoveAttachments={setRemoveAttachments}
									/>
								))}
								{files?.map((file, index) => (
									<FileUpload
										key={index}
										index={index}
										file={file}
										files={files}
										setFiles={setFiles}
										filePaths={filePaths}
										setFilePaths={setFilePaths}
									/>
								))}
								<Button
									variant="light"
									className="file-btn flex-column border"
									onClick={() => fileRef.current.click()}
								>
									<i className="ri-upload-line opacity-7" />
								</Button>
								<input
									ref={fileRef}
									type="file"
									multiple
									className="hidden"
									accept="image/*"
									onChange={handleFiles}
								/>
							</div>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="outline-secondary"
							className="btn-sm action-btn text-sm me-2"
							onClick={closeEditModal}
						>
							Close
						</Button>
						<Button
							type="submit"
							variant="primary"
							className="btn-sm action-btn text-sm btn-default"
							disabled={actionLoading}
						>
							{actionLoading ? (
								<Spinner className="btn-loader text-white" />
							) : (
								"Save Changes"
							)}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal show={deleteModal} onHide={() => setDeleteModal(false)}>
				<Modal.Body>
					<h2 className="text-lg text-medium-inter mb-15">Delete Work Order</h2>
					<p className="text-sm opacity-7">
						Are you sure you want to delete this work order? This action cannot
						be undone.
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="light"
						className="modal-btn btn-sm dismiss-btn text-medium-inter text-sm"
						onClick={() => setDeleteModal(false)}
					>
						Cancel
					</Button>
					<Button
						variant="danger"
						className="action-btn btn-sm text-medium-inter text-sm flex-column"
						disabled={deleteLoading}
						onClick={deleteWorkOrder}
					>
						{deleteLoading ? (
							<Spinner className="btn-loader text-white" />
						) : (
							"Delete"
						)}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default WorkOrder;
