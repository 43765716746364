export const getFullName = (name) => {
	const nameParts = [];

	if (name.prefix) nameParts.push(name.prefix);
	if (name.first_name) nameParts.push(name.first_name);
	if (name.middle_name) nameParts.push(name.middle_name[0] + ".");
	if (name.last_name) nameParts.push(name.last_name);
	if (name.suffix) nameParts.push(name.suffix);

	const fullName = nameParts.join(" ");
	return fullName;
};

export const getFullPartnerName = (name) => {
	const nameParts = [];

	if (name.partner_prefix) nameParts.push(name.partner_prefix);
	if (name.partner_first_name) nameParts.push(name.partner_first_name);
	if (name.partner_middle_name)
		nameParts.push(name.partner_middle_name[0] + ".");
	if (name.partner_last_name) nameParts.push(name.partner_last_name);
	if (name.partner_suffix) nameParts.push(name.partner_suffix);

	const fullName = nameParts.join(" ");
	return fullName;
};

export const getAddress = (address) => {
	const addressParts = [];

	if (address.address) addressParts.push(address.address);
	if (address.village) addressParts.push(address.village);

	const fullAddress = addressParts.join(", ");
	return fullAddress;
};
