import React, { useEffect, useContext, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { SessionContext } from "../context/SessionContext";
import { ProfileContext } from "../context/ProfileContext";
import { WithContext as ReactTags } from "react-tag-input";
import toast from "react-hot-toast";
import ToastDisplay from "../components/ToastDisplay";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Pagination from "../components/Pagination";
import Spinner from "react-bootstrap/esm/Spinner";
import ContentLoader from "../components/ContentLoader";
import Dropdown from "react-bootstrap/Dropdown";
import { getFullName } from "./Utils";

const ContractorContacts = () => {
	const navigate = useNavigate();
	const [session] = useContext(SessionContext);
	const [profile] = useContext(ProfileContext);
	const [contacts, setContacts] = useState([]);
	const [meta, setMeta] = useState(null);
	const [loading, setLoading] = useState(false);
	const [actionLoading, setActionLoading] = useState(false);
	const [contactModal, setContactModal] = useState(false);
	const [filterModal, setFilterModal] = useState(false);
	const [showFilters, setShowFilters] = useState(false);
	const [removeEmails, setRemoveEmails] = useState([]);
	const [errors, setErrors] = useState([]);

	const [contactID, setContactID] = useState(null);
	const [contact, setContact] = useState(null);
	const [contactLoading, setContactLoading] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);

	const [prefix, setPrefix] = useState("");
	const [firstName, setFirstName] = useState("");
	const [middleName, setMiddleName] = useState("");
	const [lastName, setLastName] = useState("");
	const [suffix, setSuffix] = useState("");
	const [gender, setGender] = useState("");
	const [emails, setEmails] = useState([]);
	const [position, setPosition] = useState("");
	const [phone, setPhone] = useState("");
	const [remarks, setRemarks] = useState("");

	const [searchParams, setSearchParams] = useSearchParams({});
	const [searchQ, setSearchQ] = useState(searchParams.get("q") || "");
	const [searchPosition, setSearchPosition] = useState(
		searchParams.get("position") || ""
	);
	const [searchPhone, setSearchPhone] = useState(
		searchParams.get("phone") || ""
	);

	const searchFields = [
		{ key: "q", value: searchQ },
		{ key: "position", value: searchPosition },
		{ key: "phone", value: searchPhone },
	];

	const filterFields = [
		{
			key: "position",
			value: searchPosition,
			defaultVal: "",
			edit: setSearchPosition,
		},
		{
			key: "phone",
			value: searchPhone,
			defaultVal: "",
			edit: setSearchPhone,
		},
	];

	const requiredFields = [
		{ key: "firstName", value: firstName },
		{ key: "lastName", value: lastName },
		{ key: "emails", value: emails },
	];

	const getData = async () => {
		setLoading(true);
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/contractors/contacts${window.location.search}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			setContacts(data.data);
			setMeta(data.meta);
		}
		setLoading(false);
	};

	const getContact = async () => {
		setContactLoading(true);
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/contractors/contacts/c/${contactID}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			setContact(data.contact);
			setEmails(
				data.contact.emails.map((email) => ({
					id: email.id,
					text: email.email,
				}))
			);
			setPrefix(data.contact.prefix);
			setFirstName(data.contact.first_name);
			setMiddleName(data.contact.middle_name);
			setLastName(data.contact.last_name);
			setSuffix(data.contact.suffix);
			setGender(data.contact.gender);
			setPosition(data.contact.position);
			setPhone(data.contact.phone);
			setRemarks(data.contact.remarks);
			setContactLoading(false);
		}
	};

	const saveContact = async () => {
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
			body: JSON.stringify({
				prefix: prefix,
				first_name: firstName,
				middle_name: middleName,
				last_name: lastName,
				suffix: suffix,
				gender: gender,
				position: position,
				phone: phone,
				emails: emails,
				remarks: remarks,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/contractors/contacts`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			toast(<ToastDisplay status="error" content={data.msg} />);
		} else {
			toast(<ToastDisplay status="success" content={data.msg} />);
			closeContactModal();
			getData();
		}
		setActionLoading(false);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setActionLoading(true);
		var newErrors = [];

		requiredFields.map((field) => {
			if (!field.value || field.value === "" || field.value.length === 0) {
				newErrors.push(field.key);
			}
		});

		if (newErrors.length !== 0) {
			toast(
				<ToastDisplay
					status="error"
					content="Please fill out all required fields."
				/>
			);
			setActionLoading(false);
			setErrors(newErrors);
			return;
		}
		saveContact();
	};

	const editContact = async () => {
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
			body: JSON.stringify({
				prefix: prefix,
				first_name: firstName,
				middle_name: middleName,
				last_name: lastName,
				suffix: suffix,
				gender: gender,
				position: position,
				phone: phone,
				emails: emails,
				remarks: remarks,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/contractors/contacts/c/${contactID}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			toast(<ToastDisplay status="error" content={data.msg} />);
		} else {
			toast(<ToastDisplay status="success" content={data.msg} />);
			getData();
		}
		setActionLoading(false);
	};

	const handleContactSubmit = (e) => {
		e.preventDefault();
		setActionLoading(true);
		var newErrors = [];

		requiredFields.map((field) => {
			if (!field.value || field.value === "" || field.value.length === 0) {
				newErrors.push(field.key);
			}
		});

		if (newErrors.length !== 0) {
			toast(
				<ToastDisplay
					status="error"
					content="Please fill out all required fields."
				/>
			);
			setActionLoading(false);
			setErrors(newErrors);
			return;
		}
		editContact();
	};

	const deleteContact = async () => {
		setDeleteLoading(true);
		const requestOptions = {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/contractors/contacts/c/${contactID}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			toast(<ToastDisplay status="success" content={data.msg} />);
			getData();
			closeContactModal();
			setDeleteModal(false);
		}
		setDeleteLoading(false);
	};

	const search = (e) => {
		e.preventDefault();
		var newSearch = { ...searchParams };
		searchFields.map((field) => {
			if (field.value !== "") {
				newSearch[field.key] = field.value;
			} else {
				delete newSearch[field.key];
			}
		});
		setSearchParams(newSearch);
		setFilterModal(false);
	};

	const removeFilter = (e, func, key) => {
		e.preventDefault();
		func("");
		var newSearch = { ...searchParams };
		searchFields.map((field) => {
			if (field.value !== "" && field.key !== key) {
				newSearch[field.key] = field.value;
			} else {
				delete newSearch[field.key];
			}
		});
		setSearchParams(newSearch);
	};

	const clearFilters = (e) => {
		e.preventDefault();
		setSearchParams({});
		filterFields.map((field) => {
			field.edit(field.defaultVal);
		});
	};

	const closeContactModal = () => {
		setContactModal(false);
		setContactID(null);
		setContact(null);
		setPrefix("");
		setFirstName("");
		setMiddleName("");
		setLastName("");
		setSuffix("");
		setGender("");
		setEmails([]);
		setPosition("");
		setPhone("");
		setRemarks("");

		setRemoveEmails([]);
		setErrors([]);
	};

	const isEmpty = (value) => {
		return value === "" || value === null || value === undefined;
	};

	const KeyCodes = {
		comma: 188,
		enter: 13,
		space: 32,
	};

	const emailDelimiters = [KeyCodes.comma, KeyCodes.enter, KeyCodes.space];

	const handleDeleteEmail = (i) => {
		setRemoveEmails([
			...removeEmails,
			emails.filter((tag, index) => index === i)[0],
		]);
		setEmails(emails.filter((tag, index) => index !== i));
	};

	const handleNewEmail = (tag) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (emailRegex.test(tag.text)) {
			setEmails([...emails, tag]);
		} else {
			toast(<ToastDisplay status="error" content="Invalid email format." />);
		}
	};

	const handleEmailBlur = (tag) => {
		if (tag) {
			handleNewEmail({ id: tag, text: tag });
		}
	};

	useEffect(() => {
		if (profile) {
			if (!profile.role.contractors_view) {
				navigate("/");
			} else {
				getData();
				if (filterFields.every((field) => isEmpty(field.value))) {
					setShowFilters(false);
				} else {
					setShowFilters(true);
				}
			}
		}
	}, [searchParams, profile]);

	useEffect(() => {
		if (contactID) {
			getContact();
		}
	}, [contactID]);

	return (
		<>
			<Row className="flex-row-left mb-20">
				<Col className="col-4">
					<Form onSubmit={search}>
						<div className="search-container bg-white flex-row-left">
							<i className="ri-search-line search-icon" />
							<Form.Control
								className="text-sm"
								placeholder="Search contacts"
								value={searchQ}
								onChange={(e) => setSearchQ(e.target.value)}
							/>
						</div>
					</Form>
				</Col>
				<Col className="col-8 flex-row-right">
					<Pagination
						meta={meta}
						searchParams={searchParams}
						setSearchParams={setSearchParams}
						searchFields={searchFields}
						className="pull-right flex-row-left"
					/>
					<Button
						variant="light"
						className="refresh-btn flex-column"
						onClick={getData}
					>
						<i className="ri-restart-line opacity-7" />
					</Button>
					<Button
						className="control-btn btn-sm btn-default-outlined text-medium-gotham text-sm"
						onClick={() => setFilterModal(true)}
					>
						<i className="ri-filter-line" />
						Filters
					</Button>
					{profile?.role?.contractors_add && (
						<Button
							className="control-btn btn-sm btn-default text-medium-gotham text-sm"
							onClick={() => setContactModal(true)}
						>
							Add Contact
						</Button>
					)}
				</Col>
			</Row>
			{showFilters ? (
				<div className="filters-container">
					<span className="filters-label text-medium-mont">Filters:</span>
					{filterFields.map((field) => {
						const { key, value } = field;
						if (value) {
							return (
								<div
									key={key}
									className="filter-item bg-default flex-row-left rounded-4"
								>
									<span className="text-white label-md mb-0">
										{key.charAt(0).toUpperCase() + key.slice(1)}: {value}
									</span>
									<a
										href="#"
										className="remove-filter"
										onClick={(e) => removeFilter(e, field.edit, key)}
									>
										<i className="ri-close-line text-white" />
									</a>
								</div>
							);
						} else {
							return "";
						}
					})}
					<div className="filter-item rounded-4 fit">
						<a
							href="#"
							className="text-sm text-medium-mont text-default"
							onClick={clearFilters}
						>
							Clear Filters
						</a>
					</div>
				</div>
			) : (
				""
			)}
			<Row className="content-table-header bg-white gx-0">
				<Col className="p-10">
					<span className="text-sm text-medium-gotham block">Name</span>
				</Col>
				<Col className="p-10">
					<span className="text-sm text-medium-gotham block">Position</span>
				</Col>
				<Col className="p-10">
					<span className="text-sm text-medium-gotham block">Email</span>
				</Col>
			</Row>
			{loading ? (
				<ContentLoader />
			) : (
				contacts?.map((contact) => (
					<a key={contact.id} href="#" onClick={() => setContactID(contact.id)}>
						<Row className="content-table-item bg-white gx-0">
							<Col className="p-10 ellipsis">
								<span className="text-sm">{getFullName(contact)}</span>
							</Col>
							<Col className="p-10 ellipsis">
								<span className="text-sm">{contact.position || "--"}</span>
							</Col>
							<Col className="p-10 flex-row-left">
								<span className="email-preview text-sm ellipsis">
									{contact.emails[0]?.email || "--"}
								</span>
								{contact.emails.length > 1 && (
									<div className="email-badge rounded-8 flex-column">
										<span className="label-md text-medium-gotham opacity-7">
											+{contact.emails.length - 1}
										</span>
									</div>
								)}
							</Col>
						</Row>
					</a>
				))
			)}
			<Row className="mt-4 mb-20">
				<Col className="flex-row-right">
					<Pagination
						meta={meta}
						searchParams={searchParams}
						setSearchParams={setSearchParams}
						searchFields={searchFields}
						className="pull-right flex-row-left pagination-bottom"
					/>
				</Col>
			</Row>
			<Modal
				show={filterModal}
				centered
				onHide={() => setFilterModal(false)}
				className="filter-modal"
			>
				<Form onSubmit={search}>
					<Modal.Header>
						<Modal.Title className="text-lg">Filters</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Position</Form.Label>
								<Form.Control
									className="text-sm form-input"
									value={searchPosition}
									onChange={(e) => setSearchPosition(e.target.value)}
								/>
							</Col>
						</Row>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Phone</Form.Label>
								<Form.Control
									className="text-sm form-input"
									value={searchPhone}
									onChange={(e) => setSearchPhone(e.target.value)}
								/>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="outline-secondary"
							className="btn-sm action-btn text-sm me-2"
							onClick={() => setFilterModal(false)}
						>
							Close
						</Button>
						<Button
							type="submit"
							variant="primary"
							className="btn-sm action-btn text-sm btn-default"
						>
							Apply
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal
				show={contactModal}
				className="info-modal"
				centered
				size="lg"
				onHide={closeContactModal}
			>
				<Form onSubmit={handleSubmit}>
					<Modal.Header>
						<Modal.Title className="text-lg">New Contact</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="mb-10">
							<h2 className="text-lg text-medium-gotham mb-0">
								Contact Information
							</h2>
						</div>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Prefix</Form.Label>
								<Form.Control
									autoFocus
									className={`text-sm form-input ${
										errors.includes("prefix") && "border-danger"
									}`}
									value={prefix}
									onChange={(e) => setPrefix(e.target.value)}
								/>
							</Col>
							<Col />
						</Row>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">First Name*</Form.Label>
								<Form.Control
									className={`text-sm form-input ${
										errors.includes("firstName") && "border-danger"
									}`}
									value={firstName}
									onChange={(e) => setFirstName(e.target.value)}
								/>
							</Col>
							<Col>
								<Form.Label className="text-sm">Middle Name</Form.Label>
								<Form.Control
									className={`text-sm form-input ${
										errors.includes("middleName") && "border-danger"
									}`}
									value={middleName}
									onChange={(e) => setMiddleName(e.target.value)}
								/>
							</Col>
						</Row>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Last Name*</Form.Label>
								<Form.Control
									className={`text-sm form-input ${
										errors.includes("lastName") && "border-danger"
									}`}
									value={lastName}
									onChange={(e) => setLastName(e.target.value)}
								/>
							</Col>
							<Col>
								<Form.Label className="text-sm">Suffix</Form.Label>
								<Form.Control
									className={`text-sm form-input ${
										errors.includes("suffix") && "border-danger"
									}`}
									value={suffix}
									onChange={(e) => setSuffix(e.target.value)}
								/>
							</Col>
						</Row>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Gender</Form.Label>
								<Form.Select
									className={`text-sm form-input ${
										errors.includes("gender") && "border-danger"
									}`}
									value={gender}
									onChange={(e) => setGender(e.target.value)}
								>
									<option value=""></option>
									<option value="Male">Male</option>
									<option value="Female">Female</option>
									<option value="Other">Other</option>
								</Form.Select>
							</Col>
							<Col>
								<Form.Label className="text-sm">Position</Form.Label>
								<Form.Control
									className={`text-sm form-input ${
										errors.includes("position") && "border-danger"
									}`}
									value={position}
									onChange={(e) => setPosition(e.target.value)}
								/>
							</Col>
						</Row>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Phone</Form.Label>
								<Form.Control
									className={`text-sm form-input ${
										errors.includes("phone") && "border-danger"
									}`}
									value={phone}
									onChange={(e) => setPhone(e.target.value)}
								/>
							</Col>
						</Row>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Emails*</Form.Label>
								<ReactTags
									tags={emails}
									delimiters={emailDelimiters}
									handleInputBlur={handleEmailBlur}
									handleDelete={handleDeleteEmail}
									handleAddition={handleNewEmail}
									inputFieldPosition="bottom"
									allowDragDrop={false}
									placeholder=""
									autofocus={false}
									classNames={{
										tagInput: "full",
										tagInputField: `full form-control form-input text-sm ${
											errors.includes("emails") && "border-danger"
										}`,
										tag: "tag-item text-sm bg-green text-white rounded-4",
										remove: "tag-remove-btn text-white flex-column",
									}}
								/>
							</Col>
						</Row>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Remarks</Form.Label>
								<Form.Control
									as="textarea"
									className={`text-sm form-input ${
										errors.includes("remarks") && "border-danger"
									}`}
									value={remarks}
									onChange={(e) => setRemarks(e.target.value)}
								/>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="outline-secondary"
							className="btn-sm action-btn text-sm me-2"
							onClick={closeContactModal}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							variant="primary"
							className="btn-sm action-btn flex-column text-sm btn-default"
							disabled={actionLoading}
						>
							{actionLoading ? (
								<Spinner className="btn-loader text-white" />
							) : (
								"Save"
							)}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal
				show={contactID}
				className="info-modal"
				centered
				size="lg"
				onHide={closeContactModal}
			>
				<Form onSubmit={handleContactSubmit}>
					<Modal.Header>
						<Col className="col-9">
							<Modal.Title className="text-lg">Contact Information</Modal.Title>
						</Col>
						<Col className="flex-row-right">
							{profile?.role?.contractors_edit ? (
								<Dropdown>
									<Dropdown.Toggle
										variant="light"
										className="modal-control-btn text-lg rounded-4 flex-column no-arrow"
										disabled={loading}
									>
										<i className="ri-more-2-fill me-0" />
									</Dropdown.Toggle>
									<Dropdown.Menu className="text-sm dropdown-default">
										{profile?.role?.contractors_edit && (
											<Dropdown.Item
												href="#"
												className="text-danger"
												onClick={() => setDeleteModal(true)}
											>
												Delete
											</Dropdown.Item>
										)}
									</Dropdown.Menu>
								</Dropdown>
							) : (
								""
							)}
						</Col>
					</Modal.Header>
					<Modal.Body>
						{contactLoading ? (
							<div className="full-height flex-column bg-white">
								<Spinner className="opacity-5" />
							</div>
						) : (
							<>
								<div className="mb-10">
									<h2 className="text-lg text-medium-gotham mb-0">
										Contact Information
									</h2>
								</div>
								<Row className="mb-20">
									<Col>
										<Form.Label className="text-sm">Prefix</Form.Label>
										<Form.Control
											className={`text-sm form-input ${
												errors.includes("prefix") && "border-danger"
											}`}
											value={prefix}
											onChange={(e) => setPrefix(e.target.value)}
										/>
									</Col>
									<Col />
								</Row>
								<Row className="mb-20">
									<Col>
										<Form.Label className="text-sm">First Name*</Form.Label>
										<Form.Control
											className={`text-sm form-input ${
												errors.includes("firstName") && "border-danger"
											}`}
											value={firstName}
											onChange={(e) => setFirstName(e.target.value)}
										/>
									</Col>
									<Col>
										<Form.Label className="text-sm">Middle Name</Form.Label>
										<Form.Control
											className={`text-sm form-input ${
												errors.includes("middleName") && "border-danger"
											}`}
											value={middleName}
											onChange={(e) => setMiddleName(e.target.value)}
										/>
									</Col>
								</Row>
								<Row className="mb-20">
									<Col>
										<Form.Label className="text-sm">Last Name*</Form.Label>
										<Form.Control
											className={`text-sm form-input ${
												errors.includes("lastName") && "border-danger"
											}`}
											value={lastName}
											onChange={(e) => setLastName(e.target.value)}
										/>
									</Col>
									<Col>
										<Form.Label className="text-sm">Suffix</Form.Label>
										<Form.Control
											className={`text-sm form-input ${
												errors.includes("suffix") && "border-danger"
											}`}
											value={suffix}
											onChange={(e) => setSuffix(e.target.value)}
										/>
									</Col>
								</Row>
								<Row className="mb-20">
									<Col>
										<Form.Label className="text-sm">Gender</Form.Label>
										<Form.Select
											className={`text-sm form-input ${
												errors.includes("gender") && "border-danger"
											}`}
											value={gender}
											onChange={(e) => setGender(e.target.value)}
										>
											<option value=""></option>
											<option value="Male">Male</option>
											<option value="Female">Female</option>
											<option value="Other">Other</option>
										</Form.Select>
									</Col>
									<Col>
										<Form.Label className="text-sm">Position</Form.Label>
										<Form.Control
											className={`text-sm form-input ${
												errors.includes("position") && "border-danger"
											}`}
											value={position}
											onChange={(e) => setPosition(e.target.value)}
										/>
									</Col>
								</Row>
								<Row className="mb-20">
									<Col>
										<Form.Label className="text-sm">Phone</Form.Label>
										<Form.Control
											className={`text-sm form-input ${
												errors.includes("phone") && "border-danger"
											}`}
											value={phone}
											onChange={(e) => setPhone(e.target.value)}
										/>
									</Col>
								</Row>
								<Row className="mb-20">
									<Col>
										<Form.Label className="text-sm">Emails*</Form.Label>
										<ReactTags
											tags={emails}
											delimiters={emailDelimiters}
											handleInputBlur={handleEmailBlur}
											handleDelete={handleDeleteEmail}
											handleAddition={handleNewEmail}
											inputFieldPosition="bottom"
											allowDragDrop={false}
											placeholder=""
											autofocus={false}
											classNames={{
												tagInput: "full",
												tagInputField: `full form-control form-input text-sm ${
													errors.includes("emails") && "border-danger"
												}`,
												tag: "tag-item text-sm bg-green text-white rounded-4",
												remove: "tag-remove-btn text-white flex-column",
											}}
										/>
									</Col>
								</Row>
								<Row className="mb-20">
									<Col>
										<Form.Label className="text-sm">Remarks</Form.Label>
										<Form.Control
											as="textarea"
											className={`text-sm form-input ${
												errors.includes("remarks") && "border-danger"
											}`}
											value={remarks}
											onChange={(e) => setRemarks(e.target.value)}
										/>
									</Col>
								</Row>
							</>
						)}
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="outline-secondary"
							className="btn-sm action-btn text-sm me-2"
							onClick={closeContactModal}
						>
							Close
						</Button>
						<Button
							type="submit"
							variant="primary"
							className="btn-sm action-btn flex-column text-sm btn-default"
							disabled={actionLoading || contactLoading}
						>
							{actionLoading ? (
								<Spinner className="btn-loader text-white" />
							) : (
								"Save Changes"
							)}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal show={deleteModal} onHide={() => setDeleteModal(false)}>
				<Modal.Body>
					<h2 className="text-lg text-medium-inter mb-15">Delete Contact</h2>
					<p className="text-sm opacity-7">
						Are you sure you want to delete this contact person? This action
						cannot be undone.
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="light"
						className="modal-btn btn-sm dismiss-btn text-medium-inter text-sm"
						onClick={() => setDeleteModal(false)}
					>
						Cancel
					</Button>
					<Button
						variant="danger"
						className="action-btn btn-sm text-medium-inter text-sm flex-column"
						disabled={deleteLoading}
						onClick={deleteContact}
					>
						{deleteLoading ? (
							<Spinner className="btn-loader text-white" />
						) : (
							"Delete Contact"
						)}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default ContractorContacts;
