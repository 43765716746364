import React, { useEffect, useContext, useState, useMemo, useRef } from "react";
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import { ClientContext } from "../context/ClientContext";
import { getFullName } from "../components/Utils";
import { v4 as uuidv4 } from "uuid";
import format from "date-fns/format";
import toast from "react-hot-toast";
import ToastDisplay from "../components/ToastDisplay";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Pagination from "../components/Pagination";
import Spinner from "react-bootstrap/Spinner";
import ContentLoader from "../components/ContentLoader";
import ContentLoaderSm from "../components/ContentLoaderSm";
import FileUpload from "../components/FileUpload";
import Empty from "../components/Empty";

const ClientHome = () => {
	document.title = "Work Orders | Repairs & Maintenance Management System";
	const fileRef = useRef(null);
	const navigate = useNavigate();
	const [clientSession] = useContext(ClientContext);
	const [workOrders, setWorkOrders] = useState([]);
	const [meta, setMeta] = useState(null);
	const [loading, setLoading] = useState(true);
	const [generating, setGenerating] = useState(false);
	const [actionLoading, setActionLoading] = useState(false);
	const [workOrderModal, setWorkOrderModal] = useState(false);
	const [filterModal, setFilterModal] = useState(false);
	const [showFilters, setShowFilters] = useState(false);
	const [errors, setErrors] = useState([]);

	const [shortDesc, setShortDesc] = useState("");
	const [desc, setDesc] = useState("");
	const [type, setType] = useState("Corrective");
	const [category, setCategory] = useState("");

	const [files, setFiles] = useState([]);
	const [filePaths, setFilePaths] = useState([]);

	const [searchParams, setSearchParams] = useSearchParams({});
	const [searchQ, setSearchQ] = useState(searchParams.get("q") || "");
	const [searchStatus, setSearchStatus] = useState(
		searchParams.get("status") || ""
	);
	const [searchType, setSearchType] = useState(searchParams.get("type") || "");
	const [searchCategory, setSearchCategory] = useState(
		searchParams.get("category") || ""
	);

	const searchFields = [
		{ key: "q", value: searchQ },
		{ key: "status", value: searchStatus },
		{ key: "type", value: searchType },
		{ key: "category", value: searchCategory },
	];

	const filterFields = [
		{
			key: "status",
			value: searchStatus,
			defaultVal: "",
			edit: setSearchStatus,
		},
		{
			key: "type",
			value: searchType,
			defaultVal: "",
			edit: setSearchType,
		},
		{
			key: "category",
			value: searchCategory,
			defaultVal: "",
			edit: setSearchCategory,
		},
	];

	const requiredFields = [
		{ key: "shortDesc", value: shortDesc },
		{ key: "desc", value: desc },
		{ key: "type", value: type },
		{ key: "category", value: category },
	];

	const getCurrentDate = () => {
		const date = new Date();
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, "0");
		const day = String(date.getDate()).padStart(2, "0");
		return `${year}-${month}-${day}`;
	};

	const getData = async () => {
		setLoading(true);
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": clientSession,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/clients/work-orders/${window.location.search}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			setWorkOrders(data.data);
			setMeta(data.meta);
		}
		setLoading(false);
	};

	const saveWorkOrder = async () => {
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": clientSession,
			},
			body: JSON.stringify({
				short_description: shortDesc,
				description: desc,
				type: type,
				category: category,
				attachments: filePaths,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/clients/work-orders/`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			toast(<ToastDisplay status="error" content={data.msg} />);
		} else {
			toast(<ToastDisplay status="success" content={data.msg} />);
			closeWorkOrderModal();
			navigate(`/work-orders/${data.work_order_id}`);
		}
		setActionLoading(false);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setActionLoading(true);
		var newErrors = [];

		requiredFields.map((field) => {
			if (!field.value || field.value === "" || field.value.length === 0) {
				newErrors.push(field.key);
			}
		});

		if (newErrors.length !== 0) {
			toast(
				<ToastDisplay
					status="error"
					content="Please fill out all required fields."
				/>
			);
			setActionLoading(false);
			setErrors(newErrors);
			return;
		}
		saveWorkOrder();
	};

	const search = (e) => {
		e.preventDefault();
		var newSearch = { ...searchParams };
		searchFields.map((field) => {
			if (field.value !== "") {
				newSearch[field.key] = field.value;
			} else {
				delete newSearch[field.key];
			}
		});
		setSearchParams(newSearch);
		setFilterModal(false);
	};

	const removeFilter = (e, func, key) => {
		e.preventDefault();
		func("");
		var newSearch = { ...searchParams };
		searchFields.map((field) => {
			if (field.value !== "" && field.key !== key) {
				newSearch[field.key] = field.value;
			} else {
				delete newSearch[field.key];
			}
		});
		setSearchParams(newSearch);
	};

	const clearFilters = (e) => {
		e.preventDefault();
		setSearchParams({});
		filterFields.map((field) => {
			field.edit(field.defaultVal);
		});
	};

	const closeWorkOrderModal = () => {
		setWorkOrderModal(false);
		setShortDesc("");
		setDesc("");
		setCategory("");

		setErrors([]);
	};

	const handleFiles = (e) => {
		const newFiles = Array.from(e.target.files);
		const forUpload = [...files];
		const paths = [...filePaths];

		newFiles.map((file) => {
			const newUid = uuidv4();
			forUpload.push({
				uuid: `${newUid}.${file.name.split(".").pop()}`,
				file: file,
			});
			paths.push(`${newUid}.${file.name.split(".").pop()}`);
		});

		setFiles(forUpload);
		setFilePaths(paths);
	};

	const isEmpty = (value) => {
		return value === "" || value === null || value === undefined;
	};

	useEffect(() => {
		getData();
		if (filterFields.every((field) => isEmpty(field.value))) {
			setShowFilters(false);
		} else {
			setShowFilters(true);
		}
	}, [searchParams]);

	return (
		<>
			<div className="page-banner bg-cover display-sm pt-4 pb-4">
				<Container className="gx-md-5 gx-4">
					<h1 className="title-md text-medium-gotham text-white mb-20">
						Work Orders
					</h1>
					<Row className="mb-20">
						<Col className="col-4">
							<a href="#">
								<div className="banner-stat rounded-8 flex-column">
									<div className="stat-text-container">
										<span className="title-md text-medium-inter text-white">
											{meta?.new !== undefined ? meta.new : "--"}
										</span>
									</div>
									<div className="stat-text-container">
										<span className="text-white text-sm">New</span>
									</div>
								</div>
							</a>
						</Col>
						<Col className="col-4">
							<a href="#">
								<div className="banner-stat rounded-8 flex-column">
									<div className="stat-text-container">
										<span className="title-md text-medium-inter text-white">
											{meta?.ongoing !== undefined ? meta.ongoing : "--"}
										</span>
									</div>
									<div className="stat-text-container">
										<span className="text-white text-sm">Ongoing</span>
									</div>
								</div>
							</a>
						</Col>
						<Col className="col-4">
							<a href="#">
								<div className="banner-stat rounded-8 flex-column">
									<div className="stat-text-container">
										<span className="title-md text-medium-inter text-white">
											{meta?.completed !== undefined ? meta.completed : "--"}
										</span>
									</div>
									<div className="stat-text-container">
										<span className="text-white text-sm">Completed</span>
									</div>
								</div>
							</a>
						</Col>
					</Row>
					<Form onSubmit={search} className="search-form-sm full display-sm">
						<div className="search-container-sm border bg-white flex-row-left">
							<i className="ri-search-line search-icon" />
							<Form.Control
								className="text-sm"
								placeholder="Search work orders"
								value={searchQ}
								onChange={(e) => setSearchQ(e.target.value)}
							/>
							<Button
								variant="light"
								className="filter-btn flex-column"
								onClick={() => setFilterModal(true)}
							>
								<i className="ri-equalizer-3-fill m-0" />
							</Button>
						</div>
					</Form>
				</Container>
			</div>
			<Container className="gx-md-5 gx-4">
				<Row className="content-header flex-row-left mb-30 display-lg">
					<Col>
						<h1 className="text-xl m-0">Work Orders</h1>
					</Col>
				</Row>
				<Row className="flex-row-left mb-20 display-lg">
					<Col className="col-5">
						<Form onSubmit={search}>
							<div className="search-container bg-white flex-row-left">
								<i className="ri-search-line search-icon" />
								<Form.Control
									className="text-sm"
									placeholder="Search work orders"
									value={searchQ}
									onChange={(e) => setSearchQ(e.target.value)}
								/>
								<Button
									variant="light"
									className="filter-btn flex-column"
									onClick={() => setFilterModal(true)}
								>
									<i className="ri-equalizer-3-fill m-0" />
								</Button>
							</div>
						</Form>
					</Col>
					<Col className="col-7 flex-row-right">
						<Pagination
							meta={meta}
							searchParams={searchParams}
							setSearchParams={setSearchParams}
							searchFields={searchFields}
							className="pull-right flex-row-left"
						/>
						<Button
							variant="light"
							className="refresh-btn flex-column"
							onClick={getData}
						>
							<i className="ri-restart-line opacity-7" />
						</Button>
						<Button
							className="control-btn btn-sm btn-default text-medium-gotham text-sm"
							onClick={() => setWorkOrderModal(true)}
						>
							Add Work Order
						</Button>
					</Col>
				</Row>
				{showFilters ? (
					<div className="filters-container">
						<span className="filters-label text-medium-mont">Filters:</span>
						{filterFields.map((field) => {
							const { key, value } = field;
							if (value) {
								return (
									<div
										key={key}
										className="filter-item bg-default flex-row-left rounded-4"
									>
										<span className="text-white label-md mb-0">
											{key.charAt(0).toUpperCase() + key.slice(1)}: {value}
										</span>
										<a
											href="#"
											className="remove-filter"
											onClick={(e) => removeFilter(e, field.edit, key)}
										>
											<i className="ri-close-line text-white" />
										</a>
									</div>
								);
							} else {
								return "";
							}
						})}
						<div className="filter-item rounded-4 fit">
							<a
								href="#"
								className="text-sm text-medium-mont text-default"
								onClick={clearFilters}
							>
								Clear Filters
							</a>
						</div>
					</div>
				) : (
					""
				)}
				<div className="display-lg">
					<Row className="content-table-header bg-white gx-0">
						<Col className="p-10 col-2">
							<span className="text-sm text-medium-gotham block">
								Work Order #
							</span>
						</Col>
						<Col className="p-10">
							<span className="text-sm text-medium-gotham block">Client</span>
						</Col>
						<Col className="p-10">
							<span className="text-sm text-medium-gotham block">
								Short Description
							</span>
						</Col>
						<Col className="p-10">
							<span className="text-sm text-medium-gotham block">WO Date</span>
						</Col>
						<Col className="p-10">
							<span className="text-sm text-medium-gotham block">Status</span>
						</Col>
					</Row>
					{loading ? (
						<ContentLoader />
					) : workOrders?.length === 0 ? (
						<Empty label="No work orders" className="bg-white" />
					) : (
						workOrders?.map((wo) => (
							<Link key={wo.id} to={`/work-orders/${wo.id}`}>
								<Row className="content-table-item bg-white gx-0 flex-row-left">
									<Col className="p-10 ellipsis col-2">
										<span className="text-sm">{wo.wo_number}</span>
									</Col>
									<Col className="p-10 ellipsis">
										<span className="text-sm ellipsis">
											{getFullName(wo.client) || "--"}
										</span>
									</Col>
									<Col className="p-10 ellipsis">
										<span className="text-sm ellipsis">
											{wo.short_description || "--"}
										</span>
									</Col>
									<Col className="p-10 ellipsis">
										<span className="text-sm">
											{(wo.wo_date &&
												format(new Date(wo.wo_date), "MMM dd, yyyy")) ||
												"--"}
										</span>
									</Col>
									<Col className="p-10">
										<div className={`wo-status fit flex-column ${wo.status}`}>
											<span className="label-md text-medium-gotham">
												{wo.status || "--"}
											</span>
										</div>
									</Col>
								</Row>
							</Link>
						))
					)}
				</div>
				<div className="display-sm full">
					{loading ? (
						<ContentLoaderSm />
					) : workOrders.length === 0 ? (
						<Empty label="No work orders" />
					) : (
						workOrders?.map((wo) => (
							<Link
								key={wo.id}
								to={`/work-orders/${wo.id}`}
								className="full mb-20 block"
							>
								<div className="content-item-sm bg-white gx-0 full rounded-8 border">
									<div className="mb-15">
										<div>
											<span className="text-sm content-item-title block text-medium-gotham">
												Work Order #{wo.wo_number}
											</span>
										</div>
										<p className="text-sm m-0">{wo.short_description}</p>
									</div>
									<div className="content-item-sub border-top">
										<Row className="flex-row-left">
											<Col>
												<div className="content-status">
													<div
														className={`wo-status fit flex-column ${wo.status}`}
													>
														<span className="label-md">
															{wo.status || "--"}
														</span>
													</div>
												</div>
											</Col>
											<Col className="flex-row-right">
												<span className="label-md">
													{format(new Date(wo.created_at), "MMM dd, yyyy")}
												</span>
											</Col>
										</Row>
									</div>
								</div>
							</Link>
						))
					)}
				</div>
				<div className="display-sm">
					<Button
						className="fab flex-row-center"
						onClick={() => setWorkOrderModal(true)}
					>
						<i className="ri-add-line" />
						<span className="label-md text-medium-gotham">New Request</span>
					</Button>
				</div>
				<Row className="mt-4 mb-20 display-lg">
					<Col className="flex-row-right">
						<Pagination
							meta={meta}
							searchParams={searchParams}
							setSearchParams={setSearchParams}
							searchFields={searchFields}
							className="pull-right flex-row-left pagination-bottom"
						/>
					</Col>
				</Row>
				{meta?.next && (
					<Row className="mt-4 mb-20 display-sm">
						<Col className="flex-column">
							<a href="#" className="text-green">
								Load More
							</a>
						</Col>
					</Row>
				)}
			</Container>
			<Modal
				show={filterModal}
				centered
				onHide={() => setFilterModal(false)}
				className="filter-modal"
			>
				<Form onSubmit={search}>
					<Modal.Header>
						<Modal.Title className="text-lg">Filters</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{/* <Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Type</Form.Label>
								<Form.Select
									className="text-sm form-input"
									value={searchType}
									onChange={(e) => setSearchType(e.target.value)}
								>
									<option value=""></option>
									<option value="Preventive Maintenance">
										Preventive Maintenance
									</option>
									<option value="Corrective Maintenance">
										Corrective Maintenance
									</option>
								</Form.Select>
							</Col>
						</Row> */}
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Category</Form.Label>
								<Form.Select
									className="text-sm form-input"
									value={searchCategory}
									onChange={(e) => setSearchCategory(e.target.value)}
								>
									<option value=""></option>
									<option value="Electrical">Electrical</option>
									<option value="Plumbing">Plumbing</option>
								</Form.Select>
							</Col>
						</Row>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Status</Form.Label>
								<Form.Select
									className="text-sm form-input"
									value={searchStatus}
									onChange={(e) => setSearchStatus(e.target.value)}
								>
									<option value=""></option>
									<option value="New">New</option>
									<option value="Completed">Completed</option>
								</Form.Select>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="outline-secondary"
							className="btn-sm action-btn text-sm me-2"
							onClick={() => setFilterModal(false)}
						>
							Close
						</Button>
						<Button
							type="submit"
							variant="primary"
							className="btn-sm action-btn text-sm btn-default"
						>
							Apply
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal
				show={workOrderModal}
				centered
				size="lg"
				onHide={closeWorkOrderModal}
			>
				<Form onSubmit={handleSubmit}>
					<Modal.Header>
						<Modal.Title className="text-lg">New Work Order</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Short Description*</Form.Label>
								<Form.Control
									autoFocus
									className={`text-sm form-input ${
										errors.includes("shortDesc") && "border-danger"
									}`}
									value={shortDesc}
									onChange={(e) => setShortDesc(e.target.value)}
								/>
							</Col>
						</Row>
						{/* <Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Type*</Form.Label>
								<Form.Select
									className={`text-sm form-input ${
										errors.includes("type") && "border-danger"
									}`}
									value={type}
									onChange={(e) => setType(e.target.value)}
								>
									<option value=""></option>
									<option value="Preventive Maintenance">
										Preventive Maintenance
									</option>
									<option value="Corrective Maintenance">
										Corrective Maintenance
									</option>
								</Form.Select>
							</Col>
						</Row> */}
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Category*</Form.Label>
								<Form.Select
									className={`text-sm form-input ${
										errors.includes("category") && "border-danger"
									}`}
									value={category}
									onChange={(e) => setCategory(e.target.value)}
								>
									<option value=""></option>
									<option value="Electrical">Electrical</option>
									<option value="Plumbing">Plumbing</option>
								</Form.Select>
							</Col>
						</Row>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Description*</Form.Label>
								<Form.Control
									as="textarea"
									className={`text-sm form-input ${
										errors.includes("desc") && "border-danger"
									}`}
									value={desc}
									onChange={(e) => setDesc(e.target.value)}
								/>
							</Col>
						</Row>
						<Row className="mb-20">
							<Form.Label className="text-sm">Attachments</Form.Label>
							<div className="order-files-container">
								{files?.map((file, index) => (
									<FileUpload
										key={index}
										index={index}
										file={file}
										files={files}
										setFiles={setFiles}
										filePaths={filePaths}
										setFilePaths={setFilePaths}
									/>
								))}
								<Button
									variant="light"
									className="file-btn flex-column border"
									onClick={() => fileRef.current.click()}
								>
									<i className="ri-upload-line opacity-7" />
								</Button>
								<input
									ref={fileRef}
									type="file"
									multiple
									className="hidden"
									accept="image/*"
									capture="environment"
									onChange={handleFiles}
								/>
							</div>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="outline-secondary"
							className="btn-sm action-btn text-sm me-2"
							onClick={closeWorkOrderModal}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							variant="primary"
							className="btn-sm action-btn text-medium-inter flex-column text-sm btn-default"
							disabled={actionLoading}
						>
							{actionLoading ? (
								<Spinner className="btn-loader text-white" />
							) : (
								"Submit"
							)}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
};

export default ClientHome;
