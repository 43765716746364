import React from "react";
import Button from "react-bootstrap/Button";

const Pagination = ({
	meta,
	searchParams,
	setSearchParams,
	searchFields,
	className,
}) => {
	const paginate = (direction) => {
		var newSearch = { ...searchParams };
		var page = searchParams.get("page");
		if (!page) {
			page = 1;
		}
		if (direction === "next") {
			newSearch.page = parseInt(page) + 1;
		} else {
			if (searchParams.get("page") > 1) {
				newSearch.page = parseInt(page) - 1;
			}
		}

		searchFields.map((field) => {
			if (field.value !== "") {
				newSearch[field.key] = field.value;
			} else {
				delete newSearch[field.key];
			}
		});
		setSearchParams(newSearch);
	};

	return (
		<div className={`pagination-container ${className}`}>
			<span className="pagination-label text-sm">
				{meta?.end > 0 ? meta.start + 1 : 0} - {meta?.end} &nbsp; of &nbsp;{" "}
				{meta?.total}
			</span>
			<Button
				variant="light"
				className="pagination-btn flex-column ms-3 me-2"
				disabled={meta?.start == 0}
				onClick={() => paginate("previous")}
			>
				<i className="ri-arrow-left-s-line opacity-7" />
			</Button>
			<Button
				variant="light"
				className="pagination-btn pagination-next-btn flex-column me-3"
				disabled={!meta?.next}
				onClick={() => paginate("next")}
			>
				<i className="ri-arrow-right-s-line opacity-7" />
			</Button>
		</div>
	);
};

export default Pagination;
