import React, { useEffect, useContext, useState } from "react";
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import { SessionContext } from "../context/SessionContext";
import { ProfileContext } from "../context/ProfileContext";
import toast from "react-hot-toast";
import ToastDisplay from "../components/ToastDisplay";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Pagination from "../components/Pagination";
import Spinner from "react-bootstrap/esm/Spinner";
import ContentLoader from "../components/ContentLoader";

const Roles = () => {
	document.title = "Roles | Repairs & Maintenance Management System";
	const navigate = useNavigate();
	const [session] = useContext(SessionContext);
	const [profile] = useContext(ProfileContext);
	const [roles, setRoles] = useState([]);
	const [meta, setMeta] = useState(null);
	const [loading, setLoading] = useState(false);
	const [actionLoading, setActionLoading] = useState(false);
	const [roleModal, setRoleModal] = useState(false);
	const [filterModal, setFilterModal] = useState(false);
	const [showFilters, setShowFilters] = useState(false);
	const [errors, setErrors] = useState([]);

	const [name, setName] = useState("");
	const [workOrdersView, setWorkOrdersView] = useState(false);
	const [workOrdersAdd, setWorkOrdersAdd] = useState(false);
	const [workOrdersEdit, setWorkOrdersEdit] = useState(false);
	const [workOrdersDelete, setWorkOrdersDelete] = useState(false);
	const [itemsView, setItemsView] = useState(false);
	const [itemsAdd, setItemsAdd] = useState(false);
	const [itemsEdit, setItemsEdit] = useState(false);
	const [itemsDelete, setItemsDelete] = useState(false);
	const [clientsView, setClientsView] = useState(false);
	const [clientsAdd, setClientsAdd] = useState(false);
	const [clientsEdit, setClientsEdit] = useState(false);
	const [clientsDelete, setClientsDelete] = useState(false);
	const [contractorsView, setContractorsView] = useState(false);
	const [contractorsAdd, setContractorsAdd] = useState(false);
	const [contractorsEdit, setContractorsEdit] = useState(false);
	const [contractorsDelete, setContractorsDelete] = useState(false);
	const [usersView, setUsersView] = useState(false);
	const [usersAdd, setUsersAdd] = useState(false);
	const [usersEdit, setUsersEdit] = useState(false);
	const [usersDelete, setUsersDelete] = useState(false);
	const [rolesView, setRolesView] = useState(false);
	const [rolesAdd, setRolesAdd] = useState(false);
	const [rolesEdit, setRolesEdit] = useState(false);
	const [rolesDelete, setRolesDelete] = useState(false);

	const [searchParams, setSearchParams] = useSearchParams({});
	const [searchQ, setSearchQ] = useState(searchParams.get("q") || "");
	const searchFields = [{ key: "q", value: searchQ }];
	const requiredFields = [{ key: "name", value: name }];

	const getData = async () => {
		setLoading(true);
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/roles/${window.location.search}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			setRoles(data.data);
			setMeta(data.meta);
		}
		setLoading(false);
	};

	const saveRole = async () => {
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
			body: JSON.stringify({
				name: name,
				work_orders_view: workOrdersView,
				work_orders_add: workOrdersAdd,
				work_orders_edit: workOrdersEdit,
				work_orders_delete: workOrdersDelete,
				items_view: itemsView,
				items_add: itemsAdd,
				items_edit: itemsEdit,
				items_delete: itemsDelete,
				clients_view: clientsView,
				clients_add: clientsAdd,
				clients_edit: clientsEdit,
				clients_delete: clientsDelete,
				contractors_view: contractorsView,
				contractors_add: contractorsAdd,
				contractors_edit: contractorsEdit,
				contractors_delete: contractorsDelete,
				users_view: usersView,
				users_add: usersAdd,
				users_edit: usersEdit,
				users_delete: usersDelete,
				roles_view: rolesView,
				roles_add: rolesAdd,
				roles_edit: rolesEdit,
				roles_delete: rolesDelete,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/roles/`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			toast(<ToastDisplay status="error" content={data.msg} />);
		} else {
			toast(<ToastDisplay status="success" content={data.msg} />);
			closeRoleModal();
			navigate(`/property/roles/${data.role_id}`);
		}
		setActionLoading(false);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setActionLoading(true);
		var newErrors = [];

		requiredFields.map((field) => {
			if (!field.value || field.value === "" || field.value.length === 0) {
				newErrors.push(field.key);
			}
		});

		if (newErrors.length !== 0) {
			toast(
				<ToastDisplay
					status="error"
					content="Please fill out all required fields."
				/>
			);
			setActionLoading(false);
			setErrors(newErrors);
			return;
		}
		saveRole();
	};

	const search = (e) => {
		e.preventDefault();
		var newSearch = { ...searchParams };
		searchFields.map((field) => {
			if (field.value !== "") {
				newSearch[field.key] = field.value;
			} else {
				delete newSearch[field.key];
			}
		});
		setSearchParams(newSearch);
		setFilterModal(false);
	};

	const closeRoleModal = () => {
		setRoleModal(false);

		setWorkOrdersView(false);
		setWorkOrdersAdd(false);
		setWorkOrdersEdit(false);
		setWorkOrdersDelete(false);

		setItemsView(false);
		setItemsAdd(false);
		setItemsEdit(false);
		setItemsDelete(false);

		setClientsView(false);
		setClientsAdd(false);
		setClientsEdit(false);
		setClientsDelete(false);

		setContractorsView(false);
		setContractorsAdd(false);
		setContractorsEdit(false);
		setContractorsDelete(false);

		setUsersView(false);
		setUsersAdd(false);
		setUsersEdit(false);
		setUsersDelete(false);
		setRolesView(false);
		setRolesAdd(false);
		setRolesEdit(false);
		setRolesDelete(false);
		setErrors([]);
	};

	const isEmpty = (value) => {
		return value === "" || value === null || value === undefined;
	};

	const handleWorkOrdersView = () => {
		if (itemsView) {
			setWorkOrdersAdd(false);
			setWorkOrdersEdit(false);
			setWorkOrdersDelete(false);
		}
		setWorkOrdersView(!itemsView);
	};

	const handleItemsView = () => {
		if (itemsView) {
			setItemsAdd(false);
			setItemsEdit(false);
			setItemsDelete(false);
		}
		setItemsView(!itemsView);
	};

	const handleClientsView = () => {
		if (clientsView) {
			setClientsAdd(false);
			setClientsEdit(false);
			setClientsDelete(false);
		}
		setClientsView(!clientsView);
	};

	const handleContractorsView = () => {
		if (contractorsView) {
			setContractorsAdd(false);
			setContractorsEdit(false);
			setContractorsDelete(false);
		}
		setContractorsView(!contractorsView);
	};

	const handleUsersView = () => {
		if (usersView) {
			setUsersAdd(false);
			setUsersEdit(false);
			setUsersDelete(false);
		}
		setUsersView(!usersView);
	};

	const handleRolesView = () => {
		if (rolesView) {
			setRolesAdd(false);
			setRolesEdit(false);
			setRolesDelete(false);
		}
		setRolesView(!rolesView);
	};

	useEffect(() => {
		if (profile) {
			if (!profile.role.roles_view) {
				navigate("/");
			} else {
				getData();
			}
		}
	}, [searchParams, profile]);

	return (
		<>
			<Container className="gx-5">
				<Row className="content-header flex-row-left mb-30">
					<Col>
						<h1 className="text-xl m-0">Roles</h1>
					</Col>
				</Row>
				<Row className="flex-row-left mb-20">
					<Col className="col-5">
						<Form onSubmit={search}>
							<div className="search-container bg-white flex-row-left">
								<i className="ri-search-line search-icon" />
								<Form.Control
									className="text-sm"
									placeholder="Search roles"
									value={searchQ}
									onChange={(e) => setSearchQ(e.target.value)}
								/>
							</div>
						</Form>
					</Col>
					<Col className="col-7 flex-row-right">
						<Pagination
							meta={meta}
							searchParams={searchParams}
							setSearchParams={setSearchParams}
							searchFields={searchFields}
							className="pull-right flex-row-left"
						/>
						<Button
							variant="light"
							className="refresh-btn flex-column"
							onClick={getData}
						>
							<i className="ri-restart-line opacity-7" />
						</Button>
						{profile?.role?.roles_add && (
							<Button
								className="control-btn btn-sm btn-default text-medium-gotham text-sm"
								onClick={() => setRoleModal(true)}
							>
								Add Role
							</Button>
						)}
					</Col>
				</Row>
				<Row className="content-table-header bg-white gx-0">
					<Col className="p-10">
						<span className="text-sm text-medium-gotham block">Name</span>
					</Col>
				</Row>
				{loading ? (
					<ContentLoader />
				) : (
					roles?.map((role) => (
						<Link key={role.id} to={`/property/roles/${role.id}`}>
							<Row className="content-table-item bg-white gx-0">
								<Col className="p-10 ellipsis">
									<span className="text-sm">{role.name}</span>
								</Col>
							</Row>
						</Link>
					))
				)}
				<Row className="mt-4 mb-20">
					<Col className="flex-row-right">
						<Pagination
							meta={meta}
							searchParams={searchParams}
							setSearchParams={setSearchParams}
							searchFields={searchFields}
							className="pull-right flex-row-left pagination-bottom"
						/>
					</Col>
				</Row>
			</Container>
			<Modal
				show={roleModal}
				className="info-modal"
				centered
				size="lg"
				onHide={closeRoleModal}
			>
				<Form onSubmit={handleSubmit}>
					<Modal.Header>
						<Modal.Title className="text-lg">New Role</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="mb-10">
							<h2 className="text-lg text-medium-gotham mb-0">
								Role Information
							</h2>
						</div>
						<Row className="mb-30">
							<Col>
								<Form.Label className="text-sm">Name*</Form.Label>
								<Form.Control
									autoFocus
									className={`text-sm form-input ${
										errors.includes("name") && "border-danger"
									}`}
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
							</Col>
						</Row>
						<div className="mb-10">
							<h2 className="text-lg text-medium-gotham mb-0">Permissions</h2>
						</div>
						<div className="border rounded-8 p-20 mb-20">
							<div className="mb-20">
								<span className="text-medium-gotham">Work Orders</span>
							</div>
							<Row>
								<Col className="flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={workOrdersView}
										id="workOrdersView"
										onChange={handleWorkOrdersView}
									/>
									<label htmlFor="workOrdersView" className="lbl m-0" />
									<span className="text-sm ms-3">View</span>
								</Col>
								<Col className="flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={workOrdersAdd}
										id="workOrdersAdd"
										onChange={() => {
											setWorkOrdersAdd(!workOrdersAdd);
											setWorkOrdersView(true);
										}}
									/>
									<label htmlFor="workOrdersAdd" className="lbl m-0" />
									<span className="text-sm ms-3">Add</span>
								</Col>
								<Col className="flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={workOrdersEdit}
										id="workOrdersEdit"
										onChange={() => {
											setWorkOrdersEdit(!workOrdersEdit);
											setItemsView(true);
										}}
									/>
									<label htmlFor="workOrdersEdit" className="lbl m-0" />
									<span className="text-sm ms-3">Edit</span>
								</Col>
								<Col className="flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={workOrdersDelete}
										id="workOrdersDelete"
										onChange={() => {
											setWorkOrdersDelete(!workOrdersDelete);
											setWorkOrdersView(true);
										}}
									/>
									<label htmlFor="workOrdersDelete" className="lbl m-0" />
									<span className="text-sm ms-3">Delete</span>
								</Col>
							</Row>
						</div>
						<div className="border rounded-8 p-20 mb-20">
							<div className="mb-20">
								<span className="text-medium-gotham">Clients</span>
							</div>
							<Row>
								<Col className="flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={clientsView}
										id="clientsView"
										onChange={handleClientsView}
									/>
									<label htmlFor="clientsView" className="lbl m-0" />
									<span className="text-sm ms-3">View</span>
								</Col>
								<Col className="flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={clientsAdd}
										id="clientsAdd"
										onChange={() => {
											setClientsAdd(!clientsAdd);
											setClientsView(true);
										}}
									/>
									<label htmlFor="clientsAdd" className="lbl m-0" />
									<span className="text-sm ms-3">Add</span>
								</Col>
								<Col className="flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={clientsEdit}
										id="clientsEdit"
										onChange={() => {
											setClientsEdit(!clientsEdit);
											setClientsView(true);
										}}
									/>
									<label htmlFor="clientsEdit" className="lbl m-0" />
									<span className="text-sm ms-3">Edit</span>
								</Col>
								<Col className="flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={clientsDelete}
										id="clientsDelete"
										onChange={() => {
											setClientsDelete(!clientsDelete);
											setClientsView(true);
										}}
									/>
									<label htmlFor="clientsDelete" className="lbl m-0" />
									<span className="text-sm ms-3">Delete</span>
								</Col>
							</Row>
						</div>
						<div className="border rounded-8 p-20 mb-20">
							<div className="mb-20">
								<span className="text-medium-gotham">Contractors</span>
							</div>
							<Row>
								<Col className="flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={contractorsView}
										id="contractorsView"
										onChange={handleContractorsView}
									/>
									<label htmlFor="contractorsView" className="lbl m-0" />
									<span className="text-sm ms-3">View</span>
								</Col>
								<Col className="flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={contractorsAdd}
										id="contractorsAdd"
										onChange={() => {
											setContractorsAdd(!contractorsAdd);
											setContractorsView(true);
										}}
									/>
									<label htmlFor="contractorsAdd" className="lbl m-0" />
									<span className="text-sm ms-3">Add</span>
								</Col>
								<Col className="flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={contractorsEdit}
										id="contractorsEdit"
										onChange={() => {
											setContractorsEdit(!contractorsEdit);
											setContractorsView(true);
										}}
									/>
									<label htmlFor="contractorsEdit" className="lbl m-0" />
									<span className="text-sm ms-3">Edit</span>
								</Col>
								<Col className="flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={contractorsDelete}
										id="contractorsDelete"
										onChange={() => {
											setContractorsDelete(!contractorsDelete);
											setContractorsView(true);
										}}
									/>
									<label htmlFor="contractorsDelete" className="lbl m-0" />
									<span className="text-sm ms-3">Delete</span>
								</Col>
							</Row>
						</div>
						<div className="border rounded-8 p-20 mb-20">
							<div className="mb-20">
								<span className="text-medium-gotham">Users</span>
							</div>
							<Row>
								<Col className="flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={usersView}
										id="usersView"
										onChange={handleUsersView}
									/>
									<label htmlFor="usersView" className="lbl m-0" />
									<span className="text-sm ms-3">View</span>
								</Col>
								<Col className="flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={usersAdd}
										id="usersAdd"
										onChange={() => {
											setUsersAdd(!usersAdd);
											setUsersView(true);
										}}
									/>
									<label htmlFor="usersAdd" className="lbl m-0" />
									<span className="text-sm ms-3">Add</span>
								</Col>
								<Col className="flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={usersEdit}
										id="usersEdit"
										onChange={() => {
											setUsersEdit(!usersEdit);
											setUsersView(true);
										}}
									/>
									<label htmlFor="usersEdit" className="lbl m-0" />
									<span className="text-sm ms-3">Edit</span>
								</Col>
								<Col className="flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={usersDelete}
										id="usersDelete"
										onChange={() => {
											setUsersDelete(!usersDelete);
											setUsersView(true);
										}}
									/>
									<label htmlFor="usersDelete" className="lbl m-0" />
									<span className="text-sm ms-3">Delete</span>
								</Col>
							</Row>
						</div>
						<div className="border rounded-8 p-20 mb-20">
							<div className="mb-20">
								<span className="text-medium-gotham">Roles & Permissions</span>
							</div>
							<Row>
								<Col className="flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={rolesView}
										id="rolesView"
										onChange={handleRolesView}
									/>
									<label htmlFor="rolesView" className="lbl m-0" />
									<span className="text-sm ms-3">View</span>
								</Col>
								<Col className="flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={rolesAdd}
										id="rolesAdd"
										onChange={() => {
											setRolesAdd(!rolesAdd);
											setRolesView(true);
										}}
									/>
									<label htmlFor="rolesAdd" className="lbl m-0" />
									<span className="text-sm ms-3">Add</span>
								</Col>
								<Col className="flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={rolesEdit}
										id="rolesEdit"
										onChange={() => {
											setRolesEdit(!rolesEdit);
											setRolesView(true);
										}}
									/>
									<label htmlFor="rolesEdit" className="lbl m-0" />
									<span className="text-sm ms-3">Edit</span>
								</Col>
								<Col className="flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={rolesDelete}
										id="rolesDelete"
										onChange={() => {
											setRolesDelete(!rolesDelete);
											setRolesView(true);
										}}
									/>
									<label htmlFor="rolesDelete" className="lbl m-0" />
									<span className="text-sm ms-3">Delete</span>
								</Col>
							</Row>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="outline-secondary"
							className="btn-sm action-btn text-sm me-2"
							onClick={closeRoleModal}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							variant="primary"
							className="btn-sm action-btn flex-column text-sm btn-default"
							disabled={actionLoading}
						>
							{actionLoading ? (
								<Spinner className="btn-loader text-white" />
							) : (
								"Save"
							)}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
};

export default Roles;
