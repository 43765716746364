import { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { SessionContext } from "../context/SessionContext";
import { ProfileContext } from "../context/ProfileContext";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Skeleton from "react-loading-skeleton";
import Dropdown from "react-bootstrap/Dropdown";
import Nav from "react-bootstrap/Nav";
import toast from "react-hot-toast";
import UserDetails from "../components/UserDetails";
import ToastDisplay from "../components/ToastDisplay";
import InfoLogs from "../components/InfoLogs";

const User = () => {
	let { id } = useParams();
	const navigate = useNavigate();
	const [session] = useContext(SessionContext);
	const [profile] = useContext(ProfileContext);
	const [loading, setLoading] = useState(true);
	const [user, setUser] = useState(null);
	const [editModal, setEditModal] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const [passwordModal, setPasswordModal] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [actionLoading, setActionLoading] = useState(false);
	const [roles, setRoles] = useState([]);

	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [role, setRole] = useState("");
	const [password, setPassword] = useState("");

	const [errors, setErrors] = useState([]);
	const [tab, setTab] = useState("details");

	const requiredFields = [
		{ key: "name", value: name },
		{ key: "email", value: email },
		{ key: "role", value: role },
	];

	const getData = async () => {
		setLoading(true);
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/u/${id}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			setUser(data.user);
			setRoles(data.roles);
			setName(data.user.name);
			setEmail(data.user.email);
			setRole(data.user.role_id);
		}
		setLoading(false);
	};

	const editUser = async () => {
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
			body: JSON.stringify({
				name: name,
				email: email,
				role: role,
				password: password,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/u/${id}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			toast(<ToastDisplay status="error" content={data.msg} />);
		} else {
			toast(<ToastDisplay status="success" content={data.msg} />);
			getData();
			closeEditModal();
		}
		setActionLoading(false);
	};

	const deleteUser = async () => {
		setDeleteLoading(true);
		const requestOptions = {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/u/${id}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			toast(<ToastDisplay status="error" content={data.msg} />);
		} else {
			toast(<ToastDisplay status="success" content={data.msg} />);
			navigate("/property/users");
		}
		setDeleteModal(false);
		setDeleteLoading(false);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setActionLoading(true);
		var newErrors = [];

		requiredFields.map((field) => {
			if (!field.value || field.value === "" || field.value.length === 0) {
				newErrors.push(field.key);
			}
		});

		if (newErrors.length !== 0) {
			toast(
				<ToastDisplay
					status="error"
					content="Please fill out all required fields."
				/>
			);
			setActionLoading(false);
			setErrors(newErrors);
			return;
		}
		editUser();
	};

	const resetPassword = async (e) => {
		e.preventDefault();
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
			body: JSON.stringify({
				password: password,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/u/${user.id}/password/reset`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			toast(<ToastDisplay status="error" content={data.msg} />);
		} else {
			toast(<ToastDisplay status="success" content={data.msg} />);
			closePasswordModal();
		}
		setActionLoading(false);
	};

	const closeEditModal = () => {
		setEditModal(false);
		setName(user.name);
		setEmail(user.email);
		setRole(user.role_id);
		setErrors([]);
	};

	const closePasswordModal = () => {
		setPasswordModal(false);
		setPassword("");
	};

	useEffect(() => {
		if (profile) {
			if (!profile.role.users_view) {
				navigate("/");
			} else {
				if (id) {
					getData();
				}
			}
		}
	}, [id, profile]);

	return (
		<>
			<Container className="gx-5">
				<div className="info-page-header mb-10">
					<a
						href="#"
						className="back-btn flex-row-left fit mb-20"
						onClick={(e) => {
							e.preventDefault();
							navigate(-1);
						}}
					>
						<i className="ri-arrow-left-line opacity-7" />
						<span className="text-sm text-regular-inter upper opacity-7">
							Users
						</span>
					</a>
					<Row className="flex-row-left">
						<Col className="flex-row-left">
							<div className="contact-icon flex-column rounded-8">
								<span className="text-bold-gotham text-md">
									{user?.name[0]}
								</span>
							</div>
							<h1 className="text-lg mb-0 text-medium-gotham">
								{loading ? (
									<Skeleton
										width={250}
										height={25}
										baseColor="#dadee7"
										highlightColor="#e9ecf2"
									/>
								) : (
									user?.name || "--"
								)}
							</h1>
						</Col>
						<Col className="flex-row-right">
							{profile?.role?.users_edit || profile?.role?.users_delete ? (
								<Dropdown>
									<Dropdown.Toggle
										variant="light"
										className="info-control-btn text-lg rounded-4 flex-column no-arrow"
										disabled={loading}
									>
										<i className="ri-more-2-fill me-0" />
									</Dropdown.Toggle>
									<Dropdown.Menu className="text-sm dropdown-default">
										{profile?.role?.users_edit && (
											<>
												<Dropdown.Item
													href="#"
													onClick={(e) => {
														e.preventDefault();
														setEditModal(true);
													}}
												>
													Edit
												</Dropdown.Item>
												<Dropdown.Item
													href="#"
													className="text-danger"
													onClick={() => setPasswordModal(true)}
												>
													Reset Password
												</Dropdown.Item>
											</>
										)}
										{profile?.role?.users_delete && (
											<Dropdown.Item
												href="#"
												className="text-danger"
												onClick={() => setDeleteModal(true)}
											>
												Delete
											</Dropdown.Item>
										)}
									</Dropdown.Menu>
								</Dropdown>
							) : (
								""
							)}
						</Col>
					</Row>
				</div>
				<Nav variant="tabs" defaultActiveKey={tab} className="mb-20">
					<Nav.Item>
						<Nav.Link
							eventKey="details"
							className="info-tab-item"
							onClick={() => setTab("details")}
						>
							Details
						</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link
							eventKey="logs"
							className="info-tab-item"
							onClick={() => setTab("logs")}
						>
							Logs
						</Nav.Link>
					</Nav.Item>
				</Nav>
				{tab === "details" && <UserDetails loading={loading} user={user} />}
				{tab === "logs" && <InfoLogs loading={loading} data={user} />}
			</Container>
			<Modal
				show={editModal}
				className="info-modal"
				size="lg"
				centered
				onHide={closeEditModal}
			>
				<Form onSubmit={handleSubmit}>
					<Modal.Header>
						<Modal.Title className="text-lg">Edit User</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="mb-10">
							<h2 className="text-lg text-medium-gotham mb-0">
								User Information
							</h2>
						</div>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Name*</Form.Label>
								<Form.Control
									className={`text-sm form-input ${
										errors.includes("name") && "border-danger"
									}`}
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
							</Col>
							<Col>
								<Form.Label className="text-sm">Email*</Form.Label>
								<Form.Control
									className={`text-sm form-input ${
										errors.includes("email") && "border-danger"
									}`}
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</Col>
						</Row>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Role*</Form.Label>
								<Form.Select
									className={`text-sm form-input ${
										errors.includes("role") && "border-danger"
									}`}
									value={role}
									onChange={(e) => setRole(e.target.value)}
								>
									<option value=""></option>
									{roles?.map((userRole) => (
										<option value={userRole.id}>{userRole.name}</option>
									))}
								</Form.Select>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="outline-secondary"
							className="btn-sm action-btn text-sm me-2"
							onClick={closeEditModal}
						>
							Close
						</Button>
						<Button
							type="submit"
							variant="primary"
							className="btn-sm action-btn text-sm btn-default"
							disabled={actionLoading}
						>
							{actionLoading ? (
								<Spinner className="btn-loader text-white" />
							) : (
								"Save Changes"
							)}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal show={deleteModal} onHide={() => setDeleteModal(false)}>
				<Modal.Body>
					<h2 className="text-lg text-medium-inter mb-15">Delete User</h2>
					<p className="text-sm opacity-7">
						Are you sure you want to delete this user? This action cannot be
						undone.
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="light"
						className="modal-btn btn-sm dismiss-btn text-medium-inter text-sm"
						onClick={() => setDeleteModal(false)}
					>
						Cancel
					</Button>
					<Button
						variant="danger"
						className="action-btn btn-sm text-medium-inter text-sm flex-column"
						disabled={deleteLoading}
						onClick={deleteUser}
					>
						{deleteLoading ? (
							<Spinner className="btn-loader text-white" />
						) : (
							"Delete"
						)}
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal
				show={passwordModal}
				className="overlay-modal"
				backdropClassName="overlay-modal-backdrop"
				onHide={() => closePasswordModal()}
			>
				<Form onSubmit={resetPassword}>
					<Modal.Header>
						<Modal.Title className="text-lg">Reset Password</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Row className="mb-20">
							<Col>
								<Form.Label>Temporary Password</Form.Label>
								<p className="label-md">
									The user will be prompted to change their password upon log
									in.
								</p>
								<Form.Control
									required
									autoFocus
									type="password"
									className="form-input"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
								/>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="outline-secondary"
							className="modal-btn btn-sm dismiss-btn text-sm me-2"
							onClick={() => closePasswordModal()}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							className="btn-default btn-sm action-btn text-sm me-2 flex-row-center"
							disabled={actionLoading}
						>
							{actionLoading ? (
								<Spinner className="btn-loader text-white" />
							) : (
								"Reset Password"
							)}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
};

export default User;
