import { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { SessionContext } from "../context/SessionContext";
import { ProfileContext } from "../context/ProfileContext";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Skeleton from "react-loading-skeleton";
import Dropdown from "react-bootstrap/Dropdown";
import Nav from "react-bootstrap/Nav";
import toast from "react-hot-toast";
import ToastDisplay from "../components/ToastDisplay";
import InfoLogs from "../components/InfoLogs";
import RoleDetails from "../components/RoleDetails";

const Role = () => {
	let { id } = useParams();
	const navigate = useNavigate();
	const [session] = useContext(SessionContext);
	const [profile] = useContext(ProfileContext);
	const [loading, setLoading] = useState(true);
	const [role, setRole] = useState(null);
	const [editModal, setEditModal] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [actionLoading, setActionLoading] = useState(false);

	const [name, setName] = useState("");
	const [workOrdersView, setWorkOrdersView] = useState(false);
	const [workOrdersAdd, setWorkOrdersAdd] = useState(false);
	const [workOrdersEdit, setWorkOrdersEdit] = useState(false);
	const [workOrdersDelete, setWorkOrdersDelete] = useState(false);
	const [clientsView, setClientsView] = useState(false);
	const [clientsAdd, setClientsAdd] = useState(false);
	const [clientsEdit, setClientsEdit] = useState(false);
	const [clientsDelete, setClientsDelete] = useState(false);
	const [contractorsView, setContractorsView] = useState(false);
	const [contractorsAdd, setContractorsAdd] = useState(false);
	const [contractorsEdit, setContractorsEdit] = useState(false);
	const [contractorsDelete, setContractorsDelete] = useState(false);
	const [usersView, setUsersView] = useState(false);
	const [usersAdd, setUsersAdd] = useState(false);
	const [usersEdit, setUsersEdit] = useState(false);
	const [usersDelete, setUsersDelete] = useState(false);
	const [rolesView, setRolesView] = useState(false);
	const [rolesAdd, setRolesAdd] = useState(false);
	const [rolesEdit, setRolesEdit] = useState(false);
	const [rolesDelete, setRolesDelete] = useState(false);

	const [errors, setErrors] = useState([]);
	const [tab, setTab] = useState("details");

	const requiredFields = [{ key: "name", value: name }];

	const getData = async () => {
		setLoading(true);
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/roles/${id}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			setRole(data.role);
			setName(data.role.name);
			setWorkOrdersView(data.role.work_orders_view);
			setWorkOrdersAdd(data.role.work_orders_add);
			setWorkOrdersEdit(data.role.work_orders_edit);
			setWorkOrdersDelete(data.role.work_orders_delete);
			setClientsView(data.role.clients_view);
			setClientsAdd(data.role.clients_add);
			setClientsEdit(data.role.clients_edit);
			setClientsDelete(data.role.clients_delete);
			setContractorsView(data.role.contractors_view);
			setContractorsAdd(data.role.contractors_add);
			setContractorsEdit(data.role.contractors_edit);
			setContractorsDelete(data.role.contractors_delete);
			setUsersView(data.role.users_view);
			setUsersAdd(data.role.users_add);
			setUsersEdit(data.role.users_edit);
			setUsersDelete(data.role.users_delete);
			setRolesView(data.role.roles_view);
			setRolesAdd(data.role.roles_add);
			setRolesEdit(data.role.roles_edit);
			setRolesDelete(data.role.roles_delete);
		}
		setLoading(false);
	};

	const editRole = async () => {
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
			body: JSON.stringify({
				name: name,
				work_orders_view: workOrdersView,
				work_orders_add: workOrdersAdd,
				work_orders_edit: workOrdersEdit,
				work_orders_delete: workOrdersDelete,
				clients_view: clientsView,
				clients_add: clientsAdd,
				clients_edit: clientsEdit,
				clients_delete: clientsDelete,
				contractors_view: contractorsView,
				contractors_add: contractorsAdd,
				contractors_edit: contractorsEdit,
				contractors_delete: contractorsDelete,
				users_view: usersView,
				users_add: usersAdd,
				users_edit: usersEdit,
				users_delete: usersDelete,
				roles_view: rolesView,
				roles_add: rolesAdd,
				roles_edit: rolesEdit,
				roles_delete: rolesDelete,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/roles/${id}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			toast(<ToastDisplay status="error" content={data.msg} />);
		} else {
			toast(<ToastDisplay status="success" content={data.msg} />);
			getData();
			closeEditModal();
		}
		setActionLoading(false);
	};

	const deleteRole = async () => {
		setDeleteLoading(true);
		const requestOptions = {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/roles/${id}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			toast(<ToastDisplay status="error" content={data.msg} />);
		} else {
			toast(<ToastDisplay status="success" content={data.msg} />);
			navigate("/property/roles");
		}
		setDeleteModal(false);
		setDeleteLoading(false);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setActionLoading(true);
		var newErrors = [];

		requiredFields.map((field) => {
			if (!field.value || field.value === "" || field.value.length === 0) {
				newErrors.push(field.key);
			}
		});

		if (newErrors.length !== 0) {
			toast(
				<ToastDisplay
					status="error"
					content="Please fill out all required fields."
				/>
			);
			setActionLoading(false);
			setErrors(newErrors);
			return;
		}
		editRole();
	};

	const closeEditModal = () => {
		setEditModal(false);
		setName(role.name);
		setWorkOrdersView(role.work_orders_view);
		setWorkOrdersAdd(role.work_orders_add);
		setWorkOrdersEdit(role.work_orders_edit);
		setWorkOrdersDelete(role.work_orders_delete);

		setClientsView(role.clients_view);
		setClientsAdd(role.clients_add);
		setClientsEdit(role.clients_edit);
		setClientsDelete(role.clients_delete);

		setContractorsView(role.contractors_view);
		setContractorsAdd(role.contractors_add);
		setContractorsEdit(role.contractors_edit);
		setContractorsDelete(role.contractors_delete);

		setUsersView(role.users_view);
		setUsersAdd(role.users_add);
		setUsersEdit(role.users_edit);
		setUsersDelete(role.users_delete);
		setRolesView(role.roles_view);
		setRolesAdd(role.roles_add);
		setRolesEdit(role.roles_edit);
		setRolesDelete(role.roles_delete);
		setErrors([]);
	};

	const handleWorkOrdersView = () => {
		if (workOrdersView) {
			setWorkOrdersAdd(false);
			setWorkOrdersEdit(false);
			setWorkOrdersDelete(false);
		}
		setWorkOrdersView(!workOrdersView);
	};

	const handleClientsView = () => {
		if (clientsView) {
			setClientsAdd(false);
			setClientsEdit(false);
			setClientsDelete(false);
		}
		setClientsView(!clientsView);
	};

	const handleContractorsView = () => {
		if (contractorsView) {
			setContractorsAdd(false);
			setContractorsEdit(false);
			setContractorsDelete(false);
		}
		setContractorsView(!contractorsView);
	};

	const handleUsersView = () => {
		if (usersView) {
			setUsersAdd(false);
			setUsersEdit(false);
			setUsersDelete(false);
		}
		setUsersView(!usersView);
	};

	const handleRolesView = () => {
		if (rolesView) {
			setRolesAdd(false);
			setRolesEdit(false);
			setRolesDelete(false);
		}
		setRolesView(!rolesView);
	};

	useEffect(() => {
		if (profile) {
			if (!profile.role.roles_view) {
				navigate("/");
			} else {
				if (id) {
					getData();
				}
			}
		}
	}, [id, profile]);

	return (
		<>
			<Container className="gx-5">
				<div className="info-page-header mb-10">
					<a
						href="#"
						className="back-btn flex-row-left fit mb-20"
						onClick={(e) => {
							e.preventDefault();
							navigate(-1);
						}}
					>
						<i className="ri-arrow-left-line opacity-7" />
						<span className="text-sm text-regular-inter upper opacity-7">
							Roles
						</span>
					</a>
					<Row className="flex-row-left">
						<Col className="flex-row-left">
							<div className="contact-icon flex-column rounded-8">
								<span className="text-bold-gotham text-md">
									{role?.name[0]}
								</span>
							</div>
							<h1 className="text-lg mb-0 text-medium-gotham">
								{loading ? (
									<Skeleton
										width={250}
										height={25}
										baseColor="#dadee7"
										highlightColor="#e9ecf2"
									/>
								) : (
									role?.name || "--"
								)}
							</h1>
						</Col>
						<Col className="flex-row-right">
							{role?.name !== "Administrator" &&
								(profile?.role?.roles_edit || profile?.role?.roles_delete ? (
									<Dropdown>
										<Dropdown.Toggle
											variant="light"
											className="info-control-btn text-lg rounded-4 flex-column no-arrow"
											disabled={loading}
										>
											<i className="ri-more-2-fill me-0" />
										</Dropdown.Toggle>
										<Dropdown.Menu className="text-sm dropdown-default">
											{profile?.role?.roles_edit && (
												<Dropdown.Item
													href="#"
													onClick={(e) => {
														e.preventDefault();
														setEditModal(true);
													}}
												>
													Edit
												</Dropdown.Item>
											)}
											{profile?.role?.roles_delete && (
												<Dropdown.Item
													href="#"
													className="text-danger"
													onClick={() => setDeleteModal(true)}
												>
													Delete
												</Dropdown.Item>
											)}
										</Dropdown.Menu>
									</Dropdown>
								) : (
									""
								))}
						</Col>
					</Row>
				</div>
				<Nav variant="tabs" defaultActiveKey={tab} className="mb-20">
					<Nav.Item>
						<Nav.Link
							eventKey="details"
							className="info-tab-item"
							onClick={() => setTab("details")}
						>
							Details
						</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link
							eventKey="logs"
							className="info-tab-item"
							onClick={() => setTab("logs")}
						>
							Logs
						</Nav.Link>
					</Nav.Item>
				</Nav>
				{tab === "details" && <RoleDetails loading={loading} role={role} />}
				{tab === "logs" && <InfoLogs loading={loading} data={role} />}
			</Container>
			<Modal
				show={editModal}
				className="info-modal"
				size="lg"
				centered
				onHide={closeEditModal}
			>
				<Form onSubmit={handleSubmit}>
					<Modal.Header>
						<Modal.Title className="text-lg">Edit Role</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="mb-10">
							<h2 className="text-lg text-medium-gotham mb-0">
								Role Information
							</h2>
						</div>
						<Row className="mb-30">
							<Col>
								<Form.Label className="text-sm">Name*</Form.Label>
								<Form.Control
									autoFocus
									className={`text-sm form-input ${
										errors.includes("name") && "border-danger"
									}`}
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
							</Col>
						</Row>
						<div className="mb-10">
							<h2 className="text-lg text-medium-gotham mb-0">Permissions</h2>
						</div>
						<div className="border rounded-8 p-20 mb-20">
							<div className="mb-20">
								<span className="text-medium-gotham">Work Orders</span>
							</div>
							<Row>
								<Col className="flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={workOrdersView}
										id="workOrdersView"
										onChange={handleWorkOrdersView}
									/>
									<label htmlFor="workOrdersView" className="lbl m-0" />
									<span className="text-sm ms-3">View</span>
								</Col>
								<Col className="flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={workOrdersAdd}
										id="workOrdersAdd"
										onChange={() => {
											setWorkOrdersAdd(!workOrdersAdd);
											setWorkOrdersView(true);
										}}
									/>
									<label htmlFor="workOrdersAdd" className="lbl m-0" />
									<span className="text-sm ms-3">Add</span>
								</Col>
								<Col className="flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={workOrdersEdit}
										id="workOrdersEdit"
										onChange={() => {
											setWorkOrdersEdit(!workOrdersEdit);
											setWorkOrdersView(true);
										}}
									/>
									<label htmlFor="workOrdersEdit" className="lbl m-0" />
									<span className="text-sm ms-3">Edit</span>
								</Col>
								<Col className="flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={workOrdersDelete}
										id="workOrdersDelete"
										onChange={() => {
											setWorkOrdersDelete(!workOrdersDelete);
											setWorkOrdersView(true);
										}}
									/>
									<label htmlFor="workOrdersDelete" className="lbl m-0" />
									<span className="text-sm ms-3">Delete</span>
								</Col>
							</Row>
						</div>
						<div className="border rounded-8 p-20 mb-20">
							<div className="mb-20">
								<span className="text-medium-gotham">Clients</span>
							</div>
							<Row>
								<Col className="flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={clientsView}
										id="clientsView"
										onChange={handleClientsView}
									/>
									<label htmlFor="clientsView" className="lbl m-0" />
									<span className="text-sm ms-3">View</span>
								</Col>
								<Col className="flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={clientsAdd}
										id="clientsAdd"
										onChange={() => {
											setClientsAdd(!clientsAdd);
											setClientsView(true);
										}}
									/>
									<label htmlFor="clientsAdd" className="lbl m-0" />
									<span className="text-sm ms-3">Add</span>
								</Col>
								<Col className="flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={clientsEdit}
										id="clientsEdit"
										onChange={() => {
											setClientsEdit(!clientsEdit);
											setClientsView(true);
										}}
									/>
									<label htmlFor="clientsEdit" className="lbl m-0" />
									<span className="text-sm ms-3">Edit</span>
								</Col>
								<Col className="flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={clientsDelete}
										id="clientsDelete"
										onChange={() => {
											setClientsDelete(!clientsDelete);
											setClientsView(true);
										}}
									/>
									<label htmlFor="clientsDelete" className="lbl m-0" />
									<span className="text-sm ms-3">Delete</span>
								</Col>
							</Row>
						</div>
						<div className="border rounded-8 p-20 mb-20">
							<div className="mb-20">
								<span className="text-medium-gotham">Contractors</span>
							</div>
							<Row>
								<Col className="flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={contractorsView}
										id="contractorsView"
										onChange={handleContractorsView}
									/>
									<label htmlFor="contractorsView" className="lbl m-0" />
									<span className="text-sm ms-3">View</span>
								</Col>
								<Col className="flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={contractorsAdd}
										id="contractorsAdd"
										onChange={() => {
											setContractorsAdd(!contractorsAdd);
											setContractorsView(true);
										}}
									/>
									<label htmlFor="contractorsAdd" className="lbl m-0" />
									<span className="text-sm ms-3">Add</span>
								</Col>
								<Col className="flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={contractorsEdit}
										id="contractorsEdit"
										onChange={() => {
											setContractorsEdit(!contractorsEdit);
											setContractorsView(true);
										}}
									/>
									<label htmlFor="contractorsEdit" className="lbl m-0" />
									<span className="text-sm ms-3">Edit</span>
								</Col>
								<Col className="flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={contractorsDelete}
										id="contractorsDelete"
										onChange={() => {
											setContractorsDelete(!contractorsDelete);
											setContractorsView(true);
										}}
									/>
									<label htmlFor="contractorsDelete" className="lbl m-0" />
									<span className="text-sm ms-3">Delete</span>
								</Col>
							</Row>
						</div>
						<div className="border rounded-8 p-20 mb-20">
							<div className="mb-20">
								<span className="text-medium-gotham">Users</span>
							</div>
							<Row>
								<Col className="flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={usersView}
										id="usersView"
										onChange={handleUsersView}
									/>
									<label htmlFor="usersView" className="lbl m-0" />
									<span className="text-sm ms-3">View</span>
								</Col>
								<Col className="flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={usersAdd}
										id="usersAdd"
										onChange={() => {
											setUsersAdd(!usersAdd);
											setUsersView(true);
										}}
									/>
									<label htmlFor="usersAdd" className="lbl m-0" />
									<span className="text-sm ms-3">Add</span>
								</Col>
								<Col className="flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={usersEdit}
										id="usersEdit"
										onChange={() => {
											setUsersEdit(!usersEdit);
											setUsersView(true);
										}}
									/>
									<label htmlFor="usersEdit" className="lbl m-0" />
									<span className="text-sm ms-3">Edit</span>
								</Col>
								<Col className="flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={usersDelete}
										id="usersDelete"
										onChange={() => {
											setUsersDelete(!usersDelete);
											setUsersView(true);
										}}
									/>
									<label htmlFor="usersDelete" className="lbl m-0" />
									<span className="text-sm ms-3">Delete</span>
								</Col>
							</Row>
						</div>
						<div className="border rounded-8 p-20 mb-20">
							<div className="mb-20">
								<span className="text-medium-gotham">Roles & Permissions</span>
							</div>
							<Row>
								<Col className="flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={rolesView}
										id="rolesView"
										onChange={handleRolesView}
									/>
									<label htmlFor="rolesView" className="lbl m-0" />
									<span className="text-sm ms-3">View</span>
								</Col>
								<Col className="flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={rolesAdd}
										id="rolesAdd"
										onChange={() => {
											setRolesAdd(!rolesAdd);
											setRolesView(true);
										}}
									/>
									<label htmlFor="rolesAdd" className="lbl m-0" />
									<span className="text-sm ms-3">Add</span>
								</Col>
								<Col className="flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={rolesEdit}
										id="rolesEdit"
										onChange={() => {
											setRolesEdit(!rolesEdit);
											setRolesView(true);
										}}
									/>
									<label htmlFor="rolesEdit" className="lbl m-0" />
									<span className="text-sm ms-3">Edit</span>
								</Col>
								<Col className="flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={rolesDelete}
										id="rolesDelete"
										onChange={() => {
											setRolesDelete(!rolesDelete);
											setRolesView(true);
										}}
									/>
									<label htmlFor="rolesDelete" className="lbl m-0" />
									<span className="text-sm ms-3">Delete</span>
								</Col>
							</Row>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="outline-secondary"
							className="btn-sm action-btn text-sm me-2"
							onClick={closeEditModal}
						>
							Close
						</Button>
						<Button
							type="submit"
							variant="primary"
							className="btn-sm action-btn text-sm btn-default"
							disabled={actionLoading}
						>
							{actionLoading ? (
								<Spinner className="btn-loader text-white" />
							) : (
								"Save Changes"
							)}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal show={deleteModal} onHide={() => setDeleteModal(false)}>
				<Modal.Body>
					<h2 className="text-lg text-medium-inter mb-15">Delete Role</h2>
					<p className="text-sm opacity-7">
						Are you sure you want to delete this role? This action cannot be
						undone.
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="light"
						className="modal-btn btn-sm dismiss-btn text-medium-inter text-sm"
						onClick={() => setDeleteModal(false)}
					>
						Cancel
					</Button>
					<Button
						variant="danger"
						className="action-btn btn-sm text-medium-inter text-sm flex-column"
						disabled={deleteLoading}
						onClick={deleteRole}
					>
						{deleteLoading ? (
							<Spinner className="btn-loader text-white" />
						) : (
							"Delete"
						)}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default Role;
