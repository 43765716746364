import { useState, useContext } from "react";
import { SessionContext } from "../context/SessionContext";
import toast from "react-hot-toast";
import ToastDisplay from "../components/ToastDisplay";
import Skeleton from "react-loading-skeleton";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";

const ClientDetails = ({ loading, client, getData }) => {
	const [session] = useContext(SessionContext);
	const [actionLoading, setActionLoading] = useState(false);
	const [approveModal, setApproveModal] = useState(false);

	const approveAccount = async () => {
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/clients/c/${client?.id}/approve`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			toast(<ToastDisplay status="error" content={data.msg} />);
		} else {
			toast(<ToastDisplay status="success" content={data.msg} />);
			getData();
		}
		setApproveModal(false);
		setActionLoading(false);
	};

	return (
		<div>
			<div className="bg-white rounded-8 p-20 mb-30">
				<div className="mb-20">
					<h2 className="text-xl text-medium-gotham mb-0">Staff Information</h2>
				</div>
				<Row className="mb-30">
					<Col>
						<span className="info-label text-sm text-medium-gotham block">
							{loading ? (
								<Skeleton
									width={120}
									height={15}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								"Prefix"
							)}
						</span>
						<span className="info-val">
							{loading ? (
								<Skeleton
									width={"80%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								client?.prefix || "--"
							)}
						</span>
					</Col>
				</Row>
				<Row className="mb-30">
					<Col>
						<span className="info-label text-sm text-medium-gotham block">
							{loading ? (
								<Skeleton
									width={120}
									height={15}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								"First Name"
							)}
						</span>
						<span className="info-val">
							{loading ? (
								<Skeleton
									width={"80%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								client?.first_name || "--"
							)}
						</span>
					</Col>
					<Col>
						<span className="info-label text-sm text-medium-gotham block">
							{loading ? (
								<Skeleton
									width={120}
									height={15}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								"Middle Name"
							)}
						</span>
						<span className="info-val">
							{loading ? (
								<Skeleton
									width={"80%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								client?.middle_name || "--"
							)}
						</span>
					</Col>
					<Col>
						<span className="info-label text-sm text-medium-gotham block">
							{loading ? (
								<Skeleton
									width={120}
									height={15}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								"Last Name"
							)}
						</span>
						<span className="info-val">
							{loading ? (
								<Skeleton
									width={"80%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								client?.last_name || "--"
							)}
						</span>
					</Col>
				</Row>
				<Row className="mb-30">
					<Col>
						<span className="info-label text-sm text-medium-gotham block">
							{loading ? (
								<Skeleton
									width={120}
									height={15}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								"Suffix"
							)}
						</span>
						<span className="info-val">
							{loading ? (
								<Skeleton
									width={"80%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								client?.suffix || "--"
							)}
						</span>
					</Col>
					<Col>
						<span className="info-label text-sm text-medium-gotham block">
							{loading ? (
								<Skeleton
									width={120}
									height={15}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								"Gender"
							)}
						</span>
						<span className="info-val">
							{loading ? (
								<Skeleton
									width={"80%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								client?.gender || "--"
							)}
						</span>
					</Col>
					<Col>
						<span className="info-label text-sm text-medium-gotham block">
							{loading ? (
								<Skeleton
									width={120}
									height={15}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								"Agency"
							)}
						</span>
						<span className="info-val">
							{loading ? (
								<Skeleton
									width={"80%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								client?.agency || "--"
							)}
						</span>
					</Col>
				</Row>
				<Row className="mb-30">
					<Col>
						<span className="info-label text-sm text-medium-gotham block">
							{loading ? (
								<Skeleton
									width={120}
									height={15}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								"Position"
							)}
						</span>
						<span className="info-val">
							{loading ? (
								<Skeleton
									width={"80%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								client?.position || "--"
							)}
						</span>
					</Col>
				</Row>
			</div>
			<div className="bg-white rounded-8 p-20 mb-30">
				<div className="mb-20">
					<h2 className="text-xl text-medium-gotham mb-0">
						Account Information
					</h2>
				</div>
				<Row className="mb-30">
					<Col>
						<span className="info-label text-sm text-medium-gotham block">
							{loading ? (
								<Skeleton
									width={70}
									height={15}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								"Email"
							)}
						</span>
						<span className="info-val">
							{loading ? (
								<Skeleton
									width={"80%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								client?.email || "--"
							)}
						</span>
					</Col>
				</Row>
			</div>
			<div className="bg-white rounded-8 p-20 mb-30">
				<div className="mb-20">
					<h2 className="text-xl text-medium-gotham mb-0">
						Contact Information
					</h2>
				</div>
				<Row className="mb-30">
					<Col>
						<span className="info-label text-sm text-medium-gotham block">
							{loading ? (
								<Skeleton
									width={120}
									height={15}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								"Address"
							)}
						</span>
						<span className="info-val">
							{loading ? (
								<Skeleton
									width={"80%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								client?.address || "--"
							)}
						</span>
					</Col>
					<Col>
						<span className="info-label text-sm text-medium-gotham block">
							{loading ? (
								<Skeleton
									width={120}
									height={15}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								"Village/Apartment"
							)}
						</span>
						<span className="info-val">
							{loading ? (
								<Skeleton
									width={"80%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								client?.village || "--"
							)}
						</span>
					</Col>
					<Col>
						<span className="info-label text-sm text-medium-gotham block">
							{loading ? (
								<Skeleton
									width={120}
									height={15}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								"Home Phone #"
							)}
						</span>
						<span className="info-val">
							{loading ? (
								<Skeleton
									width={"80%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								client?.home_phone || "--"
							)}
						</span>
					</Col>
				</Row>
				<Row className="mb-30">
					<Col>
						<span className="info-label text-sm text-medium-gotham block">
							{loading ? (
								<Skeleton
									width={70}
									height={15}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								"Work Phone #"
							)}
						</span>
						<span className="info-val">
							{loading ? (
								<Skeleton
									width={"80%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								client?.work_phone || "--"
							)}
						</span>
					</Col>
					<Col>
						<span className="info-label text-sm text-medium-gotham block">
							{loading ? (
								<Skeleton
									width={70}
									height={15}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								"Local Ext."
							)}
						</span>
						<span className="info-val">
							{loading ? (
								<Skeleton
									width={"80%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								client?.local_ext || "--"
							)}
						</span>
					</Col>
					<Col>
						<span className="info-label text-sm text-medium-gotham block">
							{loading ? (
								<Skeleton
									width={70}
									height={15}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								"Fax"
							)}
						</span>
						<span className="info-val">
							{loading ? (
								<Skeleton
									width={"80%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								client?.fax || "--"
							)}
						</span>
					</Col>
				</Row>
				<Row className="mb-30">
					<Col>
						<span className="info-label text-sm text-medium-gotham block">
							{loading ? (
								<Skeleton
									width={120}
									height={15}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								"Mobile #"
							)}
						</span>
						<span className="info-val">
							{loading ? (
								<Skeleton
									width={"80%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								client?.msisdn || "--"
							)}
						</span>
					</Col>
				</Row>
			</div>
			<div className="bg-white rounded-8 p-20 mb-30">
				<div className="mb-20">
					<h2 className="text-xl text-medium-gotham mb-0">Miscellaneous</h2>
				</div>
				<Row className="mb-30">
					<Col>
						<span className="info-label text-sm text-medium-gotham block">
							{loading ? (
								<Skeleton
									width={80}
									height={15}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								"Remarks"
							)}
						</span>
						<span className="info-val">
							{loading ? (
								<Skeleton
									width={"39%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								client?.remarks || "--"
							)}
						</span>
					</Col>
				</Row>
			</div>
			{client?.status === "Pending" && (
				<div className="flex-row-right">
					<Button
						className="btn-default btn-md text-sm rounded-4 text-medium-inter"
						onClick={() => setApproveModal(true)}
					>
						Approve Account
					</Button>
				</div>
			)}
			<Modal show={approveModal} onHide={() => setApproveModal(false)} centered>
				<Modal.Body>
					<h2 className="text-lg text-medium-inter mb-15">Approve Account</h2>
					<p className="text-sm opacity-7">
						Are you sure you want to approve this account?
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="light"
						className="modal-btn btn-sm dismiss-btn text-medium-inter text-sm"
						onClick={() => setApproveModal(false)}
					>
						Cancel
					</Button>
					<Button
						className="action-btn btn-default btn-sm text-medium-inter text-sm flex-column"
						disabled={actionLoading}
						onClick={approveAccount}
					>
						{actionLoading ? (
							<Spinner className="btn-loader text-white" />
						) : (
							"Approve"
						)}
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default ClientDetails;
