import format from "date-fns/format";
import Skeleton from "react-loading-skeleton";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const InfoLogs = ({ data }) => {
	return (
		<div className="bg-white rounded-8 p-20 mb-30">
			<div className="mb-10">
				<h2 className="text-xl text-medium-gotham mb-0">Logs</h2>
			</div>
			<div className="contact-logs">
				{data?.logs?.map((log) => (
					<Row className="info-log rounded-4">
						<Col className="col-8">
							<span className="text-sm">{log.content}</span>
						</Col>
						<Col className="col-4 align-right">
							<span className="text-sm">
								{format(new Date(log.created_at), "MMM dd, yyyy - hh:mm a")}
							</span>
						</Col>
					</Row>
				)) || (
					<Skeleton
						width={"39%"}
						height={25}
						baseColor="#dadee7"
						highlightColor="#e9ecf2"
					/>
				)}
			</div>
		</div>
	);
};

export default InfoLogs;
