import { format } from "date-fns";
import { useState } from "react";
import WoAttachment from "./WoAttachment";

const ClientWorkOrderUpdate = ({ update }) => {
	const [isActive, setIsActive] = useState(false);

	return (
		<div className="order-item bg-white border rounded-8 mb-15">
			<a
				href="#"
				className="order-item-toggle full flex-row-left"
				onClick={(e) => {
					e.preventDefault();
					setIsActive(!isActive);
				}}
			>
				<div>
					<div className="wo-update-title flex-row-left">
						<span className="text-medium-gotham text-sm block">
							{update?.title || "--"}
						</span>
					</div>
					<span className="label-md opacity-7 block">
						{update?.user?.name} |{" "}
						{format(new Date(update.created_at), "MMM dd, yyyy hh:mm a")}
					</span>
				</div>
				<div
					className={`order-icon flex-row-left ${(isActive && "active") || ""}`}
				>
					<i className="ri-arrow-down-s-line opacity-5" />
				</div>
			</a>
			<div
				className={`order-info-container full ${(isActive && "active") || ""}`}
			>
				<p
					className={`text-sm ${
						update?.attachments?.length !== 0 ? "mb-20" : "mb-0"
					}`}
				>
					{update?.content}
				</p>
				{update?.attachments?.length !== 0 && (
					<div className="order-files-container">
						{update?.attachments?.map((attachment) => (
							<WoAttachment key={attachment.id} attachment={attachment} />
						))}
					</div>
				)}
			</div>
		</div>
	);
};

export default ClientWorkOrderUpdate;
