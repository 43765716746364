import format from "date-fns/format";
import differenceInDays from "date-fns/differenceInDays";
import Skeleton from "react-loading-skeleton";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const WorkOrderProperty = ({ workOrder, loading }) => {
	return (
		<div className="bg-white rounded-8 p-20 mb-30">
			<div className="mb-20">
				<h2 className="text-xl text-medium-gotham mb-0">
					Property Information
				</h2>
			</div>
			<Row className="mb-30">
				<Col>
					<span className="info-label text-sm text-medium-gotham block">
						{loading ? (
							<Skeleton
								width={120}
								height={15}
								baseColor="#dadee7"
								highlightColor="#e9ecf2"
							/>
						) : (
							"Received Date"
						)}
					</span>
					<span className="info-val">
						{loading ? (
							<Skeleton
								width={"80%"}
								height={25}
								baseColor="#dadee7"
								highlightColor="#e9ecf2"
							/>
						) : workOrder.received ? (
							format(new Date(workOrder.received), "MMM dd, yyyy")
						) : (
							"--"
						)}
					</span>
				</Col>
				<Col>
					<span className="info-label text-sm text-medium-gotham block">
						{loading ? (
							<Skeleton
								width={120}
								height={15}
								baseColor="#dadee7"
								highlightColor="#e9ecf2"
							/>
						) : (
							"Received Time"
						)}
					</span>
					<span className="info-val">
						{loading ? (
							<Skeleton
								width={"80%"}
								height={25}
								baseColor="#dadee7"
								highlightColor="#e9ecf2"
							/>
						) : workOrder.received ? (
							format(new Date(workOrder.received), "hh:mm a")
						) : (
							"--"
						)}
					</span>
				</Col>
				<Col>
					<span className="info-label text-sm text-medium-gotham block">
						{loading ? (
							<Skeleton
								width={120}
								height={15}
								baseColor="#dadee7"
								highlightColor="#e9ecf2"
							/>
						) : (
							"Received By"
						)}
					</span>
					<span className="info-val">
						{loading ? (
							<Skeleton
								width={"80%"}
								height={25}
								baseColor="#dadee7"
								highlightColor="#e9ecf2"
							/>
						) : (
							workOrder?.received_by?.name || "--"
						)}
					</span>
				</Col>
			</Row>
			<Row className="mb-30">
				<Col>
					<span className="info-label text-sm text-medium-gotham block">
						{loading ? (
							<Skeleton
								width={120}
								height={15}
								baseColor="#dadee7"
								highlightColor="#e9ecf2"
							/>
						) : (
							"Assigned To"
						)}
					</span>
					<span className="info-val">
						{loading ? (
							<Skeleton
								width={"80%"}
								height={25}
								baseColor="#dadee7"
								highlightColor="#e9ecf2"
							/>
						) : (
							workOrder?.assigned_to?.name || "--"
						)}
					</span>
				</Col>
				<Col>
					<span className="info-label text-sm text-medium-gotham block">
						{loading ? (
							<Skeleton
								width={120}
								height={15}
								baseColor="#dadee7"
								highlightColor="#e9ecf2"
							/>
						) : (
							"Priority"
						)}
					</span>
					<span className="info-val">
						{loading ? (
							<Skeleton
								width={"80%"}
								height={25}
								baseColor="#dadee7"
								highlightColor="#e9ecf2"
							/>
						) : (
							workOrder?.priority || "--"
						)}
					</span>
				</Col>
				<Col>
					<span className="info-label text-sm text-medium-gotham block">
						{loading ? (
							<Skeleton
								width={120}
								height={15}
								baseColor="#dadee7"
								highlightColor="#e9ecf2"
							/>
						) : (
							"Due Date"
						)}
					</span>
					<span className="info-val">
						{loading ? (
							<Skeleton
								width={"80%"}
								height={25}
								baseColor="#dadee7"
								highlightColor="#e9ecf2"
							/>
						) : workOrder.due ? (
							format(new Date(workOrder.due), "MMM dd, yyyy")
						) : (
							"--"
						)}
					</span>
				</Col>
			</Row>
			<Row className="mb-30">
				<Col>
					<span className="info-label text-sm text-medium-gotham block">
						{loading ? (
							<Skeleton
								width={120}
								height={15}
								baseColor="#dadee7"
								highlightColor="#e9ecf2"
							/>
						) : (
							"Completion Date"
						)}
					</span>
					<span className="info-val">
						{loading ? (
							<Skeleton
								width={"80%"}
								height={25}
								baseColor="#dadee7"
								highlightColor="#e9ecf2"
							/>
						) : workOrder.completion ? (
							format(new Date(workOrder.completion), "MMM dd, yyyy")
						) : (
							"--"
						)}
					</span>
				</Col>
				<Col>
					<span className="info-label text-sm text-medium-gotham block">
						{loading ? (
							<Skeleton
								width={120}
								height={15}
								baseColor="#dadee7"
								highlightColor="#e9ecf2"
							/>
						) : (
							"Days Worked"
						)}
					</span>
					<span className="info-val">
						{loading ? (
							<Skeleton
								width={"80%"}
								height={25}
								baseColor="#dadee7"
								highlightColor="#e9ecf2"
							/>
						) : workOrder.received && workOrder.completion ? (
							differenceInDays(
								new Date(workOrder.received),
								new Date(workOrder.completion)
							)
						) : (
							"--"
						)}
					</span>
				</Col>
				<Col />
			</Row>
		</div>
	);
};

export default WorkOrderProperty;
