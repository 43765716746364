import React, { useEffect, useContext, useState } from "react";
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import { SessionContext } from "../context/SessionContext";
import { ProfileContext } from "../context/ProfileContext";
import toast from "react-hot-toast";
import ToastDisplay from "../components/ToastDisplay";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import InputGroup from "react-bootstrap/InputGroup";
import Pagination from "../components/Pagination";
import Spinner from "react-bootstrap/esm/Spinner";
import ContentLoader from "../components/ContentLoader";

const Items = () => {
	document.title = "Inventory Items | Repairs & Maintenance Management System";
	const navigate = useNavigate();
	const [session] = useContext(SessionContext);
	const [profile] = useContext(ProfileContext);
	const [items, setItems] = useState([]);
	const [meta, setMeta] = useState(null);
	const [loading, setLoading] = useState(false);
	const [generating, setGenerating] = useState(false);
	const [actionLoading, setActionLoading] = useState(false);
	const [itemModal, setItemModal] = useState(false);
	const [filterModal, setFilterModal] = useState(false);
	const [showFilters, setShowFilters] = useState(false);
	const [errors, setErrors] = useState([]);

	const [name, setName] = useState("");
	const [barcode, setBarcode] = useState("");
	const [sapAssetNo, setSapAssetNo] = useState("");
	const [description, setDescription] = useState("");
	const [agency, setAgency] = useState("");
	const [location, setLocation] = useState("");
	const [area, setArea] = useState("");

	const [searchParams, setSearchParams] = useSearchParams({});
	const [searchQ, setSearchQ] = useState(searchParams.get("q") || "");
	const [searchAgency, setSearchAgency] = useState(
		searchParams.get("agency") || ""
	);
	const [searchLocation, setSearchLocation] = useState(
		searchParams.get("location") || ""
	);
	const [searchArea, setSearchArea] = useState(searchParams.get("area") || "");

	const searchFields = [
		{ key: "q", value: searchQ },
		{ key: "agency", value: searchAgency },
		{ key: "location", value: searchLocation },
		{ key: "area", value: searchArea },
	];

	const filterFields = [
		{
			key: "agency",
			value: searchAgency,
			defaultVal: "",
			edit: setSearchAgency,
		},
		{
			key: "location",
			value: searchLocation,
			defaultVal: "",
			edit: setSearchLocation,
		},
		{
			key: "area",
			value: searchArea,
			defaultVal: "",
			edit: setSearchArea,
		},
	];

	const requiredFields = [{ key: "name", value: name }];

	const getData = async () => {
		setLoading(true);
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/inventory/${window.location.search}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			setItems(data.data);
			setMeta(data.meta);
		}
		setLoading(false);
	};

	const generateCode = async () => {
		setGenerating(true);
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/inventory/code`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			setBarcode(data.code);
		}
		setGenerating(false);
	};

	const saveItem = async () => {
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
			body: JSON.stringify({
				name: name,
				barcode: barcode,
				sap_asset_no: sapAssetNo,
				description: description,
				agency: agency,
				location: location,
				area: area,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/inventory/`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			toast(<ToastDisplay status="error" content={data.msg} />);
		} else {
			toast(<ToastDisplay status="success" content={data.msg} />);
			closeItemModal();
			navigate(`/property/inventory/items/${data.item_id}`);
		}
		setActionLoading(false);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setActionLoading(true);
		var newErrors = [];

		requiredFields.map((field) => {
			if (!field.value || field.value === "" || field.value.length === 0) {
				newErrors.push(field.key);
			}
		});

		if (newErrors.length !== 0) {
			toast(
				<ToastDisplay
					status="error"
					content="Please fill out all required fields."
				/>
			);
			setActionLoading(false);
			setErrors(newErrors);
			return;
		}
		saveItem();
	};

	const search = (e) => {
		e.preventDefault();
		var newSearch = { ...searchParams };
		searchFields.map((field) => {
			if (field.value !== "") {
				newSearch[field.key] = field.value;
			} else {
				delete newSearch[field.key];
			}
		});
		setSearchParams(newSearch);
		setFilterModal(false);
	};

	const removeFilter = (e, func, key) => {
		e.preventDefault();
		func("");
		var newSearch = { ...searchParams };
		searchFields.map((field) => {
			if (field.value !== "" && field.key !== key) {
				newSearch[field.key] = field.value;
			} else {
				delete newSearch[field.key];
			}
		});
		setSearchParams(newSearch);
	};

	const clearFilters = (e) => {
		e.preventDefault();
		setSearchParams({});
		filterFields.map((field) => {
			field.edit(field.defaultVal);
		});
	};

	const closeItemModal = () => {
		setItemModal(false);
		setName("");
		setBarcode("");
		setSapAssetNo("");
		setDescription("");
		setAgency("");
		setLocation("");
		setArea("");
		setErrors([]);
	};

	const isEmpty = (value) => {
		return value === "" || value === null || value === undefined;
	};

	useEffect(() => {
		if (profile) {
			if (!profile.role.items_view) {
				navigate("/");
			} else {
				getData();
				if (filterFields.every((field) => isEmpty(field.value))) {
					setShowFilters(false);
				} else {
					setShowFilters(true);
				}
			}
		}
	}, [searchParams, profile]);

	return (
		<>
			<Container className="gx-5">
				<Row className="content-header flex-row-left mb-30">
					<Col>
						<h1 className="text-xl m-0">Inventory Items</h1>
					</Col>
				</Row>
				<Row className="flex-row-left mb-20">
					<Col className="col-4">
						<Form onSubmit={search}>
							<div className="search-container bg-white flex-row-left">
								<i className="ri-search-line search-icon" />
								<Form.Control
									className="text-sm"
									placeholder="Search items"
									value={searchQ}
									onChange={(e) => setSearchQ(e.target.value)}
								/>
							</div>
						</Form>
					</Col>
					<Col className="col-8 flex-row-right">
						<Pagination
							meta={meta}
							searchParams={searchParams}
							setSearchParams={setSearchParams}
							searchFields={searchFields}
							className="pull-right flex-row-left"
						/>
						<Button
							variant="light"
							className="refresh-btn flex-column"
							onClick={getData}
						>
							<i className="ri-restart-line opacity-7" />
						</Button>
						<Button
							className="control-btn btn-sm btn-default-outlined text-medium-gotham text-sm"
							onClick={() => setFilterModal(true)}
						>
							<i className="ri-filter-line" />
							Filters
						</Button>
						{profile?.role?.items_add && (
							<Button
								className="control-btn btn-sm btn-default text-medium-gotham text-sm"
								onClick={() => setItemModal(true)}
							>
								Add Item
							</Button>
						)}
					</Col>
				</Row>
				{showFilters ? (
					<div className="filters-container">
						<span className="filters-label text-medium-mont">Filters:</span>
						{filterFields.map((field) => {
							const { key, value } = field;
							if (value) {
								return (
									<div
										key={key}
										className="filter-item bg-default flex-row-left rounded-4"
									>
										<span className="text-white label-md mb-0">
											{key.charAt(0).toUpperCase() + key.slice(1)}: {value}
										</span>
										<a
											href="#"
											className="remove-filter"
											onClick={(e) => removeFilter(e, field.edit, key)}
										>
											<i className="ri-close-line text-white" />
										</a>
									</div>
								);
							} else {
								return "";
							}
						})}
						<div className="filter-item rounded-4 fit">
							<a
								href="#"
								className="text-sm text-medium-mont text-default"
								onClick={clearFilters}
							>
								Clear Filters
							</a>
						</div>
					</div>
				) : (
					""
				)}
				<Row className="content-table-header bg-white gx-0">
					<Col className="p-10">
						<span className="text-sm text-medium-gotham block">Name</span>
					</Col>
					<Col className="p-10">
						<span className="text-sm text-medium-gotham block">Barcode</span>
					</Col>
					<Col className="p-10">
						<span className="text-sm text-medium-gotham block">Agency</span>
					</Col>
					<Col className="p-10">
						<span className="text-sm text-medium-gotham block">Location</span>
					</Col>
				</Row>
				{loading ? (
					<ContentLoader />
				) : (
					items?.map((item) => (
						<Link key={item.id} to={`/property/inventory/items/${item.id}`}>
							<Row className="content-table-item bg-white gx-0">
								<Col className="p-10 ellipsis">
									<span className="text-sm">{item.name}</span>
								</Col>
								<Col className="p-10 ellipsis">
									<span className="text-sm">{item.barcode || "--"}</span>
								</Col>
								<Col className="p-10 ellipsis">
									<span className="email-preview text-sm ellipsis">
										{item.agency || "--"}
									</span>
								</Col>
								<Col className="p-10 ellipsis">
									<span className="email-preview text-sm ellipsis">
										{item.location || "--"}
									</span>
								</Col>
							</Row>
						</Link>
					))
				)}
				<Row className="mt-4 mb-20">
					<Col className="flex-row-right">
						<Pagination
							meta={meta}
							searchParams={searchParams}
							setSearchParams={setSearchParams}
							searchFields={searchFields}
							className="pull-right flex-row-left pagination-bottom"
						/>
					</Col>
				</Row>
			</Container>
			<Modal
				show={filterModal}
				centered
				onHide={() => setFilterModal(false)}
				className="filter-modal"
			>
				<Form onSubmit={search}>
					<Modal.Header>
						<Modal.Title className="text-lg">Filters</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Agency</Form.Label>
								<Form.Control
									className="text-sm form-input"
									value={searchAgency}
									onChange={(e) => setSearchAgency(e.target.value)}
								/>
							</Col>
						</Row>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Location</Form.Label>
								<Form.Control
									className="text-sm form-input"
									value={searchLocation}
									onChange={(e) => setSearchLocation(e.target.value)}
								/>
							</Col>
						</Row>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Area</Form.Label>
								<Form.Control
									className="text-sm form-input"
									value={searchArea}
									onChange={(e) => setSearchArea(e.target.value)}
								/>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="outline-secondary"
							className="btn-sm action-btn text-sm me-2"
							onClick={() => setFilterModal(false)}
						>
							Close
						</Button>
						<Button
							type="submit"
							variant="primary"
							className="btn-sm action-btn text-sm btn-default"
						>
							Apply
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal
				show={itemModal}
				className="info-modal"
				centered
				size="lg"
				onHide={closeItemModal}
			>
				<Form onSubmit={handleSubmit}>
					<Modal.Header>
						<Modal.Title className="text-lg">New Item</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="mb-10">
							<h2 className="text-lg text-medium-gotham mb-0">
								Item Information
							</h2>
						</div>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Name</Form.Label>
								<Form.Control
									autoFocus
									className={`text-sm form-input ${
										errors.includes("name") && "border-danger"
									}`}
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
							</Col>
						</Row>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Barcode</Form.Label>
								<InputGroup>
									<Form.Control
										className={`text-sm form-input ${
											errors.includes("barcode") && "border-danger"
										}`}
										value={barcode}
										onChange={(e) => setBarcode(e.target.value)}
									/>
									<Button
										className="btn-default-outlined text-sm flex-column code-btn"
										onClick={generateCode}
										disabled={generating}
									>
										{generating ? (
											<Spinner size="sm" />
										) : (
											<i className="ri-refresh-line" />
										)}
									</Button>
								</InputGroup>
							</Col>
							<Col>
								<Form.Label className="text-sm">SAP Asset No.</Form.Label>
								<Form.Control
									className={`text-sm form-input ${
										errors.includes("sapAssetNo") && "border-danger"
									}`}
									value={sapAssetNo}
									onChange={(e) => setSapAssetNo(e.target.value)}
								/>
							</Col>
						</Row>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Description</Form.Label>
								<Form.Control
									as="textarea"
									className={`text-sm form-input ${
										errors.includes("description") && "border-danger"
									}`}
									value={description}
									onChange={(e) => setDescription(e.target.value)}
								/>
							</Col>
						</Row>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Agency</Form.Label>
								<Form.Control
									className={`text-sm form-input ${
										errors.includes("agency") && "border-danger"
									}`}
									value={agency}
									onChange={(e) => setAgency(e.target.value)}
								/>
							</Col>
						</Row>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Location</Form.Label>
								<Form.Control
									className={`text-sm form-input ${
										errors.includes("location") && "border-danger"
									}`}
									value={location}
									onChange={(e) => setLocation(e.target.value)}
								/>
							</Col>
							<Col>
								<Form.Label className="text-sm">Area</Form.Label>
								<Form.Control
									className={`text-sm form-input ${
										errors.includes("area") && "border-danger"
									}`}
									value={area}
									onChange={(e) => setArea(e.target.value)}
								/>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="outline-secondary"
							className="btn-sm action-btn text-sm me-2"
							onClick={closeItemModal}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							variant="primary"
							className="btn-sm action-btn flex-column text-sm btn-default"
							disabled={actionLoading}
						>
							{actionLoading ? (
								<Spinner className="btn-loader text-white" />
							) : (
								"Save"
							)}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
};

export default Items;
