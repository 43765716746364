import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Skeleton from "react-loading-skeleton";

const ContentLoader = () => {
	return (
		<>
			<Row className="content-table-item bg-white gx-0">
				<Col className="p-10 ellipsis">
					<Skeleton
						width={"90%"}
						height={20}
						baseColor="#dadee7"
						highlightColor="#e9ecf2"
					/>
				</Col>
				<Col className="p-10 ellipsis">
					<Skeleton
						width={"60%"}
						height={20}
						baseColor="#dadee7"
						highlightColor="#e9ecf2"
					/>
				</Col>
				<Col className="p-10 ellipsis">
					<Skeleton
						width={"75%"}
						height={20}
						baseColor="#dadee7"
						highlightColor="#e9ecf2"
					/>
				</Col>
			</Row>
			<Row className="content-table-item bg-white gx-0">
				<Col className="p-10 ellipsis">
					<Skeleton
						width={"70%"}
						height={20}
						baseColor="#dadee7"
						highlightColor="#e9ecf2"
					/>
				</Col>
				<Col className="p-10 ellipsis">
					<Skeleton
						width={"87%"}
						height={20}
						baseColor="#dadee7"
						highlightColor="#e9ecf2"
					/>
				</Col>
				<Col className="p-10 ellipsis">
					<Skeleton
						width={"60%"}
						height={20}
						baseColor="#dadee7"
						highlightColor="#e9ecf2"
					/>
				</Col>
			</Row>
		</>
	);
};

export default ContentLoader;
