import Skeleton from "react-loading-skeleton";
import format from "date-fns/format";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const ContractorDetails = ({ loading, contractor }) => {
	return (
		<div>
			<div className="bg-white rounded-8 p-20 mb-30">
				<div className="mb-20">
					<h2 className="text-xl text-medium-gotham mb-0">
						Company Information
					</h2>
				</div>
				<Row className="mb-30">
					<Col>
						<span className="info-label text-sm text-medium-gotham block">
							{loading ? (
								<Skeleton
									width={120}
									height={15}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								"Name"
							)}
						</span>
						<span className="info-val">
							{loading ? (
								<Skeleton
									width={"80%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								contractor?.company || "--"
							)}
						</span>
					</Col>
					<Col>
						<span className="info-label text-sm text-medium-gotham block">
							{loading ? (
								<Skeleton
									width={120}
									height={15}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								"Short Name"
							)}
						</span>
						<span className="info-val">
							{loading ? (
								<Skeleton
									width={"80%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								contractor?.short_name || "--"
							)}
						</span>
					</Col>
					<Col>
						<span className="info-label text-sm text-medium-gotham block">
							{loading ? (
								<Skeleton
									width={120}
									height={15}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								"Phone"
							)}
						</span>
						<span className="info-val">
							{loading ? (
								<Skeleton
									width={"80%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								contractor?.phone || "--"
							)}
						</span>
					</Col>
				</Row>
				<Row className="mb-50">
					<Col>
						<span className="info-label text-sm text-medium-gotham block">
							{loading ? (
								<Skeleton
									width={120}
									height={15}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								"Email"
							)}
						</span>
						<span className="info-val">
							{loading ? (
								<Skeleton
									width={"80%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								(contractor?.emails.length !== 0 &&
									contractor?.emails.map((email) => (
										<span key={email.id} className="block">
											{email.email}
										</span>
									))) ||
								"--"
							)}
						</span>
					</Col>
					<Col>
						<span className="info-label text-sm text-medium-gotham block">
							{loading ? (
								<Skeleton
									width={120}
									height={15}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								"Contact Person"
							)}
						</span>
						<span className="info-val">
							{loading ? (
								<Skeleton
									width={"80%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								contractor?.contact_person || "--"
							)}
						</span>
					</Col>
					<Col />
				</Row>
			</div>
			<div className="bg-white rounded-8 p-20 mb-30">
				<div className="mb-20">
					<h2 className="text-xl text-medium-gotham mb-0">Address</h2>
				</div>
				<Row className="mb-30">
					<Col>
						<span className="info-label text-sm text-medium-gotham block">
							{loading ? (
								<Skeleton
									width={120}
									height={15}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								"Address"
							)}
						</span>
						<span className="info-val">
							{loading ? (
								<Skeleton
									width={"80%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								contractor?.address || "--"
							)}
						</span>
					</Col>
					<Col>
						<span className="info-label text-sm text-medium-gotham block">
							{loading ? (
								<Skeleton
									width={120}
									height={15}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								"Town/City"
							)}
						</span>
						<span className="info-val">
							{loading ? (
								<Skeleton
									width={"80%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								contractor?.city || "--"
							)}
						</span>
					</Col>
					<Col>
						<span className="info-label text-sm text-medium-gotham block">
							{loading ? (
								<Skeleton
									width={120}
									height={15}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								"Province"
							)}
						</span>
						<span className="info-val">
							{loading ? (
								<Skeleton
									width={"80%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								contractor?.province || "--"
							)}
						</span>
					</Col>
				</Row>
				<Row className="mb-50">
					<Col>
						<span className="info-label text-sm text-medium-gotham block">
							{loading ? (
								<Skeleton
									width={120}
									height={15}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								"Country"
							)}
						</span>
						<span className="info-val">
							{loading ? (
								<Skeleton
									width={"80%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								contractor?.country || "--"
							)}
						</span>
					</Col>
					<Col>
						<span className="info-label text-sm text-medium-gotham block">
							{loading ? (
								<Skeleton
									width={120}
									height={15}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								"Postal Code"
							)}
						</span>
						<span className="info-val">
							{loading ? (
								<Skeleton
									width={"80%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								contractor?.postal_code || "--"
							)}
						</span>
					</Col>
					<Col />
				</Row>
			</div>
			<div className="bg-white rounded-8 p-20 mb-30">
				<div className="mb-20">
					<h2 className="text-xl text-medium-gotham mb-0">
						Bank Account Details
					</h2>
				</div>
				<Row className="mb-30">
					<Col>
						<span className="info-label text-sm text-medium-gotham block">
							{loading ? (
								<Skeleton
									width={120}
									height={15}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								"Bank"
							)}
						</span>
						<span className="info-val">
							{loading ? (
								<Skeleton
									width={"80%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								contractor?.bank || "--"
							)}
						</span>
					</Col>
					<Col>
						<span className="info-label text-sm text-medium-gotham block">
							{loading ? (
								<Skeleton
									width={120}
									height={15}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								"Branch"
							)}
						</span>
						<span className="info-val">
							{loading ? (
								<Skeleton
									width={"80%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								contractor?.bank_branch || "--"
							)}
						</span>
					</Col>
					<Col>
						<span className="info-label text-sm text-medium-gotham block">
							{loading ? (
								<Skeleton
									width={120}
									height={15}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								"Bank Key/BSB"
							)}
						</span>
						<span className="info-val">
							{loading ? (
								<Skeleton
									width={"80%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								contractor?.bank_key || "--"
							)}
						</span>
					</Col>
				</Row>
				<Row className="mb-50">
					<Col>
						<span className="info-label text-sm text-medium-gotham block">
							{loading ? (
								<Skeleton
									width={120}
									height={15}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								"Account Name"
							)}
						</span>
						<span className="info-val">
							{loading ? (
								<Skeleton
									width={"80%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								contractor?.bank_account_name || "--"
							)}
						</span>
					</Col>
					<Col>
						<span className="info-label text-sm text-medium-gotham block">
							{loading ? (
								<Skeleton
									width={120}
									height={15}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								"Account Number"
							)}
						</span>
						<span className="info-val">
							{loading ? (
								<Skeleton
									width={"80%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								contractor?.bank_account_number || "--"
							)}
						</span>
					</Col>
					<Col />
				</Row>
			</div>
		</div>
	);
};

export default ContractorDetails;
