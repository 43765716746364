import React, { useState, useEffect, useContext } from "react";
import { ClientProfileContext } from "../context/ClientProfileContext";
import { ClientContext } from "../context/ClientContext";
import { Outlet, NavLink, useNavigate } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import Skeleton from "react-loading-skeleton";
import ToastDisplay from "../components/ToastDisplay";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Logo from "../assets/hero.png";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { getFullName } from "../components/Utils";

const ClientLayout = () => {
	const navigate = useNavigate();
	const [clientProfile, setClientProfile] = useContext(ClientProfileContext);
	const [clientSession] = useContext(ClientContext);
	const [showNav, setShowNav] = useState(false);
	const [passwordModal, setPasswordModal] = useState(false);
	const [actionLoading, setActionLoading] = useState(false);
	const [password, setPassword] = useState("");

	const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
		<a
			href=""
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
			className="header-user-dropdown text-sm flex-row-left"
		>
			{children}
		</a>
	));

	const closePasswordModal = () => {
		setPasswordModal(false);
		setPassword("");
	};

	const resetPassword = async (e) => {
		e.preventDefault();
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": clientSession,
			},
			body: JSON.stringify({
				password: password,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/clients/c/${clientProfile?.id}/password`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			toast(<ToastDisplay status="error" content={data.msg} />);
		} else {
			closePasswordModal();
			toast(<ToastDisplay status="success" content={data.msg} />);
			setClientProfile(data.profile);
		}
		setActionLoading(false);
	};

	useEffect(() => {
		if (clientProfile?.temp_pw) {
			setPasswordModal(true);
		}
	}, [clientProfile]);

	return (
		<>
			<Toaster
				position="top-center"
				toastOptions={{ className: "snackbar-container" }}
			/>
			<div
				className={`main-nav-overlay ${showNav ? "active" : ""}`}
				onClick={() => setShowNav(false)}
			/>
			<div className={`main-nav bg-white ${showNav ? "active" : ""}`}>
				<div className="main-nav-header flex-column full display-lg">
					<img src={Logo} alt="logo" className="nav-logo" />
				</div>
				<div className="main-nav-header-sm display-sm mb-10">
					<div className="full-height flex-column full p-10">
						<div className="main-nav-user border full flex-row-center">
							{!clientProfile ? (
								<Skeleton
									width="100%"
									height={14}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								<span className="text-sm ellipsis">
									{getFullName(clientProfile)}
								</span>
							)}
						</div>
					</div>
				</div>
				<div className="main-nav-body">
					<NavLink
						to="/work-orders"
						className="main-nav-item opacity-5 flex-row-left full"
					>
						<i className="nav-icon ri-tools-fill"></i>
						<span className="text-medium-gotham text-sm">Work Orders</span>
					</NavLink>
					<NavLink
						to="/logout"
						className="main-nav-item opacity-5 flex-row-left full"
					>
						<i className="nav-icon ri-logout-box-line"></i>
						<span className="text-medium-gotham text-sm">Logout</span>
					</NavLink>
				</div>
			</div>
			<div className="content">
				<div className="page-header bg-white full flex-row-left">
					<Container className="gx-md-5 gx-4">
						<Row className="flex-row-left">
							<Col className="col-8">
								<div className="header-client">
									<span className="text-medium-gotham text-lg display-lg">
										Repairs & Maintenance Management System
									</span>
									<div className="display-sm">
										<div className="flex-row-left">
											<a
												href="#"
												className="nav-toggle flex-row-left"
												onClick={(e) => {
													e.preventDefault();
													setShowNav(!showNav);
												}}
											>
												<div className="fry" />
											</a>
											<div className="page-header-title">
												<span className="text-medium-gotham block">
													Repairs & Maintenance
												</span>
												<span className="text-medium-gotham block">
													Management System
												</span>
											</div>
										</div>
									</div>
								</div>
							</Col>
							<Col className="col-4 flex-row-right">
								<div className="header-user bg-default flex-column display-lg">
									<span className="text-white text-medium-inter">
										{clientProfile?.first_name[0]}
									</span>
								</div>
								{/* <Dropdown align="end" className="display-lg">
									<Dropdown.Toggle as={CustomToggle}>
										<div className="header-user bg-default flex-column">
											<span className="text-white text-medium-inter">
												{profile?.name[0]}
											</span>
										</div>
									</Dropdown.Toggle>
									<Dropdown.Menu className="account-dropdown dropdown-default">
										<Dropdown.Item href="/property/logout" className="text-sm">
											Logout
										</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown> */}
								<img src={Logo} alt="logo" className="nav-logo-sm display-sm" />
							</Col>
						</Row>
					</Container>
				</div>
				<Outlet />
			</div>
			<Modal
				show={passwordModal}
				onHide={() => closePasswordModal()}
				centered
				backdrop="static"
				keyboard={false}
			>
				<Form onSubmit={resetPassword}>
					<Modal.Header>
						<Modal.Title className="text-lg">Change Password</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Row className="mb-30">
							<Col>
								<Form.Label>New Password</Form.Label>
								<Form.Control
									required
									type="password"
									className="form-input"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
								/>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							type="submit"
							className="btn-default btn-sm rsvp-action-btn text-sm me-2 flex-row-center"
							disabled={actionLoading}
						>
							{actionLoading ? "Saving.." : "Save"}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
};

export default ClientLayout;
