import { format } from "date-fns";
import { useState } from "react";
import WoAttachment from "./WoAttachment";
import { getFullName } from "./Utils";

const WorkOrderComment = ({ comment }) => {
	return (
		<div className="bg-white border rounded-8 mb-15 p-10">
			<div className="comment-header flex-row-left full mb-10">
				<span className="text-medium-gotham text-sm block">
					{comment?.user ? comment.user.name : getFullName(comment.client)}
				</span>
			</div>
			<div className="comment-body">
				<p className="text-sm mb-15">{comment?.content}</p>
				<span className="label-md opacity-7 block">
					{format(new Date(comment.created_at), "MMM dd, yyyy hh:mm a")}
				</span>
			</div>
		</div>
	);
};

export default WorkOrderComment;
