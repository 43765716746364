const AgencyOptions = () => {
	const options = [
		{ value: "AFP", label: "AFP" },
		{ value: "Austrade", label: "Austrade" },
		{ value: "Defence", label: "Defence" },
		{ value: "DFAT", label: "DFAT" },
		{ value: "Home Affairs", label: "Home Affairs" },
	];

	return options;
};

export default AgencyOptions;
