import { useState } from "react";
import { Link } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import ToastDisplay from "../components/ToastDisplay";
import VillageOptions from "../components/VillageOptions";
import AgencyOptions from "../components/AgencyOptions";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import Logo from "../assets/hero.png";

const ClientRegistration = () => {
	const villages = VillageOptions();
	const agencies = AgencyOptions();
	const [actionLoading, setActionLoading] = useState(false);
	const [prefix, setPrefix] = useState("");
	const [firstName, setFirstName] = useState("");
	const [middleName, setMiddleName] = useState("");
	const [lastName, setLastName] = useState("");
	const [suffix, setSuffix] = useState("");
	const [gender, setGender] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [address, setAddress] = useState("");
	const [village, setVillage] = useState("");
	const [homePhone, setHomePhone] = useState("");
	const [msisdn, setMsisdn] = useState("");
	const [agency, setAgency] = useState("");
	const [position, setPosition] = useState("");
	const [workPhone, setWorkPhone] = useState("");
	const [localExt, setLocalExt] = useState("");
	const [fax, setFax] = useState("");
	const [errors, setErrors] = useState([]);
	const [finished, setFinished] = useState(false);

	const requiredFields = [
		{ key: "firstName", value: firstName },
		{ key: "lastName", value: lastName },
		{ key: "email", value: email },
		{ key: "password", value: password },
		{ key: "agency", value: agency },
		{ key: "village", value: village },
	];

	const saveClient = async () => {
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				prefix: prefix,
				first_name: firstName,
				middle_name: middleName,
				last_name: lastName,
				suffix: suffix,
				gender: gender,
				email: email,
				password: password,
				home_phone: homePhone,
				msisdn: msisdn,
				address: address,
				village: village?.value || "",
				agency: agency?.value || "",
				position: position,
				work_phone: workPhone,
				local_ext: localExt,
				fax: fax,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/clients/signup`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			toast(<ToastDisplay status="error" content={data.msg} />, {
				position: "top-center",
			});
		} else {
			toast(<ToastDisplay status="success" content={data.msg} />, {
				position: "top-center",
			});
			setFinished(true);
		}
		setActionLoading(false);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setActionLoading(true);
		var newErrors = [];

		requiredFields.map((field) => {
			if (!field.value || field.value === "" || field.value.length === 0) {
				newErrors.push(field.key);
			}
		});

		if (newErrors.length !== 0) {
			toast(
				<ToastDisplay
					status="error"
					content="Please fill out all required fields."
				/>,
				{ position: "top-center" }
			);
			setActionLoading(false);
			setErrors(newErrors);
			return;
		}
		saveClient();
	};

	return (
		<div className="full-page bg-white flex-column">
			<Toaster toastOptions={{ className: "snackbar-container" }} />
			<Container className="flex-column">
				{!finished ? (
					<div className="login-form-container bg-white col-xl-4 col-lg-6 col-md-7 col-12">
						<Form onSubmit={handleSubmit} className="login-form full">
							<div className="flex-column mt-5">
								<img src={Logo} alt="login-logo" className="login-logo mb-30" />
							</div>
							<div className="align-left full mb-30">
								<h1 className="text-medium-gotham text-xl mb-0">
									Create Account
								</h1>
								<span className="login-title text-md">
									Repairs & Maintenance Management System
								</span>
							</div>
							<div className="mb-10">
								<h2 className="text-lg text-medium-gotham mb-0">
									Account Information
								</h2>
							</div>
							<Row className="mb-20">
								<Col>
									<Form.Label className="text-sm">Email*</Form.Label>
									<Form.Control
										type="email"
										className={`text-sm reg-input rounded-4 ${
											errors.includes("email") && "border-danger"
										}`}
										value={email}
										onChange={(e) => setEmail(e.target.value)}
									/>
								</Col>
							</Row>
							<Row className="mb-50">
								<Col>
									<Form.Label className="text-sm">Password*</Form.Label>
									<Form.Control
										type="password"
										className={`text-sm reg-input rounded-4 ${
											errors.includes("password") && "border-danger"
										}`}
										value={password}
										onChange={(e) => setPassword(e.target.value)}
									/>
								</Col>
							</Row>
							<div className="mb-10">
								<h2 className="text-lg text-medium-gotham mb-0">
									Personal Information
								</h2>
							</div>
							<Row className="mb-20">
								<Col>
									<Form.Label className="text-sm">Prefix</Form.Label>
									<Form.Control
										className={`text-sm reg-input rounded-4 ${
											errors.includes("prefix") && "border-danger"
										}`}
										value={prefix}
										onChange={(e) => setPrefix(e.target.value)}
									/>
								</Col>
							</Row>
							<Row className="mb-20">
								<Col>
									<Form.Label className="text-sm">First Name*</Form.Label>
									<Form.Control
										className={`text-sm reg-input rounded-4 ${
											errors.includes("firstName") && "border-danger"
										}`}
										value={firstName}
										onChange={(e) => setFirstName(e.target.value)}
									/>
								</Col>
								<Col>
									<Form.Label className="text-sm">Middle Name</Form.Label>
									<Form.Control
										className={`text-sm reg-input rounded-4 ${
											errors.includes("middleName") && "border-danger"
										}`}
										value={middleName}
										onChange={(e) => setMiddleName(e.target.value)}
									/>
								</Col>
							</Row>
							<Row className="mb-20">
								<Col>
									<Form.Label className="text-sm">Last Name*</Form.Label>
									<Form.Control
										className={`text-sm reg-input rounded-4 ${
											errors.includes("lastName") && "border-danger"
										}`}
										value={lastName}
										onChange={(e) => setLastName(e.target.value)}
									/>
								</Col>
								<Col>
									<Form.Label className="text-sm">Suffix</Form.Label>
									<Form.Control
										className={`text-sm reg-input rounded-4 ${
											errors.includes("suffix") && "border-danger"
										}`}
										value={suffix}
										onChange={(e) => setSuffix(e.target.value)}
									/>
								</Col>
							</Row>
							<Row className="mb-20">
								<Col>
									<Form.Label className="text-sm">Gender</Form.Label>
									<Form.Select
										className={`text-sm reg-input rounded-4 ${
											errors.includes("gender") && "border-danger"
										}`}
										value={gender}
										onChange={(e) => setGender(e.target.value)}
									>
										<option value=""></option>
										<option value="Male">Male</option>
										<option value="Female">Female</option>
										<option value="Other">Other</option>
									</Form.Select>
								</Col>
							</Row>
							<Row className="mb-50">
								<Col>
									<Form.Label className="text-sm">Agency*</Form.Label>
									<Select
										isClearable
										defaultValue={agency}
										onChange={setAgency}
										options={agencies}
										menuShouldScrollIntoView={false}
										maxMenuHeight={200}
										placeholder=""
										styles={{
											control: (baseStyles, state) => ({
												...baseStyles,
												borderColor: state.isFocused
													? "#c7883a !important"
													: "#797979 !important",
												boxShadow: "none",
												outline: "none",
												borderRadius: 4,
												fontSize: "0.875rem",
												height: "42px",
											}),
											option: (baseStyles, state) => ({
												...baseStyles,
												fontSize: ".875rem",
												color: "#050911",
												backgroundColor: state.isFocused
													? "#f6d4aa !important"
													: "#fff",
											}),
										}}
									/>
								</Col>
								<Col>
									<Form.Label className="text-sm">Position</Form.Label>
									<Form.Control
										className={`text-sm reg-input rounded-4 ${
											errors.includes("position") && "border-danger"
										}`}
										value={position}
										onChange={(e) => setPosition(e.target.value)}
									/>
								</Col>
							</Row>
							<div className="mb-10">
								<h2 className="text-lg text-medium-gotham mb-0">
									Contact Information
								</h2>
							</div>
							<Row className="mb-20">
								<Col>
									<Form.Label className="text-sm">Address</Form.Label>
									<Form.Control
										className={`text-sm reg-input rounded-4 ${
											errors.includes("address") && "border-danger"
										}`}
										value={address}
										onChange={(e) => setAddress(e.target.value)}
									/>
								</Col>
							</Row>
							<Row className="mb-20">
								<Col>
									<Form.Label className="text-sm">
										Village/Apartment*
									</Form.Label>
									<Select
										isClearable
										defaultValue={village}
										onChange={setVillage}
										options={villages}
										menuShouldScrollIntoView={false}
										maxMenuHeight={200}
										placeholder=""
										styles={{
											control: (baseStyles, state) => ({
												...baseStyles,
												borderColor: state.isFocused
													? "#c7883a !important"
													: "#797979 !important",
												boxShadow: "none",
												outline: "none",
												borderRadius: 4,
												fontSize: "0.875rem",
												height: "42px",
											}),
											option: (baseStyles, state) => ({
												...baseStyles,
												fontSize: ".875rem",
												color: "#050911",
												backgroundColor: state.isFocused
													? "#f6d4aa !important"
													: "#fff",
											}),
										}}
									/>
								</Col>
							</Row>
							<Row className="mb-20">
								<Col>
									<Form.Label className="text-sm">Home Phone #</Form.Label>
									<Form.Control
										className={`text-sm reg-input rounded-4 ${
											errors.includes("homePhone") && "border-danger"
										}`}
										value={homePhone}
										onChange={(e) => setHomePhone(e.target.value)}
									/>
								</Col>
								<Col>
									<Form.Label className="text-sm">Mobile #</Form.Label>
									<Form.Control
										className={`text-sm reg-input rounded-4 ${
											errors.includes("msisdn") && "border-danger"
										}`}
										value={msisdn}
										onChange={(e) => setMsisdn(e.target.value)}
									/>
								</Col>
							</Row>
							<Row className="mb-20">
								<Col>
									<Form.Label className="text-sm">Work Phone</Form.Label>
									<Form.Control
										className={`text-sm reg-input rounded-4 ${
											errors.includes("workPhone") && "border-danger"
										}`}
										value={workPhone}
										onChange={(e) => setWorkPhone(e.target.value)}
									/>
								</Col>
							</Row>
							<Row className="mb-50">
								<Col>
									<Form.Label className="text-sm">Local Ext.</Form.Label>
									<Form.Control
										className={`text-sm reg-input rounded-4 ${
											errors.includes("localExt") && "border-danger"
										}`}
										value={localExt}
										onChange={(e) => setLocalExt(e.target.value)}
									/>
								</Col>
								<Col>
									<Form.Label className="text-sm">Fax</Form.Label>
									<Form.Control
										className={`text-sm reg-input rounded-4 ${
											errors.includes("fax") && "border-danger"
										}`}
										value={fax}
										onChange={(e) => setFax(e.target.value)}
									/>
								</Col>
							</Row>
							<div className="rounded bg-orange-light p-20 mb-30">
								<p className="mb-0 text-sm">
									You'll be able to log in once your account is approved.
								</p>
							</div>
							<Button
								type="submit"
								className="btn-default login-btn btn-md text-sm text-medium-inter full mb-30"
								disabled={actionLoading}
							>
								{actionLoading ? (
									<Spinner className="btn-loader text-white" />
								) : (
									"Create account"
								)}
							</Button>
						</Form>
					</div>
				) : (
					<div className="flex-column">
						<img src={Logo} alt="login-logo" className="login-logo mb-30" />
						<h1 className="text-medium-gotham text-xl mb-15">
							Account Created
						</h1>
						<p className="login-title text-md align-center mb-30">
							You will be able to log in once an admin approves your account.
						</p>
						<Link to="/login" className="mb-30">
							<Button className="btn-default ps-5 pe-5">Log in</Button>
						</Link>
					</div>
				)}
			</Container>
		</div>
	);
};

export default ClientRegistration;
