const EmptyBee = ({ className }) => {
	return (
		<div className={`empty-bee flex-column full ${className}`}>
			<svg
				width="79"
				height="110"
				viewBox="0 0 79 110"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M41.479 43.345C6.83859 44.9787 -5.89289 61.3144 4.53694 70.8366C11.8966 77.555 24.3675 86.6348 47.6143 84.4569C70.8601 82.2784 72.8188 70.2924 60.5762 68.662C48.3336 67.0322 4.71902 74.636 9.64825 92.2022C13.2063 104.877 44.0472 108.53 44.0472 108.53"
					stroke="#D7DAE9"
					strokeWidth="2"
					strokeDasharray="7"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M65.5008 24.345C65.5008 24.345 70.4644 11.3786 70.9877 6.33447C71.2629 3.69879 66.8952 -3.63953 61.7833 3.14842C56.6715 9.93637 59.0036 18.2593 65.5008 24.345Z"
					fill="#D8DBEA"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M47.8873 10.2236C42.0724 8.25717 39.44 9.79039 38.3185 12.8507C37.1959 15.912 38.7627 21.7391 47.5331 23.0674C56.3046 24.3957 65 24.3447 65 24.3447C65 24.3447 53.7023 12.1901 47.8873 10.2236Z"
					fill="#D8DBEA"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M64.5161 26.9142C64.6481 26.3722 64.8227 25.8527 65.0172 25.345C63.9436 25.5145 62.8513 25.7527 61.752 26.0752C60.5594 26.4241 59.4462 26.8818 58.3994 27.4062C57.945 28.2648 57.5769 29.1822 57.3378 30.1673C55.8645 36.2314 59.343 42.3435 65.1878 44.345C65.8208 44.2195 66.4568 44.0745 67.0938 43.8882C68.8737 43.3668 70.5157 42.6768 72 41.877C66.2921 39.3727 63.007 33.1234 64.5161 26.9142Z"
					fill="#D8DBEA"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M56.1714 30.0766C56.3111 29.4776 56.5006 28.9047 56.7106 28.345C51.6539 31.5844 48.8223 36.612 49.2892 39.5438C46.058 40.9326 44 42.1025 44 42.1025C44 42.1025 46.438 41.917 49.9905 41.2342C51.8932 44.0147 57.1677 45.8765 63 45.2088C57.7167 42.4767 54.7346 36.259 56.1714 30.0766Z"
					fill="#D8DBEA"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M72.917 32.2751C71.5775 31.9536 70.747 30.6081 71.0695 29.2604C71.391 27.9221 72.7449 27.0927 74.0833 27.4142C75.429 27.7377 76.2512 29.0884 75.9308 30.4278C75.6073 31.7743 74.2637 32.5975 72.917 32.2751ZM78.7854 30.4748C77.7074 26.7409 72.7509 24.8127 66.9039 25.473C66.6595 26.0515 66.4516 26.6619 66.3031 27.2941C65.0012 32.8363 68.0086 38.4113 73.1091 40.345C77.3652 37.6374 79.755 33.8036 78.7854 30.4748Z"
					fill="#D8DBEA"
				/>
			</svg>
		</div>
	);
};

export default EmptyBee;
