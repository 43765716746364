import React, { useEffect, useContext, useState } from "react";
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import { SessionContext } from "../context/SessionContext";
import { ProfileContext } from "../context/ProfileContext";
import toast from "react-hot-toast";
import ToastDisplay from "../components/ToastDisplay";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Pagination from "../components/Pagination";
import Spinner from "react-bootstrap/esm/Spinner";
import ContentLoader from "../components/ContentLoader";

const Users = () => {
	document.title = "Users | Repairs & Maintenance Management System";
	const navigate = useNavigate();
	const [session] = useContext(SessionContext);
	const [profile] = useContext(ProfileContext);
	const [users, setUsers] = useState([]);
	const [roles, setRoles] = useState([]);
	const [meta, setMeta] = useState(null);
	const [loading, setLoading] = useState(false);
	const [actionLoading, setActionLoading] = useState(false);
	const [userModal, setUserModal] = useState(false);
	const [filterModal, setFilterModal] = useState(false);
	const [showFilters, setShowFilters] = useState(false);
	const [errors, setErrors] = useState([]);

	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [role, setRole] = useState("");
	const [password, setPassword] = useState("");

	const [searchParams, setSearchParams] = useSearchParams({});
	const [searchQ, setSearchQ] = useState(searchParams.get("q") || "");
	const [searchRole, setSearchRole] = useState(searchParams.get("role") || "");

	const searchFields = [
		{ key: "q", value: searchQ },
		{ key: "role", value: searchRole },
	];

	const filterFields = [
		{
			key: "role",
			value: searchRole,
			defaultVal: "",
			edit: setSearchRole,
		},
	];

	const requiredFields = [
		{ key: "name", value: name },
		{ key: "email", value: email },
		{ key: "role", value: role },
		{ key: "password", value: password },
	];

	const getData = async () => {
		setLoading(true);
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/${window.location.search}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			setUsers(data.data);
			setRoles(data.roles);
			setMeta(data.meta);
		}
		setLoading(false);
	};

	const saveUser = async () => {
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
			body: JSON.stringify({
				name: name,
				email: email,
				role: role,
				password: password,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			toast(<ToastDisplay status="error" content={data.msg} />);
		} else {
			toast(<ToastDisplay status="success" content={data.msg} />);
			closeUserModal();
			navigate(`/property/users/${data.user_id}`);
		}
		setActionLoading(false);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setActionLoading(true);
		var newErrors = [];

		requiredFields.map((field) => {
			if (!field.value || field.value === "" || field.value.length === 0) {
				newErrors.push(field.key);
			}
		});

		if (newErrors.length !== 0) {
			toast(
				<ToastDisplay
					status="error"
					content="Please fill out all required fields."
				/>
			);
			setActionLoading(false);
			setErrors(newErrors);
			return;
		}
		saveUser();
	};

	const search = (e) => {
		e.preventDefault();
		var newSearch = { ...searchParams };
		searchFields.map((field) => {
			if (field.value !== "") {
				newSearch[field.key] = field.value;
			} else {
				delete newSearch[field.key];
			}
		});
		setSearchParams(newSearch);
		setFilterModal(false);
	};

	const removeFilter = (e, func, key) => {
		e.preventDefault();
		func("");
		var newSearch = { ...searchParams };
		searchFields.map((field) => {
			if (field.value !== "" && field.key !== key) {
				newSearch[field.key] = field.value;
			} else {
				delete newSearch[field.key];
			}
		});
		setSearchParams(newSearch);
	};

	const clearFilters = (e) => {
		e.preventDefault();
		setSearchParams({});
		filterFields.map((field) => {
			field.edit(field.defaultVal);
		});
	};

	const closeUserModal = () => {
		setUserModal(false);
		setName("");
		setEmail("");
		setPassword("");
		setRole("");
		setErrors([]);
	};

	const isEmpty = (value) => {
		return value === "" || value === null || value === undefined;
	};

	useEffect(() => {
		if (profile) {
			if (!profile.role.users_view) {
				navigate("/");
			} else {
				getData();
				if (filterFields.every((field) => isEmpty(field.value))) {
					setShowFilters(false);
				} else {
					setShowFilters(true);
				}
			}
		}
	}, [searchParams, profile]);

	return (
		<>
			<Container className="gx-5">
				<Row className="content-header flex-row-left mb-30">
					<Col>
						<h1 className="text-xl m-0">Users</h1>
					</Col>
				</Row>
				<Row className="flex-row-left mb-20">
					<Col className="col-5">
						<Form onSubmit={search}>
							<div className="search-container bg-white flex-row-left">
								<i className="ri-search-line search-icon" />
								<Form.Control
									className="text-sm"
									placeholder="Search users"
									value={searchQ}
									onChange={(e) => setSearchQ(e.target.value)}
								/>
								<Button
									variant="light"
									className="filter-btn flex-column"
									onClick={() => setFilterModal(true)}
								>
									<i className="ri-equalizer-3-fill m-0" />
								</Button>
							</div>
						</Form>
					</Col>
					<Col className="col-7 flex-row-right">
						<Pagination
							meta={meta}
							searchParams={searchParams}
							setSearchParams={setSearchParams}
							searchFields={searchFields}
							className="pull-right flex-row-left"
						/>
						<Button
							variant="light"
							className="refresh-btn flex-column"
							onClick={getData}
						>
							<i className="ri-restart-line opacity-7" />
						</Button>
						{profile?.role?.users_add && (
							<Button
								className="control-btn btn-sm btn-default text-medium-gotham text-sm"
								onClick={() => setUserModal(true)}
							>
								Add User
							</Button>
						)}
					</Col>
				</Row>
				{showFilters ? (
					<div className="filters-container">
						<span className="filters-label text-medium-mont">Filters:</span>
						{filterFields.map((field) => {
							const { key, value } = field;
							if (value) {
								return (
									<div
										key={key}
										className="filter-item bg-default flex-row-left rounded-4"
									>
										<span className="text-white label-md mb-0">
											{key.charAt(0).toUpperCase() + key.slice(1)}: {value}
										</span>
										<a
											href="#"
											className="remove-filter"
											onClick={(e) => removeFilter(e, field.edit, key)}
										>
											<i className="ri-close-line text-white" />
										</a>
									</div>
								);
							} else {
								return "";
							}
						})}
						<div className="filter-item rounded-4 fit">
							<a
								href="#"
								className="text-sm text-medium-mont text-default"
								onClick={clearFilters}
							>
								Clear Filters
							</a>
						</div>
					</div>
				) : (
					""
				)}
				<Row className="content-table-header bg-white gx-0">
					<Col className="p-10">
						<span className="text-sm text-medium-gotham block">Name</span>
					</Col>
					<Col className="p-10">
						<span className="text-sm text-medium-gotham block">Email</span>
					</Col>
					<Col className="p-10">
						<span className="text-sm text-medium-gotham block">Role</span>
					</Col>
				</Row>
				{loading ? (
					<ContentLoader />
				) : (
					users?.map((user) => (
						<Link key={user.id} to={`/property/users/${user.id}`}>
							<Row className="content-table-item bg-white gx-0">
								<Col className="p-10 ellipsis">
									<span className="text-sm">{user.name}</span>
								</Col>
								<Col className="p-10 ellipsis">
									<span className="text-sm">{user.email}</span>
								</Col>
								<Col className="p-10 ellipsis">
									<span className="text-sm">{user.role?.name || "--"}</span>
								</Col>
							</Row>
						</Link>
					))
				)}
				<Row className="mt-4 mb-20">
					<Col className="flex-row-right">
						<Pagination
							meta={meta}
							searchParams={searchParams}
							setSearchParams={setSearchParams}
							searchFields={searchFields}
							className="pull-right flex-row-left pagination-bottom"
						/>
					</Col>
				</Row>
			</Container>
			<Modal
				show={filterModal}
				centered
				onHide={() => setFilterModal(false)}
				className="filter-modal"
			>
				<Form onSubmit={search}>
					<Modal.Header>
						<Modal.Title className="text-lg">Filters</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Role</Form.Label>
								<Form.Select
									className="text-sm form-input"
									value={searchRole}
									onChange={(e) => setSearchRole(e.target.value)}
								>
									<option value=""></option>
									{roles?.map((userRole) => (
										<option key={userRole.id} value={userRole.name}>
											{userRole.name}
										</option>
									))}
								</Form.Select>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="outline-secondary"
							className="btn-sm action-btn text-sm me-2"
							onClick={() => setFilterModal(false)}
						>
							Close
						</Button>
						<Button
							type="submit"
							variant="primary"
							className="btn-sm action-btn text-sm btn-default"
						>
							Apply
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal
				show={userModal}
				className="info-modal"
				centered
				size="lg"
				onHide={closeUserModal}
			>
				<Form onSubmit={handleSubmit}>
					<Modal.Header>
						<Modal.Title className="text-lg">New User</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="mb-10">
							<h2 className="text-lg text-medium-gotham mb-0">
								User Information
							</h2>
						</div>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Name*</Form.Label>
								<Form.Control
									autoFocus
									className={`text-sm form-input ${
										errors.includes("name") && "border-danger"
									}`}
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
							</Col>
							<Col>
								<Form.Label className="text-sm">Email*</Form.Label>
								<Form.Control
									className={`text-sm form-input ${
										errors.includes("email") && "border-danger"
									}`}
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</Col>
						</Row>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Temporary Password*</Form.Label>
								<Form.Control
									type="password"
									className={`text-sm form-input ${
										errors.includes("password") && "border-danger"
									}`}
									value={password}
									onChange={(e) => setPassword(e.target.value)}
								/>
							</Col>
						</Row>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Role*</Form.Label>
								<Form.Select
									className={`text-sm form-input ${
										errors.includes("role") && "border-danger"
									}`}
									value={role}
									onChange={(e) => setRole(e.target.value)}
								>
									<option value=""></option>
									{roles?.map((userRole) => (
										<option value={userRole.id}>{userRole.name}</option>
									))}
								</Form.Select>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="outline-secondary"
							className="btn-sm action-btn text-sm me-2"
							onClick={closeUserModal}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							variant="primary"
							className="btn-sm action-btn flex-column text-sm btn-default"
							disabled={actionLoading}
						>
							{actionLoading ? (
								<Spinner className="btn-loader text-white" />
							) : (
								"Save"
							)}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
};

export default Users;
