const VillageOptions = () => {
	const options = [
		{ value: "Dasmarinas Village", label: "Dasmarinas Village" },
		{
			value: "Edades Tower Rockwell Makati",
			label: "Edades Tower Rockwell Makati",
		},
		{ value: "Four Seasons Bldg", label: "Four Seasons Bldg" },
		{ value: "Hidalgo Place", label: "Hidalgo Place" },
		{ value: "Luna Gardens", label: "Luna Gardens" },
		{ value: "One Penn Place", label: "One Penn Place" },
		{ value: "Park Terraces", label: "Park Terraces" },
		{
			value: "The Residences at Greenbelt",
			label: "The Residences at Greenbelt",
		},
	];

	return options;
};

export default VillageOptions;
