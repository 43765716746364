import Skeleton from "react-loading-skeleton";
import format from "date-fns/format";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const ClientPartnerDetails = ({ loading, client }) => {
	return (
		<div>
			<div className="bg-white rounded-8 p-20 mb-30">
				<div className="mb-20">
					<h2 className="text-xl text-medium-gotham mb-0">
						Partner Information
					</h2>
				</div>
				<Row className="mb-30">
					<Col>
						<span className="info-label text-sm text-medium-gotham block">
							{loading ? (
								<Skeleton
									width={120}
									height={15}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								"Prefix"
							)}
						</span>
						<span className="info-val">
							{loading ? (
								<Skeleton
									width={"80%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								client?.partner_prefix || "--"
							)}
						</span>
					</Col>
				</Row>
				<Row className="mb-30">
					<Col>
						<span className="info-label text-sm text-medium-gotham block">
							{loading ? (
								<Skeleton
									width={120}
									height={15}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								"First Name"
							)}
						</span>
						<span className="info-val">
							{loading ? (
								<Skeleton
									width={"80%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								client?.partner_first_name || "--"
							)}
						</span>
					</Col>
					<Col>
						<span className="info-label text-sm text-medium-gotham block">
							{loading ? (
								<Skeleton
									width={120}
									height={15}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								"Middle Name"
							)}
						</span>
						<span className="info-val">
							{loading ? (
								<Skeleton
									width={"80%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								client?.partner_middle_name || "--"
							)}
						</span>
					</Col>
					<Col>
						<span className="info-label text-sm text-medium-gotham block">
							{loading ? (
								<Skeleton
									width={120}
									height={15}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								"Last Name"
							)}
						</span>
						<span className="info-val">
							{loading ? (
								<Skeleton
									width={"80%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								client?.partner_last_name || "--"
							)}
						</span>
					</Col>
				</Row>
				<Row className="mb-30">
					<Col>
						<span className="info-label text-sm text-medium-gotham block">
							{loading ? (
								<Skeleton
									width={120}
									height={15}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								"Suffix"
							)}
						</span>
						<span className="info-val">
							{loading ? (
								<Skeleton
									width={"80%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								client?.partner_suffix || "--"
							)}
						</span>
					</Col>
				</Row>
			</div>
			<div className="bg-white rounded-8 p-20 mb-30">
				<div className="mb-20">
					<h2 className="text-xl text-medium-gotham mb-0">
						Partner Contact Information
					</h2>
				</div>
				<Row className="mb-30">
					<Col>
						<span className="info-label text-sm text-medium-gotham block">
							{loading ? (
								<Skeleton
									width={70}
									height={15}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								"Email"
							)}
						</span>
						<span className="info-val">
							{loading ? (
								<Skeleton
									width={"80%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								(client?.partner_emails.length !== 0 &&
									client?.partner_emails.map((email) => (
										<span className="block">{email.email}</span>
									))) ||
								"--"
							)}
						</span>
					</Col>
					<Col>
						<span className="info-label text-sm text-medium-gotham block">
							{loading ? (
								<Skeleton
									width={120}
									height={15}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								"Mobile #"
							)}
						</span>
						<span className="info-val">
							{loading ? (
								<Skeleton
									width={"80%"}
									height={25}
									baseColor="#dadee7"
									highlightColor="#e9ecf2"
								/>
							) : (
								client?.partner_msisdn || "--"
							)}
						</span>
					</Col>
				</Row>
			</div>
		</div>
	);
};

export default ClientPartnerDetails;
