import { useEffect } from "react";
import React from "react";
import Loader from "./Loader";

import { useNavigate } from "react-router-dom";

const Home = ({ profile }) => {
	const navigate = useNavigate();

	useEffect(() => {
		if (profile) {
			if (profile.role.work_orders_view) {
				navigate("/property/work-orders");
			} else if (profile.role.clients_view) {
				navigate("/property/clients");
			} else if (profile.role.contractors_view) {
				navigate("/property/contractors");
			} else if (profile.role.roles_view) {
				navigate("/property/roles");
			} else if (profile.role.users_view) {
				navigate("/property/users");
			}
		}
	}, [profile]);

	return <Loader />;
};

export default Home;
