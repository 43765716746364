const WoAttachment = ({ attachment, setAttachments, setRemoveAttachments }) => {
	const handleDelete = () => {
		setRemoveAttachments((prevRemoveAttachments) => [
			...prevRemoveAttachments,
			attachment.id,
		]);
		setAttachments((prevAttachments) =>
			prevAttachments.filter((a) => a.id !== attachment.id)
		);
	};

	return (
		<div
			className="file-upload bg-cover rounded-4 border"
			style={{
				background: attachment ? `url(${attachment.path})` : "transparent",
			}}
		>
			{setAttachments && (
				<div className="file-delete-overlay flex-column">
					<i
						className="ri-delete-bin-line text-white m-0 text-lg"
						onClick={handleDelete}
					/>
				</div>
			)}
		</div>
	);
};

export default WoAttachment;
