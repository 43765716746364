import { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { SessionContext } from "../context/SessionContext";
import { ProfileContext } from "../context/ProfileContext";
import { WithContext as ReactTags } from "react-tag-input";
import Select from "react-select";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Skeleton from "react-loading-skeleton";
import Dropdown from "react-bootstrap/Dropdown";
import Nav from "react-bootstrap/Nav";
import toast from "react-hot-toast";
import ToastDisplay from "../components/ToastDisplay";
import ContractorDetails from "../components/ContractorDetails";
import InfoLogs from "../components/InfoLogs";
import CountryOptions from "../components/CountryOptions";
import ContractorContacts from "../components/ContractorContacts";

const Contractor = () => {
	let { id } = useParams();
	const navigate = useNavigate();
	const [session] = useContext(SessionContext);
	const [profile] = useContext(ProfileContext);
	const countries = CountryOptions();
	const [loading, setLoading] = useState(true);
	const [contractor, setContractor] = useState(null);
	const [editModal, setEditModal] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [actionLoading, setActionLoading] = useState(false);
	const [tab, setTab] = useState("details");
	const [infoTab, setInfoTab] = useState("details");

	const [company, setCompany] = useState("");
	const [shortName, setShortName] = useState("");
	const [phone, setPhone] = useState("");
	const [emails, setEmails] = useState([]);
	const [contactPerson, setContactPerson] = useState("");
	const [address, setAddress] = useState("");
	const [city, setCity] = useState("");
	const [province, setProvince] = useState("");
	const [country, setCountry] = useState({});
	const [postalCode, setPostalCode] = useState("");
	const [bank, setBank] = useState("");
	const [bankBranch, setBankBranch] = useState("");
	const [bankKey, setBankKey] = useState("");
	const [bankAccountName, setBankAccountName] = useState("");
	const [bankAccountNo, setBankAccountNo] = useState("");
	const [remarks, setRemarks] = useState("");

	const [removeEmails, setRemoveEmails] = useState([]);
	const [errors, setErrors] = useState([]);

	const requiredFields = [{ key: "company", value: company }];

	const getData = async () => {
		setLoading(true);
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/contractors/c/${id}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			setContractor(data.contractor);
			setEmails(
				data.contractor.emails.map((email) => ({
					id: email.id,
					text: email.email,
				}))
			);
			setCompany(data.contractor.company);
			setShortName(data.contractor.short_name);
			setPhone(data.contractor.phone);
			setContactPerson(data.contractor.contact_person);
			setAddress(data.contractor.address);
			setCity(data.contractor.city);
			setProvince(data.contractor.province);
			setCountry({
				value: data.contractor.country,
				label: data.contractor.country,
			});
			setPostalCode(data.contractor.postal_code);
			setBank(data.contractor.bank);
			setBankBranch(data.contractor.bank_branch);
			setBankKey(data.contractor.bank_key);
			setBankAccountName(data.contractor.bank_account_name);
			setBankAccountNo(data.contractor.bank_account_number);
			setRemarks(data.contractor.remarks);
		}
		setLoading(false);
	};

	const editContractor = async () => {
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
			body: JSON.stringify({
				company: company,
				short_name: shortName,
				contact_person: contactPerson,
				phone: phone,
				emails: emails,
				address: address,
				city: city,
				province: province,
				country: country?.value || "",
				postal_code: postalCode,
				bank: bank,
				bank_branch: bankBranch,
				bank_key: bankKey,
				bank_account_name: bankAccountName,
				bank_account_number: bankAccountNo,
				remarks: remarks,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/contractors/c/${id}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			toast(<ToastDisplay status="error" content={data.msg} />);
		} else {
			toast(<ToastDisplay status="success" content={data.msg} />);
			getData();
			closeEditModal();
		}
		setActionLoading(false);
	};

	const deleteContractor = async () => {
		setDeleteLoading(true);
		const requestOptions = {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/contractors/c/${id}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			toast(<ToastDisplay status="success" content={data.msg} />);
			navigate("/property/contractors");
			setDeleteModal(false);
		}
		setDeleteLoading(false);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setActionLoading(true);
		var newErrors = [];

		requiredFields.map((field) => {
			if (!field.value || field.value === "" || field.value.length === 0) {
				newErrors.push(field.key);
			}
		});

		if (newErrors.length !== 0) {
			toast(
				<ToastDisplay
					status="error"
					content="Please fill out all required fields."
				/>
			);
			setActionLoading(false);
			setErrors(newErrors);
			return;
		}
		editContractor();
	};

	const closeEditModal = () => {
		setEditModal(false);
		setEmails(
			contractor.emails.map((email) => ({
				id: email.id,
				text: email.email,
			}))
		);
		setCompany(contractor.company);
		setShortName(contractor.short_name);
		setPhone(contractor.phone);
		setContactPerson(contractor.contact_person);
		setAddress(contractor.address);
		setCity(contractor.city);
		setProvince(contractor.province);
		setCountry({ value: contractor.country, label: contractor.country });
		setPostalCode(contractor.postal_code);
		setBank(contractor.bank);
		setBankBranch(contractor.bank_branch);
		setBankKey(contractor.bank_key);
		setBankAccountName(contractor.bank_account_name);
		setBankAccountNo(contractor.bank_account_number);
		setRemarks(contractor.remarks);

		setInfoTab("details");
		setRemoveEmails([]);
		setErrors([]);
	};

	const KeyCodes = {
		comma: 188,
		enter: 13,
		space: 32,
	};

	const emailDelimiters = [KeyCodes.comma, KeyCodes.enter, KeyCodes.space];

	const handleDeleteEmail = (i) => {
		setEmails(emails.filter((tag, index) => index !== i));
	};

	const handleNewEmail = (tag) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (emailRegex.test(tag.text)) {
			setEmails([...emails, tag]);
		} else {
			toast(<ToastDisplay status="error" content="Invalid email format." />);
		}
	};

	const handleEmailBlur = (tag) => {
		if (tag) {
			handleNewEmail({ id: tag, text: tag });
		}
	};

	useEffect(() => {
		if (profile) {
			if (!profile.role.contractors_view) {
				navigate("/");
			} else {
				if (id) {
					getData();
				}
			}
		}
	}, [id, profile]);

	return (
		<>
			<Container className="gx-5">
				<div className="info-page-header mb-10">
					<a
						href="#"
						className="back-btn flex-row-left fit mb-20"
						onClick={(e) => {
							e.preventDefault();
							navigate(-1);
						}}
					>
						<i className="ri-arrow-left-line opacity-7" />
						<span className="text-sm text-regular-inter upper opacity-7">
							Contractors
						</span>
					</a>
					<Row className="flex-row-left">
						<Col className="flex-row-left">
							<div className="contact-icon flex-column rounded-8">
								<span className="text-bold-gotham text-md">
									{contractor?.company[0]}
								</span>
							</div>
							<h1 className="text-lg mb-0 text-medium-gotham">
								{loading ? (
									<Skeleton
										width={250}
										height={25}
										baseColor="#dadee7"
										highlightColor="#e9ecf2"
									/>
								) : (
									contractor.company || "--"
								)}
							</h1>
						</Col>
						<Col className="flex-row-right">
							{profile?.role?.contractors_edit ||
							profile?.role?.contractors_delete ? (
								<Dropdown>
									<Dropdown.Toggle
										variant="light"
										className="info-control-btn text-lg rounded-4 flex-column no-arrow"
										disabled={loading}
									>
										<i className="ri-more-2-fill me-0" />
									</Dropdown.Toggle>
									<Dropdown.Menu className="text-sm dropdown-default">
										{profile?.role?.contractors_edit && (
											<Dropdown.Item
												href="#"
												onClick={(e) => {
													e.preventDefault();
													setEditModal(true);
												}}
											>
												Edit
											</Dropdown.Item>
										)}
										{profile?.role?.contractors_delete && (
											<Dropdown.Item
												href="#"
												className="text-danger"
												onClick={() => setDeleteModal(true)}
											>
												Delete
											</Dropdown.Item>
										)}
									</Dropdown.Menu>
								</Dropdown>
							) : (
								""
							)}
						</Col>
					</Row>
				</div>
				<Nav variant="tabs" defaultActiveKey={tab} className="mb-20">
					<Nav.Item>
						<Nav.Link
							eventKey="details"
							className="info-tab-item"
							onClick={() => setTab("details")}
						>
							Details
						</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link
							eventKey="contacts"
							className="info-tab-item"
							onClick={() => setTab("contacts")}
						>
							Contact Persons
						</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link
							eventKey="logs"
							className="info-tab-item"
							onClick={() => setTab("logs")}
						>
							Logs
						</Nav.Link>
					</Nav.Item>
				</Nav>
				{tab === "details" && (
					<ContractorDetails loading={loading} contractor={contractor} />
				)}
				{tab === "contacts" && (
					<ContractorContacts loading={loading} contractor={contractor} />
				)}
				{tab === "logs" && <InfoLogs loading={loading} data={contractor} />}
			</Container>
			<Modal
				show={editModal}
				className="info-modal"
				size="lg"
				centered
				onHide={closeEditModal}
			>
				<Form onSubmit={handleSubmit}>
					<Modal.Header>
						<Modal.Title className="text-lg">Edit Contractor</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="mb-10">
							<h2 className="text-lg text-medium-gotham mb-0">
								Company Information
							</h2>
						</div>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Company</Form.Label>
								<Form.Control
									autoFocus
									className={`text-sm form-input ${
										errors.includes("company") && "border-danger"
									}`}
									value={company}
									onChange={(e) => setCompany(e.target.value)}
								/>
							</Col>
							<Col>
								<Form.Label className="text-sm">Short Name</Form.Label>
								<Form.Control
									className={`text-sm form-input ${
										errors.includes("shortName") && "border-danger"
									}`}
									value={shortName}
									onChange={(e) => setShortName(e.target.value)}
								/>
							</Col>
						</Row>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Emails</Form.Label>
								<ReactTags
									tags={emails}
									delimiters={emailDelimiters}
									handleInputBlur={handleEmailBlur}
									handleDelete={handleDeleteEmail}
									handleAddition={handleNewEmail}
									inputFieldPosition="bottom"
									allowDragDrop={false}
									placeholder=""
									autofocus={false}
									classNames={{
										tagInput: "full",
										tagInputField: `full form-control form-input text-sm ${
											errors.includes("emails") && "border-danger"
										}`,
										tag: "tag-item text-sm bg-green text-white rounded-4",
										remove: "tag-remove-btn text-white flex-column",
									}}
								/>
							</Col>
						</Row>
						<Row className="mb-50">
							<Col>
								<Form.Label className="text-sm">Phone</Form.Label>
								<Form.Control
									className={`text-sm form-input ${
										errors.includes("phone") && "border-danger"
									}`}
									value={phone}
									onChange={(e) => setPhone(e.target.value)}
								/>
							</Col>
							<Col>
								<Form.Label className="text-sm">Contact Person</Form.Label>
								<Form.Control
									className={`text-sm form-input ${
										errors.includes("contactPerson") && "border-danger"
									}`}
									value={contactPerson}
									onChange={(e) => setContactPerson(e.target.value)}
								/>
							</Col>
						</Row>
						<div className="mb-10">
							<h2 className="text-lg text-medium-gotham mb-0">Address</h2>
						</div>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Address</Form.Label>
								<Form.Control
									as="textarea"
									className={`text-sm form-input ${
										errors.includes("address") && "border-danger"
									}`}
									value={address}
									onChange={(e) => setAddress(e.target.value)}
								/>
							</Col>
						</Row>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Town/City</Form.Label>
								<Form.Control
									className={`text-sm form-input ${
										errors.includes("city") && "border-danger"
									}`}
									value={city}
									onChange={(e) => setCity(e.target.value)}
								/>
							</Col>
							<Col>
								<Form.Label className="text-sm">Province</Form.Label>
								<Form.Control
									className={`text-sm form-input ${
										errors.includes("province") && "border-danger"
									}`}
									value={province}
									onChange={(e) => setProvince(e.target.value)}
								/>
							</Col>
						</Row>
						<Row className="mb-50">
							<Col>
								<Form.Label className="text-sm">Country</Form.Label>
								<Select
									isClearable
									defaultValue={country}
									onChange={setCountry}
									options={countries}
									menuShouldScrollIntoView={false}
									maxMenuHeight={200}
									placeholder=""
									styles={{
										control: (baseStyles, state) => ({
											...baseStyles,
											borderColor: state.isFocused
												? "#c7883a !important"
												: "#dee2e6 !important",
											boxShadow: "none",
											outline: "none",
											borderRadius: 4,
											fontSize: "0.875rem",
										}),
										option: (baseStyles, state) => ({
											...baseStyles,
											fontSize: ".875rem",
											color: "#050911",
											backgroundColor: state.isFocused
												? "#f6d4aa !important"
												: "#fff",
										}),
									}}
								/>
							</Col>
							<Col>
								<Form.Label className="text-sm">Postal Code</Form.Label>
								<Form.Control
									className={`text-sm form-input ${
										errors.includes("postalCode") && "border-danger"
									}`}
									value={postalCode}
									onChange={(e) => setPostalCode(e.target.value)}
								/>
							</Col>
						</Row>
						<div className="mb-10">
							<h2 className="text-lg text-medium-gotham mb-0">
								Bank Account Details
							</h2>
						</div>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Bank</Form.Label>
								<Form.Control
									className={`text-sm form-input ${
										errors.includes("bank") && "border-danger"
									}`}
									value={bank}
									onChange={(e) => setBank(e.target.value)}
								/>
							</Col>
						</Row>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Branch</Form.Label>
								<Form.Control
									className={`text-sm form-input ${
										errors.includes("bankBranch") && "border-danger"
									}`}
									value={bankBranch}
									onChange={(e) => setBankBranch(e.target.value)}
								/>
							</Col>
						</Row>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Bank Key/BSB</Form.Label>
								<Form.Control
									className={`text-sm form-input ${
										errors.includes("bankKey") && "border-danger"
									}`}
									value={bankKey}
									onChange={(e) => setBankKey(e.target.value)}
								/>
							</Col>
						</Row>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Account Name</Form.Label>
								<Form.Control
									className={`text-sm form-input ${
										errors.includes("bankAccountName") && "border-danger"
									}`}
									value={bankAccountName}
									onChange={(e) => setBankAccountName(e.target.value)}
								/>
							</Col>
						</Row>
						<Row className="mb-50">
							<Col>
								<Form.Label className="text-sm">Account Number</Form.Label>
								<Form.Control
									className={`text-sm form-input ${
										errors.includes("bankAccountNo") && "border-danger"
									}`}
									value={bankAccountNo}
									onChange={(e) => setBankAccountNo(e.target.value)}
								/>
							</Col>
						</Row>
						<div className="mb-10">
							<h2 className="text-lg text-medium-gotham mb-0">Miscellaneous</h2>
						</div>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Remarks</Form.Label>
								<Form.Control
									as="textarea"
									className={`text-sm form-input ${
										errors.includes("remarks") && "border-danger"
									}`}
									value={remarks}
									onChange={(e) => setRemarks(e.target.value)}
								/>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="outline-secondary"
							className="btn-sm action-btn text-sm me-2"
							onClick={closeEditModal}
						>
							Close
						</Button>
						<Button
							type="submit"
							variant="primary"
							className="btn-sm action-btn text-sm btn-default"
							disabled={actionLoading}
						>
							{actionLoading ? (
								<Spinner className="btn-loader text-white" />
							) : (
								"Save Changes"
							)}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal show={deleteModal} onHide={() => setDeleteModal(false)}>
				<Modal.Body>
					<h2 className="text-lg text-medium-inter mb-15">Delete Contact</h2>
					<p className="text-sm opacity-7">
						Are you sure you want to delete this contractor? This action cannot
						be undone.
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="light"
						className="modal-btn btn-sm dismiss-btn text-medium-inter text-sm"
						onClick={() => setDeleteModal(false)}
					>
						Cancel
					</Button>
					<Button
						variant="danger"
						className="action-btn btn-sm text-medium-inter text-sm flex-column"
						disabled={deleteLoading}
						onClick={deleteContractor}
					>
						{deleteLoading ? (
							<Spinner className="btn-loader text-white" />
						) : (
							"Delete Contact"
						)}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default Contractor;
